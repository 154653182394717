@import './_breakpoints.css';
@import './_colors.css';

.ValidatorInput {
  position: relative;

  & input {
    /* Account for check icon in validated inputs */
    padding-right: 50px;
  }
}

.ValidationIndicator {
  position: absolute;
  right: 0;
}

.ErrorAlert {
  display:flex;
  gap: 4px;
}

.ErrorIcon > svg {
  height: 16px;
  width: 16px;
  
  & :global .element-to-stroke {
    stroke: ch-red;
  }
}


@media (--small-screen) {
  .ValidatorInput input {
    font-size: 16px;
  }
}
