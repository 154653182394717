@import './Dropdown.css';
@value border-color-light from './_colors.css';

:root {
  --filters-border: 1px solid border-color-light;
}

/*  Overriding the dropdown styles from Dropdown.css file imported above*/
.DropdownRange {
  padding: 16px;
}

.SearchPageFilters {
  width: 510px;
  position: relative;
  flex: 1;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 25px;

  & fieldset {
    /* reset browser default <fieldset> styles */
    border: 0;
    padding: 0.01em 0 0;
    margin: 0;
    min-width: 0;

    &:not(:last-child) {
      border-bottom: var(--filters-border) !important;
    }
  }

  & button {
    /* Matching Chrome's default button text color to fix color inconsistency between browsers */
    /* TODO should this be the cobrand's "link_on_white" color? */
    color: #000;
  }
}

.DropdownContainer {
  max-width: 300px;

  & .DropdownListButton {
    border-radius: 4px;
    border: 1px solid #999;
    height: 34px;
    width: 300px;
  }

  & .DropdownButtonIcon {
    border-right: 2px solid #949494 !important;
    border-bottom: 2px solid #949494 !important;
  }
}

.ClearAllFiltersContainer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px 0;
}

.ClearAllFilters {
  box-sizing: border-box;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  height: 32px;
  width: 149px;
  text-align: center;
}

.FilterRow {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 20px 20px 0;
  align-items: center;

  &:not(:last-child) {
    border-bottom: var(--filters-border);
  }

  /* More space when "buying power" is displayed inline next to the dropdown value option */
  &.FilterRowWithBuyingPower {
    & .DropdownRange {
      padding-right: 0;
    }
  }
}

.ReturnButtonSection {
  display: flex;
  height: 70px;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0 0;
}

.FilterRow.SubTitle {
  border-top: 0;
  font-size: 14px;
  padding-bottom: 0;
  padding-top: 0;
}

.FilterRow.SubTitleBottomPadding {
  padding-bottom: 19px;
}

.FilterRow:first-of-type {
  margin-top: 0;
}

.FilterTitle {
  width: 11rem;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  padding-right: 15px;
  box-sizing: border-box;
}

.FilterSubTitle {
  width: 9.375rem;
  padding-left: 20px;
}

.FilterControl {
  flex: 1;
}

.RangeSlider {
  box-sizing: border-box;
  margin: 32px 0 16px;
  padding: 0 10px;

  &.SinglePointRangeSlider {
    & :global .input-range__track.input-range__track--active {
      background-color: white;
      box-shadow: none;
    }

    & :global .input-range__track:not(.input-range__track--active)::after {
      background-color: transparent;
    }

    & :global .input-range__track.input-range__track--background {
      background-image: linear-gradient(
        to right,
        rgb(253 137 93),
        rgb(246 197 68),
        rgb(226 230 52),
        rgb(140 226 104),
        rgb(39 223 168)
      );
    }
  }
}

.RangeSlider.hotToCool {
  & :global .input-range__track.input-range__track--active {
    background-image: linear-gradient(
      to right,
      rgb(253 137 93),
      rgb(246 197 68),
      rgb(226 230 52),
      rgb(140 226 104),
      rgb(39 223 168)
    );
  }

  & :global .input-range__track:not(.input-range__track--active)::after {
    background-color: #fff;
  }

  & :global .input-range__track.input-range__track--background {
    background-color: #fff;
  }
}

.RangeSlider.coolToHot {
  & :global .input-range__track.input-range__track--active {
    background-image: linear-gradient(
      to left,
      rgb(253 137 93),
      rgb(246 197 68),
      rgb(226 230 52),
      rgb(140 226 104),
      rgb(39 223 168)
    );
  }

  & :global .input-range__track:not(.input-range__track--active)::after {
    background-color: #fff;
  }

  & :global .input-range__track.input-range__track--background {
    background-color: #fff;
  }
}

.RangeSlider.greenGradient {
  & :global .input-range__track.input-range__track--active {
    background-image: linear-gradient(to right, #ecfc82, #15deb2);
  }

  & :global .input-range__track:not(.input-range__track--active)::after {
    background-color: #fff;
  }

  & :global .input-range__track.input-range__track--background {
    background-color: #fff;
  }
}

.DropdownRange.UserBuyingPowerInListPriceFilter {
  & .DropdownRangeMin {
    max-width: 150px;
  }

  & .DropdownRangeMax {
    min-width: 210px;
  }
}

.ReturnButton {
  margin: 20px 0;
}

.CancelButton,
.CancelButton:hover {
  background: white;
  box-shadow: none;
  margin-left: 20px;
  color: #4a4a4a;
}

.FiltersToggle {
  line-height: 22px;
  padding: 12px 0;
  text-align: right;
  cursor: pointer;
}

.FiltersToggle > span {
  font-weight: 900;
  font-size: 16px;
}

.FiltersToggle.ShowAdvanced {
  text-align: center;
  border-top: var(--filters-border);
  padding: 30px 40px 20px;
  margin: 10px 0;
  white-space: nowrap;
  width: 100%;
  display: flex;
  justify-content: center;
}

.FiltersToggle.HideAdvanced {
  font-size: 14px;
  border-top: var(--filters-border);
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.ChevronIcon,
.ChevronIcon-svg {
  display: inline;
}

.ChevronIcon-svg {
  height: 18px;
}

.ShowAdvanced .ChevronIcon {
  padding-left: 18px;
  position: relative;
  top: 4px;
}

.ShowAdvanced .ChevronIcon-svg {
  transform: rotate(-90deg);
}

.HideAdvanced .ChevronIcon {
  padding-left: 8px;
  position: relative;
  top: 4px;
}

.HideAdvanced .ChevronIcon-svg {
  transform: rotate(90deg);
}

.FilterSectionHeading {
  font-weight: 400;
  padding-right: 15px;
  margin: 30px 0 10px;
}

.MLSSearchLink {
  font-size: 14px;
  color: #4a4a4a;
  text-decoration: underline;
  display: block;
  font-weight: bold;
  cursor: pointer;
  margin-top: 24px;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.LockedComponentContainer {
  & .LockedContentContainer {
    display: flex;
    justify-content: flex-start;
    left: -30px;

    & .LockedContentButton {
      text-align: left;
    }

    & .LockIcon {
      & svg {
        height: 23px;
        width: 23px;

        & path {
          stroke-width: 2px;
        }
      }
    }
  }
}
