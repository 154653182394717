.DesktopNavItem {
  margin-right: 35px;
  cursor: pointer;
  position: relative;
  border: none;
  background: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
}

.DesktopNavItemUnderline {
  position: absolute;
  top: calc(100% + 2px);
  width: 100%;
  height: 2px;
}
