@import './_breakpoints.css';

.MLSAttributionPDPNWMLSException {
  font-size: 11px;
  font-weight: 200;
  margin-top: 3px;
}

@media (--small-screen) {
  .MLSAttributionPDPNWMLSException {
    margin-left: 10px;
  }
}
