@import './_breakpoints.css';

.MLSAttribution {
  font-size: 9px;
  font-weight: 200;
  padding: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: white;
}

.PDPRightRailCard {
  font-family: Helvetica;
  width: 352px;
  border-radius: 14px;
  background-color: #fff;
  box-shadow: 0 22px 19px -3px rgb(0 0 0 / 17%);
  box-sizing: border-box;
  transform: translate(0, -40px);
  position: sticky;
  top: var(--topSpacing, 126px); /* --topSpacing is set by JS */
  transition: top 300ms;
  /* z-index is needed to ensure that the box-shadow overlaps other sections on the page */
  z-index: 1;
  margin-top: 10px !important;

  & .ContentWrapper {
    background: #364656;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
  }

  &.PDPRightRailCardInCollapsedPDP {
    margin-top: 0 !important;

    & .ContentWrapper {
      background: transparent;
      padding: 15px;
      /* For search page PDP mobile card expanding to full PDP */
      transition: padding 0.3s;
    }

    & .LogoLabel,
    & .ListingStatusSection,
    & .MonthlyPaymentsSection {
      color: #4a4a4a;
    }

    & .MLSAttribution {
      color: #6d7278;
    }

    & .ListingStatusSection {
      margin-bottom: 16px;
    }
  }
}

.LogoAndLabel {
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;

  /* stylelint-disable no-descending-specificity */
  & .LogoLabel {
    font-size: 13px;
    color: white;
    font-family: Helvetica;
    font-weight: 500;
    align-self: center;
  }
  /* stylelint-enable no-descending-specificity */

  & .LogoWrapper {
    width: 18px;
    height: auto;
    margin: 0 5px;
  }
}

/* stylelint-disable no-descending-specificity */
.ListingStatusSection {
  width: 100%;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;

  & .ListingStatus {
    font-size: 15px;
    font-weight: 900;
    white-space: nowrap;
    text-align: center;
  }

  & .MonthlyPaymentsSection {
    white-space: nowrap;
    color: #fff;

    & .Label {
      font-size: 11px;
      font-weight: 300;
      margin-right: 10px;
    }
  }

  & .HorizontalSeparator {
    border-bottom: 2px solid #f1f1f1;
    margin: 5px auto;
    max-width: 172px;
  }
}
/* stylelint-enable no-descending-specificity */

.PillButton {
  font-family: Helvetica;
  font-weight: bold;
  height: 44px;
  width: 245px;
  border-radius: 22px;
  font-size: 15px;
}

.ShareAndSavePropertySection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 35px 20px 40px;
  width: 100%;
  box-sizing: border-box;

  & .ShareButtonContainer {
    border-right: 1px solid #f0f0f0;
  }

  & .WatchListActionButton,
  & .ShareButtonContainer {
    height: 75px;
    font-size: 12px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & .ShareButton {
      transform: none;

      & .HeartIcon svg {
        transform-origin: top center;
        width: 22px;
        height: 20px;
      }
    }

    & .CircleButton {
      cursor: pointer;
      height: 56px;
      width: 56px;
      transform: translateY(-10px);
    }

    & .ButtonLabel {
      font-weight: 300;
      position: absolute;
      bottom: 5px;
      left: 0;
      width: 100%;
      text-align: center;
      display: block;
    }
  }
}

@media (--small-screen) {
  .PDPRightRailCard {
    position: static;
    width: calc(100% - 20px);
    margin: 0 auto 25px;
  }
}
