.FooterSubsection {
  padding: 0 20px;
  margin: auto;
  margin-top: 50px;
  max-width: 1200px;
}

.FooterText {
  text-align: center;
  font-size: 12px;
  font-weight: 100;
}

.FooterLogo {
  margin-bottom: 25px;
  text-align: center;

  & img {
    max-width: 200px;
  }
}
