.SaveFilterButton {
  margin-bottom: 0;
  margin-top: 0;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: var(--save-button-button-height);
}

.SaveButtonIcon {
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SaveButtonIcon,
.SaveButtonIcon-svg {
  height: var(--save-button-button-height);
  width: 19px;
}

.SaveButtonIcon.Spinner,
.SaveButtonIcon-svg.Spinner-svg {
  height: var(--save-button-button-height);
  width: 13px;
  transform: scale(1.2);
}

.SaveButtonIcon.Spinner {
  & svg {
    display: block;
    height: 100%;
    width: 100%;
    animation: spin 4s linear infinite;

    & circle {
      stroke-dashoffset: 0;
      stroke-dasharray: 300;
      stroke-width: 5.334%;
      stroke-miterlimit: 10;
      stroke-linecap: round;
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-animation: circle-spin 3s linear infinite;
      animation: circle-spin 3s linear infinite;
      stroke: white;
      fill: transparent;
    }
  }
}

.Arrow {
  position: absolute;
  font-size: 16px;
  width: 10px;
  transform: scaleX(2);
  border: none;
  line-height: 8px;
  color: #fff;
  margin-top: 32px;
  z-index: 100;
  margin-left: 40px;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}

@keyframes circle-spin {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -600;
  }
}

:root {
  /* save and spinning icon are matched with save button height so that it can be centered properly */
  --save-button-button-height: 34px;
}
