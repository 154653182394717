.PropertyCardCTASection {
  padding: 0 0 5px;
  width: calc(100% + 20px);
  box-sizing: border-box;
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .PropertyCardValueInline,
  & .PropertyCardValueStacked {
    display: flex;
    justify-content: space-between;

    & .PriceRow {
      text-align: left;

      & .ValueLabel,
      & .Value {
        color: #4a4a4a;
        font-size: 18px;
        font-weight: 900;
      }
    }

    & .ShowDetailsCTA {
      color: #4a4a4a !important;
      font-size: 12px;
      font-weight: 900;
      margin-right: 5px;
      margin-left: 5px;
    }
  }

  & .ShowDetailsButton {
    background-color: #f8f7f7;
  }

  & .ShowDetailsButton,
  & .SeeAvailableLoanOptions {
    height: 55px;
    font-size: 16px;
    font-weight: 900;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  & .ShowDetailsButton.forceLowerSpacing {
    margin-top: 5px;
    margin-bottom: 31px;
  }
}

/* overriding css to offset margin issue compared to other cobrands due to additional see loan option button */
.PropertyCardValue {
  margin: 0 0 10px !important;
  width: auto !important;
}

.MLSAttributionUnderCTASection {
  font-size: 9px;
  color: #6d7278;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 30%);
  text-align: center;
  margin: 0 auto;
  height: 11px;
}
