@import './_breakpoints.css';

.NativeAppError {
  margin: 200px auto;
  padding: 0 30px;
  max-width: 400px;
  font-size: 18px;
  font-weight: 100;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.NativeAppError p {
  margin: 2px;
}

.NativeAppError svg,
.NativeAppError img {
  width: 70px;
  margin-bottom: 20px;
}

.CTAButton {
  margin-top: 30px;
}

@media (--small-screen) {
  .NativeAppError {
    margin: 150px auto;
  }
}
