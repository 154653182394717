@import './_constants.css';

.PropertyCardValueStacked {
  display: flex;
  flex-direction: column;
  line-height: 24px;
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;
  width: 200px;
}

.PropertyCardValueStacked.PropertyCardValueNoEstimatedPayment {
  width: auto;
}

.ValueLabel {
  margin-right: 6px;
}

.ValueLabel,
.Value {
  font-size: 15px;
  font-weight: 900;
  display: inline-block;
}

.PriceRowNoEstimatedPayment {
  margin-top: 10px;

  & .ValueLabel,
  & .Value {
    font-size: 18px;
  }
}

.HorizontalSeparator {
  height: 2px;
  transform: scaleY(-1);
  background-color: #e9e9e9;
}

.EstimatedMonthlyPayment {
  font-size: 11px;
  font-weight: 100;
  white-space: nowrap;
}

.BottomLabel {
  align-self: center;
  font-size: 9px;
  color: #6d7278;
  position: absolute;
  margin-top: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(var(--propertyCardWidth) - 150px);
  font-weight: 200;
}
