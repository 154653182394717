.UnreadAlertsResetPage {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 67vh;
  flex-direction: column;
  position: relative;

  & button {
    position: absolute;
    top: calc(50% + 180px);
  }
}

.Button {
  width: 200px;
  height: calc(200px - calc(200px / 10));
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  background-image: linear-gradient(30deg, #5840ea 0%, #3a61de 100%);
  box-shadow: 0 15px #392ac1;
  font-size: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  & label {
    transform: perspective(400px) rotateX(10deg);
    transform: skewY(20px);
    font-size: 30px;
    font-weight: bold;
  }

  &:active {
    box-shadow: 0 0 #392ac1;
    transform: translate(0, 15px);
    transition: 0.1s all ease-out;
  }
}
