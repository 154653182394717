.SaveSearchFloatingButton {
  position: absolute;
  right: 14px;
  height: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 57%);
  color: #fff;
  font-size: 10px;
  font-weight: 900;
  top: 18px;
  z-index: 2;
}

.MobileButtonIcon {
  margin-right: 8px;
}

.MobileButtonIcon,
.MobileButtonIcon-svg {
  height: 13px;
  width: 13px;
}

.MobileButtonIcon.Spinner,
.MobileButtonIcon-svg.Spinner-svg {
  height: 13px;
  width: 13px;
  transform: scale(1.2);
}

.MobileButtonIcon.Spinner {
  & svg {
    display: block;
    height: 100%;
    width: 100%;
    animation: spin 4s linear infinite;

    & circle {
      stroke-dashoffset: 0;
      stroke-dasharray: 300;
      stroke-width: 5.334%;
      stroke-miterlimit: 10;
      stroke-linecap: round;
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-animation: circle-spin 3s linear infinite;
      animation: circle-spin 3s linear infinite;
      stroke: white;
      fill: transparent;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}

@keyframes circle-spin {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -600;
  }
}
