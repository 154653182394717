@import './_breakpoints.css';

:root {
  --authButtonHeight: 42px;
}

/* The button and modal container */
.AuthDropdownContainer {
  position: relative;
  text-align: right;

  & .AuthButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--authButtonHeight);
    cursor: pointer;
    box-sizing: border-box;
    padding: 0 calc(var(--authButtonHeight) / 2);
    border: none;
    overflow: hidden;
    width: var(--authButtonHeight);
  }

  & .MenuNotificationCount {
    position: absolute;
    top: 8px;
    left: -13px;
    border: 2px solid #fff;
  }

  & .LoggedOutAuthIcon {
    position: relative;
  }

  & .LoggedInAuthIcon {
    position: absolute;
    /* this is for ie-11 compatibility because icon does not have the right top right left bottom set up by default */
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    padding: 13px 0;
  }

  & svg {
    height: 14px;
    width: 15px;
    cursor: pointer;
    fill: white;
  }

  & svg :global .element-to-fill {
    fill: #fff;
  }

  & .AuthButtonLabel {
    color: #fff;
    white-space: nowrap;
    margin-right: 15px;
  }

  & .LoggedInAuthButtonLabel {
    color: transparent;
  }

  & .AuthDropdown {
    transform: translate(0, 0);
    max-width: 132px;
    background: white;
    margin-top: 10px;
    position: absolute;
    box-shadow: -2px 2px 10px #dadada;
    /* putting this on top of backdrop comp and above all else */
    z-index: 201;
  }

  & .Arrow {
    content: '▲';
    position: absolute;
    text-shadow: 0 2px 0 #fff, 0 -2px 4px #efefef;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white;
    transform: scaleX(1.5);
  }

  & .BackdropScreen {
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* placing backdrop comp behind authbutton dropdown */
    z-index: 200;
  }
}

.TooltipContent {
  /* Can't nest this since it's rendered inside of a portal */
  padding: 0 !important;
}

/* this is to account for a gap between the button and the menu */
.button {
  margin: 0 0 20px;
  position: relative;
  top: 10px;
  color: inherit;

  &:hover {
    color: inherit;
    background: #fff;
  }
}

@media (--small-screen) {
  /* Hidden on mobile */
  .AuthDropdownContainer,
  .FlatButton {
    display: none;
  }
}
