@import './_breakpoints.css';
@value ch-black from './_colors.css';

.CustomPropertyBanner {
  border-radius: 14px;
  display: flex;
  padding: 24px 32px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  max-width: 838px;
  background-size: 65%;
  background-repeat: no-repeat;
  background-position: right 0% top 20%;
}

.TextColumn {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 2;
  max-width: 405px;
}

.CtaColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TitleCopy {
  font-size: 22px;
  font-weight: 900;
  letter-spacing: -0.53px;
  line-height: 33px;
}

.PillButton {
  padding: 14px 8px;
  font-size: 14px;
  line-height: 18px;
  min-width: 220px;
  vertical-align: middle;
}

/* NOTE: We need to force mobile styles on Homebuyer, as their
 * Tablet view is a cross between mobile and desktop */

/* NOTE: Please add any changes to .NarrowLayout to
 * the --small-screen media query as well */
.NarrowLayout {
  &.CustomPropertyBanner {
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 32px;
    height: auto;
    background-size: cover !important;
  }

  & .TitleCopy {
    text-align: left;
    line-height: 22px;
    margin: 0 0 4px;
    font-size: 18px;
  }

  & .PillButton {
    padding: 7px 32px;
    height: 33px;
    min-height: 33px;
    min-width: 155px;
    margin-bottom: 0;
    margin-top: 10px;
  }

  & .CtaColumn {
    text-align: center;
    max-width: 368px;
    width: auto;
    margin-left: 0;
  }

  & .TextColumn {
    margin-left: 0;
    margin-right: auto;
  }
}

/* NOTE: Please add any changes to --small-screen to
 * the .NarrowLayout class as well */
@media (--small-screen) {
  .CustomPropertyBanner {
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 32px;
    height: auto;
    background-size: cover !important;
  }

  .TitleCopy {
    margin: 0 0 4px;
    font-size: 18px;
    text-align: left;
    line-height: 20px;
  }

  .PillButton {
    padding: 7px 32px;
    height: 33px;
    min-height: 33px;
    max-width: none;
    min-width: 155px;
    margin-bottom: 0;
    margin-top: 10px;
  }

  .CtaColumn {
    text-align: center;
    max-width: 368px;
    width: auto;
    padding-top: 10px;
    margin-left: 0;
  }

  .TextColumn {
    margin-left: 0;
    margin-right: auto;
  }
}

@media (--tablet-screen) {
  .CustomPropertyBanner {
    max-width: 722px;
    margin: 0 auto;
    background-size: cover !important;
  }
}

@media (--tablet-screen-and-smaller) {
  .CtaColumn {
    width: 300px;
  }
}
