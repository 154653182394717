@value ch-red from './_colors.css';

.FormError {
  color: ch-red;
  font-size: 12px;
  composes: font-base from './_common.css';
  text-align: center;
}

.FormErrorAlert {
  display:flex;
  gap: 4px;
  align-items: center;;
}

.FormErrorMessage {
  display: inline-block;
  padding-left: 4px;
  position: relative;
  top: -2px;
}

.FormErrorIcon > svg {
  height: 16px;
  width: 16px;
  
  & :global .element-to-stroke {
    stroke: ch-red;
  }
}
