@import './_breakpoints.css';

.HomeSubpageTrackOrBuyHome {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  max-width: 1043px;
  margin: 0 auto;
  left: 0;
  right: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  /* Prevent items animating out of the page from briefly overlapping into the previous page */
  overflow: hidden;
}

.HighZoomScrollableWrapper {
  max-height: 100%;
}

@media (max-width: 1050px) {
  .HomeSubpageTrackOrBuyHome {
    padding: 40px 30px 40px 50px;
  }
  /* Only apply at higher zoom levels (narrower screen) where scrolling might be necessary since
   * overflow: auto causes box shadows of child elements to be clipped */
  .HighZoomScrollableWrapper {
    overflow: auto;
  }
}

@media (--small-screen) {
  .HomeSubpageTrackOrBuyHome {
    justify-content: flex-start;
    padding: 0;
  }
}
