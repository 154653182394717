@import './_breakpoints.css';
@import './_constants.css';
@value border-color from './_colors.css';

.DesktopContainer {
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  background-color: #f4f4f4;
}

.DesktopInnerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: var(--header-max-width-chase);
  width: 100%;
  height: 60px;
  padding: 0 var(--header-chase-left-padding);
  margin: auto;

  &.DesktopInnerContainerFullWidth {
    max-width: initial;
    padding-left: var(--header-chase-left-padding);
  }
}

.MobileContainer {
  display: none;
  width: 100%;
  margin: auto;
}

.AutoComplete {
  & .InputElement,
  & .Results {
    border-color: border-color;
  }
}

.DesktopSearchContainer {
  box-sizing: border-box;
  width: 100%;
  max-width: 375px;
  margin: 0 26px;
}

.DesktopLogoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  & .ClickableLogoByHouseCanary {
    width: 150px;
  }
}

.DesktopRightContainer {
  width: auto;
  display: block;

  &.DesktopRightContainerWithSearchField {
    max-width: 600px;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }
}

.button {
  min-width: 124px;
  padding-left: 5px;
  padding-right: 5px;
}

/* stylelint-disable selector-no-vendor-prefix */
_:-ms-fullscreen,
:root ul.Results {
  top: 3.4rem;
}
/* stylelint-enable selector-no-vendor-prefix */

.DesktopNavContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #545454;
  font-size: 12px;
  font-weight: 900;

  & .MenuNotificationCount {
    position: absolute;
    top: -5px;
    left: 40px;
  }
}

@media (--desktop-screen) {
  .ClickableLogo svg {
    width: 126px !important;
    height: 20px !important;
  }
}

@media (--tablet-screen) {
  .MobileSearchItemAnimationHiddenOnTablet.MobileSearchItemAnimation {
    /* Override Motion's inline display: block */
    display: none !important;
  }

  .Suggestion {
    display: flex;
    flex-direction: column;

    & .SuggestionText {
      display: block;
    }

    & .SuggestionMultiUnit {
      text-align: left;
      margin-left: 0;
    }
  }
}

@media (--tablet-screen-and-smaller) {
  .DesktopContainer {
    display: none;
  }

  .MobileSearchHomesBackArrow {
    top: 4px;
  }

  .MobileContainer {
    display: block;

    & .HeaderMobileBottomSection {
      padding: 5px 0;
    }
  }

  .MobileTopSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 48px;
    padding: 0 16px;
    box-sizing: border-box;
  }

  .ClickableLogo {
    margin-left: 0;

    & svg {
      height: 20px;
    }
  }

  .MobileMenuContainer {
    & .MobileSearchItemAnimation {
      background: #f4f4f4;
      width: 100%;
      box-sizing: border-box;
      position: relative;
    }

    & .SearchItem {
      height: 60px;
    }

    & .AutoComplete {
      max-width: 100% !important;
      transform: translateY(-5px);
      /* Stack above the .LayerItemsControl (SRP map controls) and the mobile bottom nav.
       * This is necessary due to the `transform` above pulling the child .Results
       * element out of the stacking context (nullifying the z-index of .Results) */
      z-index: 102;

      & .InputElement {
        width: calc(100% - 20px);
        margin: 0 auto;
        border: 1px solid #e5e5e5;
        border-radius: 6px;
        padding: 15px 12px 17px;
        background-color: white;
      }

      & .InputElement::placeholder {
        font-size: 14px !important;
        color: inherit;
      }

      &.AutoCompleteWithDropdown .InputElement {
        border-bottom: 1px solid rgb(0 0 0 / 12%);
      }

      & .SearchButton {
        width: 50px;
        top: 18px;
        right: 10px;
        border-radius: 0 6px 6px 0;
      }

      &.AutoCompleteWithDropdown .SearchButton {
        border-radius: 0 6px 6px 0;
      }

      & .InputWrapper {
        padding-top: 18px;
      }

      & .ResetButton {
        top: 18px;
        right: 60px;
      }

      & .Results {
        top: 65px;
        border-radius: 0;
        border: none;
        background: #f4f4f4;
      }

      & .HasVisibleLabel {
        top: 10px;
        left: 20px;
        font-size: 9px;
      }
    }
  }

  .MobileMenuList {
    padding: 0;
    margin: 0;
    list-style-type: none;

    &.hidden {
      opacity: 0;
    }

    & .MobileMenuListItem {
      padding: 17px 0;
      font-size: 14px;
      font-weight: 900;
      height: 19px;
      cursor: pointer;
      width: calc(100vw - 32px);
      margin: auto;
      -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    }

    & .MobileMenuListItem + .MobileMenuListItem {
      border-top: 1px solid rgb(0 0 0 / 12%);
    }

    & .MobileMenuListItem:last-of-type {
      padding-bottom: 5px;
    }
  }

  .MobileMenuScreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1;
    height: 100vh;
  }

  .MobileSearchIconContainer {
    display: flex;
    align-items: center;
    animation: fade-in 0.2s;
    margin-right: 15px;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    width: 23px;
  }

  .MobileSearchIconContainer .SearchIcon {
    position: relative;
    opacity: 1;
    transition: opacity 30ms;
    cursor: pointer;
  }

  .MobileSearchIconContainer.MobileSearchIconContainerActive .SearchIcon {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }
}

@media (--tablet-screen) {
  .DesktopContainer {
    height: 40px;
    border-bottom: none;
  }

  .DesktopInnerContainer {
    justify-content: flex-end;
  }

  .ClickableLogo svg {
    width: 118px !important;
    height: 25px !important;
  }
}

@media (--high-zoom-screen) {
  .MobileTopSection {
    height: 35px;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
