.InputElement {
  min-height: 90px;
  padding: 10px;
  width: 100%;
  resize: none;
  box-sizing: border-box;
  border: 1px solid #dedede;
  font-family: inherit;
}

.OffScreenLabel {
  composes: hide-off-screen from './_common.css';
}
