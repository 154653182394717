@import './_breakpoints.css';
@value box-shadow-soft from './_fontsAnimations.css';

.MapContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  /* No z-index here to allow the map controls within the map container to set their own z-indexes as desired */
}

:global .mapboxgl-map {
  /* Inherit cobrand font family defined on the app container */
  font-family: inherit;
}

/************* Mapbox Control Overrides *************/
/* Mapbox zoom control */
.MapContainer :global .mapboxgl-ctrl-group > button:focus {
  /* restore accessibility focus outline */
  outline: auto;
  box-shadow: 0 0 2px 2px #666;
}

.MapContainer :global .mapboxgl-ctrl-logo {
  display: none;
}

:global
  .no-focus-outline
  :local
  .MapContainer
  :global
  .mapboxgl-ctrl-group
  > button:focus {
  /* remove focus outline if not needed */
  outline: none;
  box-shadow: none;
}

.MapContainer :global .mapboxgl-ctrl {
  border: none !important;
  border-radius: 20px;
  box-shadow: box-shadow-soft !important;
  overflow: visible;

  & .mapboxgl-ctrl-zoom-in,
  & .mapboxgl-ctrl-zoom-out {
    background-image: none;
    font-family: 'Lucida Console', Monaco, monospace;
    font-size: 24px;
    font-weight: 100;
    line-height: 40px;
    width: 40px;
    height: 40px;
    color: inherit;
  }

  & .mapboxgl-ctrl-zoom-in {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    &:focus {
      border-radius: 21px 21px 0 0;
    }

    &::after {
      content: '+';
    }
  }

  & .mapboxgl-ctrl-zoom-out {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top: none;

    &:focus {
      border-radius: 0 0 21px 21px;
    }

    &::after {
      content: '-';
      font-size: 26px;
    }
  }
}

/* Prevent tap highlighting on iOS */
.MapContainer :global .mapboxgl-canvas {
  -webkit-tap-highlight-color: transparent;
}

.MapContainer :global .mapboxgl-canvas-container {
  z-index: 1;
  position: relative;
}

/* Mapbox popup */
.MapContainer :global .mapboxgl-popup {
  z-index: 1;
  animation: fade-in 0.4s;
  max-width: initial !important;
}

.MapContainer :global .mapboxgl-popup-tip {
  display: none;
}

.MapContainer :global .mapboxgl-popup-content {
  background: none;
  box-shadow: none;
  padding: 0;
}

.MapContainer :global .mapboxgl-popup-close-button {
  font-size: 20px;
  line-height: 16px;
  padding: 0;
  margin: 10px 10px 0 0;
  color: #666;
  font-weight: 600;
  z-index: 2;

  &:hover {
    background-color: initial;
    color: #999;
  }
}

.MapErrorMessage {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 1px #666;
  padding: 0 20px;
  box-sizing: border-box;
}

.TimeStamp {
  display: block;
  font-size: 10px;
  font-weight: 100;
  color: #ccc;
  margin-top: 20px;
}

@media print {
  .MapContainer {
    page-break-inside: avoid;
  }
}

@media (--small-screen) {
  .MapMarkerPopup {
    /* Full-width marker popup on mobile */
    width: calc(100vw - 40px);
  }
  /* Map with controls outside of the map viewport (only on mobile PDP currently) */
  .MapContainer.MapContainerWithTopLayerGroupsControl {
    height: calc(100% - 36px);
    margin-top: 36px;
    overflow: visible;
  }

  :global .mapboxgl-ctrl-attrib {
    font-size: 13px;
  }
}

@keyframes circular-expand {
  from {
    transform: scale(1) rotateX(50deg);
  }

  to {
    transform: scale(5.5) rotateX(50deg);
  }
}

@keyframes circular-expand-fade-out {
  0%,
  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
