.CopyableField {
  display: flex;
  border: 1px solid rgb(0 0 0 / 12%);
  height: 40px;
}

.InputWrapper {
  align-self: stretch;
  flex: 1;
  border-right: 1px solid rgb(0 0 0 / 12%);
  box-sizing: border-box;
  overflow-y: hidden;
}

.InputElement {
  width: 100%;
  height: 100%;
  margin-top: 0;
  border: 0;
}

.CopyControl,
.CopyControlButton {
  display: flex;
  align-items: center;
  min-width: 60px;
}

.CopyControlButton {
  min-width: initial;
  padding: 0 10px;
  height: 100%;
  color: #4a4a4a;

  & .CopyIcon svg {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    fill: #4a4a4a;
    vertical-align: middle;
  }
}
