@import '../_breakpoints.css';

.ModalButtonSVG {
  padding-right: 4px;
}

.ModalButtonSVG,
.ModalButtonSVG-svg {
  height: 18px;
  width: 18px;
  vertical-align: middle !important;
}

.ModalButtonSVG_Wells {
  padding-right: 4px;
}

.ModalButtonSVG_Wells,
.ModalButtonSVG_Wells-svg {
  height: 37px;
  width: 37px;
  vertical-align: middle !important;
}

.YourTeamTriggerButton {
  height: 50px !important;
  padding: 0 15px !important;
  font-size: 12px;
  box-shadow: 0 3px 15px rgb(0 0 0 / 50%) !important;
}

.YourTeamTriggerButtonWellsFargo {
  height: 49px !important;
  padding: 0 10px 0 6px !important;
  font-size: 10px;
  box-shadow: 0 3px 15px rgb(0 0 0 / 50%) !important;
  border-radius: 65px !important;
}

.YourTeamTriggerButton,
.YourTeamTriggerButtonWellsFargo {
  z-index: 100;
  width: auto !important;
  line-height: 25px;
  color: #4a4a4a !important;
  position: fixed;
  font-weight: 500;
  bottom: 55px;
  right: 21px;
}

.YourTeamTriggerButtonAdjusted,
.YourTeamTriggerButtonWellsFargoAdjusted {
  right: 60px;
}

@media (--tablet-screen-and-smaller) {
  .YourTeamTriggerButton,
  .YourTeamTriggerButtonWellsFargo {
    bottom: 80px;
    right: 10px;
    font-size: 10px;
    padding: 0 10px !important;
    height: 35px !important;
  }

  @media (--four-hundred-percent-zoom) {
    .YourTeamTriggerButton,
    .YourTeamTriggerButtonWellsFargo {
      position: static;
    }
  }

  .YourTeamTriggerButtonWellsFargo {
    padding: 0 8px 0 6px !important;
    height: 35px !important;
  }

  .ModalButtonSVG_Wells-svg {
    height: 25px;
    width: 25px;
  }
}
