.CaratIcon {
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.CaratIcon-svg {
  transform: scale(0.625);
}

.CaratIcon :global .element-to-fill {
  fill: #fff;
}
