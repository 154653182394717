.CheckboxPicker {
  position: relative;
  /* reset browser default <fieldset> styles */
  border: 0;
  padding: 0.01em 0 0;
  margin: 0;
  min-width: 0;

  & .CheckboxLabel {
    font-weight: 900;
  }

  & .Checkbox {
    margin: 2px;
    position: relative;
    left: 12px;
    /* When all checkboxes + labels are the same width, overflowing checkboxes line up properly */
    min-width: 136px;
  }

  & .LockedComponentContainer {
    margin: 2px;
    /* When all checkboxes + labels are the same width, overflowing checkboxes line up properly */
    min-width: 120px;

    & .LockedContentContainer {
      left: 12px;
    }
  }
}

.CheckboxPickerGroup {
  display: flex;
  flex-wrap: wrap;
}

.CheckboxPickerGroupLabelOffscreen {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
