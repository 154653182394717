@value text-color from './_colors.css';

.Button {
  flex-basis: calc(25% - 6px);
  margin: 2px;
  border: 1px solid text-color;
  border-radius: 3px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 900;
  cursor: pointer;
  background: none;
  box-sizing: content-box;
  padding: 0;
}

.Button.active {
  color: #fff;
  background: text-color;
}
