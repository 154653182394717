@import './_constants.css';
@import './_breakpoints.css';

.Homepage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
}

.HeaderWrapper {
  z-index: 3;
  position: relative;
}

.SubpageWrapper {
  width: 100vw;
  position: relative;
  flex: 1;
}

.ScrollSnapWrapper {
  height: 100%;
  width: 100vw;
  text-align: center;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  scroll-snap-type: y mandatory;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  & h2 {
    margin: 0;
    padding-top: 36px;
  }
}

.Subpage {
  /* Necessary to force any scrolling to jump to the next/prev subpage */
  height: 100%;
  scroll-snap-align: start;
  flex: none;
}

.SubpageFooter {
  max-width: var(--header-max-width);
  margin: auto;
  padding: 40px 28px;
  overflow: auto;
  max-height: 100%;
}

@media (--four-hundred-percent-zoom) {
  .ScrollSnapWrapper {
    scroll-snap-type: none;
  }

  .Subpage {
    height: auto;
    scroll-snap-align: unset;
  }
}
