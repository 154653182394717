.SectionHeading {
  color: #414042;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
}

.AvmBreakdownContainer {
  box-sizing: border-box;
  border: 1px solid #f7f7f7;
  border-radius: 14px;
  background-color: #fff;
  box-shadow: 0 22px 19px -3px rgb(0 0 0 / 17%);
  display: flex;
  flex-direction: row;
  max-width: 749px;

  & .NoAvmFactors {
    padding: 30px;
  }

  & .LockedComponentContainer {
    & .LockedContentContainer {
      & .LockedContentButton {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        & .LockIcon {
          & svg {
            height: 40px;
            width: 40px;

            & path {
              stroke-width: 3px;
            }
          }
        }

        & .LockMessage {
          font-size: 16px;
          width: 70%;
        }
      }
    }
  }
}

.disabled {
  filter: blur(4px);
}

.NoAvmSection {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  color: #fff;
  width: 385px;
  font-size: 12px;
  font-weight: 900;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AvmValueSection {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  color: #fff;
  width: 385px;
  position: relative;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .AvmValueWrapper {
    cursor: pointer;
    text-align: center;
  }

  & .AvmBreakdownAvmValue {
    margin: 10px 0 5px;
  }
}

.AvmFactorsSection {
  box-sizing: border-box;
  padding: 30px;
  width: 385px;

  & .AverageHomeDetailsSection {
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    padding: 0 0 15px 15px;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;

    &.NoAvmFactors {
      padding: 0 25px;
    }
  }

  & .DefaultCTAContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 25px 0 0;

    & .SeeMoreCTA {
      margin: 0;
      height: 45px;
    }
  }

  &.HideSeeMoreAndCenterContent {
    padding: 30px;
  }
}

.DefaultAvmValueExplanation {
  line-height: 17px;
  letter-spacing: 0;
  font-size: 12px;
  font-weight: 300;
}

.AvmFactor {
  cursor: pointer;
  border-bottom: 1px solid #eee;
  padding: 8px 0;
  display: flex;
  font-size: 12px;
  min-height: 50px;

  &:first-child {
    padding: 0 0 20px 20px;
    min-height: unset;
    font-weight: 200;
    line-height: 2;
  }
}

.AvmFactorIcon {
  width: 75px;
  margin-right: 26px;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.AvmFactorIconPositive {
    & :global .element-to-stroke {
      stroke: #13c9a1;
    }

    & :global .element-to-fill {
      fill: #13c9a1;
    }
  }

  &.AvmFactorIconNegative {
    & :global .element-to-stroke {
      stroke: #fd8a5f;
    }

    & :global .element-to-fill {
      fill: #fd8a5f;
    }
  }

  & .icon,
  & .icon-svg {
    max-height: 36px;
  }
}

.AvmFactorIconLabel {
  font-weight: 900;
  margin-bottom: 5px;
}

.AvmFactorDescription {
  font-size: 12px;
  font-weight: 100;
  margin-bottom: 5px;
}
