@import './_constants.css';
@import './_breakpoints.css';
@value box-shadow-property-card from './_fontsAnimations.css';

.SRPFinanceCTA {
  box-sizing: border-box;
  width: calc(var(--propertyCardWidth) - 15px);
  min-height: var(--propertyCardHeight);
  border: 1px solid #e8e8e8;
  border-radius: 14px;
  background-color: #fff;
  font-weight: 900;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: box-shadow-property-card;
  }
}

.CTAHeader {
  color: #20203f;
  font-size: 14px;
  line-height: 19px;
  padding-top: 38px;
  padding-left: 20px;
}

.CTABody {
  color: #20203f;
  font-size: 35px;
  letter-spacing: -0.67px;
  line-height: 41px;
  padding-top: 20px;
  padding-left: 20px;
}

.PillButton {
  height: 55px;
  width: 299px;
  background-color: #4d4de6;
  margin: auto;
  display: block;
  color: #fff;
  font-weight: 900;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  border: none;
}

.MainContent {
  height: 152px;
  margin-bottom: 90px;
}

@media (--x-small-screen) {
  .SRPFinanceCTA {
    width: 100%;
  }
}
