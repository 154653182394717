@import './_constants.css';
@import './_breakpoints.css';

.LoadingSection {
  height: calc(100vh - 100px);
}

@media (--small-screen) {
  .LoadingSection {
    height: calc(100vh - 100px - var(--mobileBottomNavHeight));
  }
}
