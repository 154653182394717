@import './_breakpoints.css';
@import './_constants.css';

.ConfirmEmailBanner {
  box-sizing: border-box;

  & .DismissibleBanner {
    background: linear-gradient(90deg, #20203f 0%, #3a3a79 100%);
    box-shadow: none;
    padding: 0;
    z-index: 103;
  }

  & .DismissibleBanner .Window {
    width: 100%;
    padding: 8px 0;
  }

  & .DismissibleBanner .Content {
    height: 60px;
    width: 100%;
    padding: 0 40px;
  }

  & .EmailIcon .EmailIcon-svg {
    width: 30px;
    height: 27px;
    margin-right: 25px;
  }
}

.ConfirmEmailBannerContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 300;
}

.ResendButton {
  composes: strip-button-styles from './_common.css';
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.DesktopButtonText {
  display: block;
  white-space: nowrap;
}

.MobileButtonText {
  display: none;
  white-space: nowrap;
}

@media (--tablet-screen) {
  .ConfirmEmailBanner .DismissibleBanner .Content {
    height: 60px;
    width: 100%;
    padding: 0 16px;
  }
}

@media (--small-screen) {
  .ConfirmEmailBanner .DismissibleBanner .Content {
    height: 60px;
    width: 100%;
    padding: 0 16px;
  }

  .DesktopButtonText {
    display: none;
  }

  .MobileButtonText {
    display: block;
  }
}
