@import './Dropdown.css';
@import './_breakpoints.css';
/**
  * text-color and border-color are imported in Dropdown.css
  * we do not have to import them again here
*/
@value ch-green, hover-grey, light-grey from './_colors.css';

:root {
  --ease-timing: 60ms;
}

.ShowFiltersButton {
  box-sizing: border-box;

  & .FilterIcon,
  & svg {
    display: flex;
    align-items: center;
    margin: 4px 4px 0 0;
    width: 20px;
    height: 20px;
  }

  & .FilterIconDisplay {
    display: none;
  }
}

.SearchTopBarFilters {
  display: flex;
  align-items: center;
  padding-right: 23px;

  /* Place popover menu top at bottom of button */
  & .menu.topLeft {
    top: 45px;
    left: 5px;
  }

  & .PillButton {
    margin-bottom: 0;
    margin-top: 0;
    width: 160px;
    font-size: 14px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  & .ButtonMenuContainer {
    box-sizing: border-box;
  }
}

.SaveButtonIcon,
.SaveButtonIcon-svg {
  height: 19px;
  width: 19px;
}

.SaveButtonIcon {
  padding-right: 8px;
}

.SaveButtonIcon.Spinner,
.SaveButtonIcon-svg.Spinner-svg {
  height: 13px;
  width: 13px;
  transform: scale(1.2) translateY(1px);
}

.SaveButtonIcon.Spinner {
  & svg {
    display: block;
    height: 100%;
    width: 100%;
    animation: spin 4s linear infinite;

    & circle {
      stroke-dashoffset: 0;
      stroke-dasharray: 300;
      stroke-width: 5.334%;
      stroke-miterlimit: 10;
      stroke-linecap: round;
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-animation: circle-spin 3s linear infinite;
      animation: circle-spin 3s linear infinite;
      stroke: white;
      fill: transparent;
    }
  }
}

.Filters {
  display: flex;
  align-items: center;
}

.FiltersActiveCountIndication {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 17px;
  width: 17px;
  border-radius: 100%;
}

.SaveFiltersButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin: 5px;
  align-self: flex-end;
}

.menuInner {
  /* Allow dropdown menus to overflow below the popover bounds */
  clip: auto !important;
}

.MultiSelect {
  display: flex;
  flex-direction: column;
  padding: 10px 30px 15px;
  min-width: 290px;
}

.NumberAdjuster {
  padding: 15px 25px;
}

.DropdownRange {
  width: 300px;
  padding: 15px 25px;

  & .DropdownRangeMin {
    min-width: 100px;
  }

  & .DropdownRangeMax {
    min-width: 100px;
  }
}

.DropdownRange.UserBuyingPowerInListPriceFilter {
  width: 350px;

  & .DropdownRangeMin {
    max-width: 120px;
  }

  & .DropdownRangeMax {
    max-width: 230px;
  }
}

.NumberAdjusterAnimateIn {
  /* stylelint-disable-next-line value-keyword-case */
  composes: NumberAdjuster;
}

.MultiSelectAnimateIn {
  /* stylelint-disable-next-line value-keyword-case */
  composes: MultiSelect;
}

.DropdownRangeAnimateIn {
  /* stylelint-disable-next-line value-keyword-case */
  composes: DropdownRange;
}

.MultiSelectAnimateIn,
.NumberAdjusterAnimateIn,
.DropdownRangeAnimateIn {
  opacity: 0;
  animation: fade-in 0.3s ease 0.2s 1 forwards;
}

.FilterCount {
  visibility: visible;
}

.IconWrapper {
  display: inherit;
  margin-right: 2px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}

@keyframes circle-spin {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -600;
  }
}

@media screen and (max-width: 1300px) {
  .ButtonMenuContainer:nth-child(3) {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .ButtonMenuContainer:nth-child(2) {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  .ButtonMenuContainer:nth-child(1) {
    display: none;
  }
}

@media (--tablet-screen) {
  .SearchTopBarFilters {
    /* On tablet, there's less padding on the right side of the property card sidebar */
    padding-right: 0;
  }
}
