@import './_breakpoints.css';
@import './_constants.css';

.PropertyPageTopBar {
  background-color: #fff;
  width: 100%;
}

.PropertyPageTopBarInner {
  display: flex;
  height: 57px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 20rem;
  max-width: var(--header-max-width);
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

@media (--tablet-screen-and-smaller) {
  .PropertyPageTopBarInner {
    justify-content: center;

    /* Breadcrumbs are hidden on small screens, so when both breadcrumbs and the toggle
     * are hidden, hide the parent div as well */
    &.WithToggleHidden {
      display: none;
    }
  }
}
