.HeaderMobileSearchHomesNav {
  height: 34px;
  line-height: 33px;
  box-sizing: border-box;

  & .HeaderMobileSearchHomesButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
  }

  & .MobileSearchHomesBackArrow {
    position: relative;
    width: 12px;
  }

  & .MobileSearchHomesBackArrow-svg {
    width: 12px;
  }

  & .SearchHomesText {
    color: #4a4a4a;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 0;
    margin-left: 10px;
  }
}
