@value ch-orange, ch-black, hc-yellow, hc-green from './_colors.css';

.active {
  color: hc-green;
  font-size: 0.875rem;
}

.price {
  color: ch-black;
  font-size: 1rem;
  margin-left: 1rem;
}

.pending {
  color: hc-yellow;
}

.withdrawn {
  color: ch-orange;
}

.sold {
  color: ch-orange;
  font-size: 0.875rem;
}

.contingent {
  color: hc-yellow;
}
