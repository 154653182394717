@import './_constants.css';

.FooterSectionWithSeparator {
  padding: 0 15px;
}

.Columns {
  display: flex;
  position: relative;
  width: 100%;
  min-width: 320px;
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 1.25rem;
  box-sizing: border-box;
  z-index: 1;
}

.LeftSide {
  flex: 1;
  padding-right: 20px;
  /* Cause content to fit within this column and not push the right-column off the page */
  overflow: hidden;
}

.RightSide {
  flex: none;
}

.PropertyPageTabletContents {
  & .CarouselRow {
    height: var(--propertyCardPhotoHeightTablet);
    width: 100%;

    & .LoadingSection,
    & .Slider,
    & .SliderItem {
      height: var(--propertyCardPhotoHeightTablet);
    }

    & .SliderItem {
      padding: 0;
    }

    & .LoadingSection svg {
      height: 60px;
    }
  }
}
