@import './_constants.css';
@value text-color, text-color-light, ch-blue from './_colors.css';

.Filter {
  flex: 1;
  overflow-y: scroll;
}

.MobileFilterControl {
  border-radius: 14px;
  flex-grow: 1;
  font-weight: 300;
  display: flex;
  flex-direction: column;

  /* Generally we want overflow: scroll to allow all filter options to be seen on small mobile screens,
   * but in specific cases (for tooltips) we need to allow overflow to be visible */
  &.MobileFilterControlAllowingOverflow .Filter {
    overflow-y: visible;
  }
}

.Header {
  background: rgb(255 255 255 / 70%);
  border-radius: 14px 14px 0 0;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 18px;
}

.SelectedFilter {
  font-size: 14px;
  margin: 0;
}

.Results {
  font-size: 12px;
  min-width: 74px;
  text-align: right;
}

.FilterControl {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;
  padding: 0 20px;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  -webkit-tap-highlight-color: transparent;
}

.Description {
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin: 10px 0 12px;
  padding: 0 20px;
  box-sizing: border-box;
}

.Controls {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;
}

.ControlActionButtons {
  display: flex;
  width: 100%;
  margin-bottom: 18px;
}

.ActionButton {
  width: calc(50% - 20px);
  margin: 0 10px;
  min-height: 40px;
  border-radius: 4px;
  box-shadow: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}

.ActionButton.apply {
  color: #fff;
  background: text-color;
}

.ActionButton.cancel {
  color: text-color;
  background: #fff;
  border: #4a4a4a solid 1px;
}

.ActionButton:active {
  color: text-color-light;
}

.BuyingPowerValue {
  line-height: 27px;
}

.BuyingPowerSubtext {
  font-size: 9px;
  font-weight: 200;
  line-height: 0;
}

:export {
  headerAndControlsCombinedHeight: var(--headerAndControlsCombinedHeight);
}
