.PropertyCardChaseCTASection {
  /* locking this height so that property card height stays the same between active listings and non-active ones */
  height: 145px;
  padding: 15px 15px 30px;
  width: 100%;
  box-sizing: border-box;

  & .ValueSectionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .PropertyCardValueInline,
  & .PropertyCardValueStacked {
    min-height: 52px;
    display: block;
    text-align: left;

    & .PriceRow {
      white-space: nowrap;
      text-align: left;
    }

    & .ShowDetailsCTA {
      text-align: left;
    }
  }
}

.CTASectionActiveListing {
  background-color: #4a4a4a;
  color: white;

  & .PropertyCardValueInline,
  & .PropertyCardValueStacked {
    width: 100%;
    min-height: 52px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;

    & .PriceRow {
      white-space: break-spaces;
      text-align: left;
    }

    & .ShowDetailsCTA {
      color: white !important;
      margin-right: 5px;
    }

    & .ChevronIcon {
      height: 18px;
    }

    & .ChevronIcon-svg path {
      fill: white;
    }
  }

  & .BottomLabel {
    margin-top: unset;
    position: absolute;
    top: -17px;
    left: 10px;
  }
}
