@import './_breakpoints.css';
@import './_constants.css';
@value rounded-border, box-shadow-property-card from './_fontsAnimations.css';
@value text-color from './_colors.css';

.ScaffoldingMarketingWrapper {
  background: #fff;
  color: text-color;
  border-radius: rounded-border;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScaffoldingMarketingInnerWrapper {
  width: 300px;
  height: 250px;
  overflow: hidden;
  max-width: 300px;
  max-height: 250px;
  min-width: 300px;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (--x-small-screen) {
  .ScaffoldingMarketingWrapper {
    margin-right: 0;
    width: 100%;
  }
}
