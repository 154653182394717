.PropertyDataContainer {
  padding: 10px 0;
  color: inherit;
}

.Address {
  font-size: 16px;
  font-weight: 900;
  white-space: nowrap;
  margin: 0 0 10px;
  text-align: left;
}

.HeadingSection {
  display: flex;
  flex-direction: row;
  text-align: center;

  & .icon {
    margin-right: 8px;
  }

  & .icon,
  & .icon-svg {
    max-height: 25px;
    max-width: 25px;
  }

  & .Heading {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
  }
}

.PropertyData {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
}

.DaysOnMarket {
  font-size: 12px;
  line-height: 16px;
}

.VerticalSeparator {
  height: 12px;
  margin: 0 10px;
  border-right: 1px solid #eaeaea;
}
