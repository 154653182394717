/* stylelint-disable block-no-empty */
.AvmBreakdownToolTipTextChase {
}
/* stylelint-enable block-no-empty */

.SubHeading {
  color: #414042;
  font-family: inherit;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}

.ToolTipText {
  color: #414042;
  font-family: inherit;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}
