@value border-color-light from './_colors.css';

.SavedSearchFilterDropdown {
  box-sizing: border-box;

  & label {
    width: 176px;
    white-space: nowrap;
    font-weight: 400;
    padding-right: 15px;
    box-sizing: border-box;
  }

  & .FilterRow {
    align-items: center;
    border-top: 0;
    border-bottom: 1px solid border-color-light;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 20px 0;
  }
}
