@import '../_constants.css';
@import '../_breakpoints.css';

:root {
  --ease-timing: 60ms;
}

.YourTeamModalContainer {
  height: 100%;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  font-weight: 300;
  /* Above .AutoComplete in Header.css, HeaderChase.css, HeaderComehome.css */
  z-index: 102;
}

.BgScreen {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(0 0 0 / 70%);
}

.YourTeamModal {
  width: 376px;
  max-width: 376px;
  max-height: calc(100% - 60px);
  position: absolute;
  right: 82px;
  background: #fff;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-overflow-scrolling: touch;
  -webkit-box-sizing: border-box;
  -webkit-box-shadow: 0 0.9375rem 0.875rem -0.1875rem rgb(0 0 0 / 17%);
  /* stylelint-enable property-no-vendor-prefix */
  box-shadow: 0 0.9375rem 0.875rem -0.1875rem rgb(0 0 0 / 17%);
  box-sizing: border-box;
  z-index: 2;
  border-radius: 14px;
}

.YourTeamModalAdjusted {
  right: 122px;
}

.YourTeamModal .Body {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  max-height: 100vh;
}

.overlay {
  background: transparent;
}

.CloseButtonDesktop,
.CloseButtonDesktopWrapper {
  width: auto !important;
  line-height: 25px;
  color: #4a4a4a !important;
  position: fixed;
  bottom: 55px;
  right: 21px;
  font-weight: 500;
}

.CloseButtonDesktopAdjusted {
  right: 60px;
}

.CloseButtonDesktop {
  /* Override inline styles for PillButton */
  height: 50px !important;
  width: 50px !important;
  z-index: 100;
  padding: 0 15px !important;
  font-size: 12px;
  box-shadow: 0 3px 15px rgb(0 0 0 / 50%) !important;

  &:focus {
    outline-offset: -10px;
  }

  & .CloseIconDesktop {
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.MobileCloseButton {
  display: none;
}

.LOAndAgentCardContainer {
  padding: 20px 26px 29px;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  max-width: 350px;
  margin: auto;
}

@media (--x-small-screen) {
  .CloseButtonDesktopWrapper {
    display: none;
  }

  .YourTeamModal {
    right: 0;
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    max-height: 100vh;
  }

  .CloseButton {
    display: none;
  }

  .MobileCloseButton {
    composes: strip-button-styles from '../_common.css';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 7px;
    right: 13px;
    height: 30px;
    width: 30px;
    cursor: pointer;

    & > span {
      height: 24px;
    }
  }
}
