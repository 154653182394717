@import './_breakpoints.css';
@import './_constants.css';

.SavedSearchesPage {
  display: flex;
  flex-direction: column;
}

.SavedSearchesAccessiblePageHeading {
  composes: hide-off-screen from './_common.css';
}

.SavedSearches {
  margin: 0 auto 40px;
  max-width: 1190px;
  padding: 0 20px;
}

.SavedHomeHeadingIcon {
  & svg {
    height: 50px;
    width: 50px;
  }
}

.LoadingSection {
  display: block;
  flex: none;
  overflow: visible;
  /* To push footer below fold while items are loading */
  min-height: calc(100vh - 150px);
}

.DesktopAndTabletSeparator {
  display: block;
  border-bottom: 1px solid #f0f0f0;
  margin: 20px 0;
}

@media (--tablet-screen) {
  .SavedSearches {
    max-width: 726px;
  }
}

@media (--small-screen) {
  .SavedSearchesPage {
    display: block;
  }

  .SavedSearches {
    padding: 0 20px;
    /* Fixed size, centered cards */
    max-width: 375px;
  }

  .DesktopAndTabletSeparator {
    display: none;
  }
}

/* Fixes IE11 flexbox issue - where flex items inside
parent do not know the size of their parent */
/* stylelint-disable-next-line selector-no-vendor-prefix */
_:-ms-fullscreen,
:root .LoadingSection {
  min-height: 100vh;
}
