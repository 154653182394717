.LenderPropertyCTAChase {
  animation: fade-in 0.3s;
  margin-top: 5px;
}

.LenderPropertyCTAButton {
  margin-left: auto;
  margin-right: auto;
  min-width: 160px;
  font-weight: 400;
}

.OverBuyingPowerNotice {
  font-size: 13px;
  font-weight: 200;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
