/* TODO: Cleanup CSS in this file so that we can enable stylelint */
/* stylelint-disable no-descending-specificity */
:root {
  --mapMarkerOffMarketTextColor: #4a4a4a;
  --mapMarkerOffMarketVisitedTextColor: #999;
  --mapMarkerActiveListingTextColor: #fff;
}

.MapMarkerWrapper {
  z-index: 2;
  position: relative;

  & .MapMarker {
    display: inline-block;
    transform: translateY(0);
    animation: raise-return 0.3s forwards;

    &:hover {
      animation: raise 0.3s forwards;
    }
  }
}

/* Marker shadow */
.MapMarkerWrapper::after {
  content: '';
  position: absolute;
  bottom: -13px;
  left: 0;
  width: 100%;
  height: 20px;
  /* stylelint-disable color-function-notation */
  background: radial-gradient(
    ellipse 15px 8px,
    rgba(0, 0, 0, 10%),
    transparent
  );
  /* stylelint-enable color-function-notation */
  z-index: -1;
}

.MapMarkerWrapper--Pulsing {
  &::before {
    content: '';
    position: absolute;
    bottom: -11px;
    left: 8px;
    background-color: #6dacf7;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    transform: scale(1);
    opacity: 0.8;
    animation: circular-expand 1.8s ease-out forwards infinite,
      circular-expand-fade-out 1.8s ease-out forwards infinite;
  }
}

.MapMarkerWrapper--Visited {
  & .MapMarker .MapMarkerLabel {
    color: var(--mapMarkerOffMarketVisitedTextColor);
  }
}

.MapMarkerWrapper.MapMarkerWrapper--Focused {
  z-index: 5000 !important;

  & .MapMarker {
    animation: raise 0.3s forwards;

    /* Background transition */
    & .MapMarkerIcon::before {
      background: ch-blue;
      opacity: 1;
    }
  }
}

/* Selected check mark indicator */
.MapMarkerWrapper--Selected .MapMarker {
  position: relative;

  &::before {
    content: ' ';
    display: block;
    border: solid 8px text-color;
    border-radius: 8px;
    height: 0;
    width: 0;
    position: absolute;
    right: -5px;
    top: -6px;
    box-shadow: 0 0 0 2px #fff;
    z-index: 3;
  }

  &::after {
    content: ' ';
    display: block;
    width: 3px;
    height: 8px;
    border: solid #fff;
    border-width: 0 1.4px 1.4px 0;
    position: absolute;
    right: 1px;
    top: -3px;
    transform: rotate(45deg);
    z-index: 3;
  }
}

.MapMarkerWrapper--HomeMarker {
  & .MapMarkerIcon {
    background: linear-gradient(60deg, #00bead 10%, #387bea 90%);
    border: 2px solid #e9e9e9;
  }

  & .MapMarkerLabel {
    top: 2px;
  }

  & svg {
    width: 20px;
  }
}

.MapMarkerWrapper--ActiveListing {
  & .MapMarkerIcon {
    background: linear-gradient(45deg, #2cc6b4 10%, #6ed38a 60%);
    border-color: white;
  }

  & .MapMarkerLabel {
    color: var(--mapMarkerActiveListingTextColor);
  }

  &.MapMarkerWrapper--Visited {
    /* Background transition */
    & .MapMarkerIcon::before {
      background: #a1d6be;
      opacity: 1;
    }
  }
}

.MapMarkerWrapper--InactiveListing {
  & .MapMarkerLabel {
    right: 1px;
  }
}

.MapMarkerLabel {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 11px;
  color: var(--mapMarkerOffMarketTextColor);
  z-index: 2;
  transform: translate(1px, 0);
  transition: color 0.3s;

  & span {
    flex: 1;
  }
}

.MapMarkerIcon {
  box-sizing: border-box;
  position: relative;
  width: 36px;
  height: 36px;
  background: linear-gradient(350deg, #eee 0%, #fff 80%);
  border: 2px solid #e9e9e9;
  text-align: center;
  border-radius: 50% 50% 50% 0;
  transform: rotate(-45deg);
  margin-bottom: 0;
  z-index: 2;
  overflow: hidden;

  /* To support smooth transition from background gradient to background fill */
  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.3s;
    opacity: 0;
  }
}

.MarkerPopupAddress {
  letter-spacing: initial;
  text-transform: initial;
  text-align: center;
  max-width: 300px;
}

.MarkerPopupLabel {
  text-align: center;
  margin-top: 5px;
}

@keyframes raise {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-5px);
  }
}
@keyframes raise-return {
  from {
    transform: translateY(-5px);
  }

  to {
    transform: translateY(0);
  }
}
@keyframes circular-expand {
  from {
    transform: scale(1) rotateX(50deg);
  }

  to {
    transform: scale(4.5) rotateX(50deg);
  }
}
@keyframes circular-expand-fade-out {
  0% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}
/* stylelint-enable no-descending-specificity */
