@import './_constants.css';
@import './_breakpoints.css';
@value box-shadow-long from './_fontsAnimations.css';

.PropertyIntro {
  position: relative;
  margin-bottom: 12px;
  line-height: 16px;
}

.Address {
  font-size: 16px;
  font-weight: 900;
  margin: 20px 0 0;
}

.DesktopPropertyInfo {
  position: relative;
  width: 100%;
  min-width: 320px;
  margin: 0 auto;
  box-sizing: border-box;
}

.PropertyInfo {
  font-size: 12px;
  font-weight: 300;
}

.InfoToggle {
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;
}

.InfoText {
  margin-right: 2px;
  user-select: none;
  font-weight: 100;
}

.ChevronIcon {
  position: relative;
  top: 5px;
}

.ChevronIcon-svg {
  transform: rotate(270deg);
  height: 18px;
  transition: transform 100ms;
}

.active .ChevronIcon-svg {
  transform: rotate(90deg);
  transition: transform 100ms;
}

.PropertyInfoTable {
  will-change: contents, opacity, height;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 15px;
}

.InfoContent {
  composes: details-container from './_common.css';
}

.propDetailsType,
.propDetailsData {
  font-size: 12px;
}

.MortgageOffers {
  margin-top: 10px;
  padding: 0 1.25rem;
}

/* Allows background to extend beyond the column in desktop view: */
.FullWidthBackground {
  height: 100%;
  width: 100vw;
  position: absolute;
  right: calc(-1 * ((100vw - (100% + var(--pdpAvmColWidth))) / 2));
  top: 0;
  background: #f7f7f7;
}

.WatchListButtonPositioner {
  position: absolute;
  top: -57px;
  right: 15px;
  z-index: 2;
}

.SmallPropertyInfo {
  padding: 10px;

  & .PropertyCardAddress,
  & .PropertyCardAddress h2 {
    font-size: 12px;
    font-weight: 200;
    margin-bottom: 5px;
    line-height: 1.1rem;
  }

  & .PropertyCardAddress h2 { margin: 0; }

  & .PropertyInfoToggle {
    font-size: 13px;
    margin-top: 3px;
    height: 22px;
    overflow: hidden;
    animation: property-info-toggle 0.2s;

    & .PropertyInfoToggleChevron svg {
      transform: rotate(180deg) translate(-5px, -5px);
    }

    & .PropertyInfoToggleLink {
      cursor: pointer;
    }
  }
}

@media (--small-screen) {
  .Address {
    font-size: 12px;
    font-weight: 100;
    line-height: 16px;
  }
}

@keyframes property-info-toggle {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: 22px;
    opacity: 1;
  }
}
