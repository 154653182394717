.FlatButton {
  border: transparent;
  cursor: pointer;
  background-color: transparent;
  display: flex;

  & .FlatButtonLabel {
    font-weight: 900;
    font-size: 14px;
  }
}
