.LinkButton {
  display: inline-block;
  padding: 13px 18px;
  border: 0;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}
