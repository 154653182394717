.LearnMoreAside {
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  cursor: default;
}

.LearnMoreLink {
  composes: strip-button-styles from './_common.css';
  color: inherit;
  text-decoration: underline;
  font-weight: 500;
}
