@import './_breakpoints.css';

.BrokerageAttribution {
  font-family: "Avenir W01", Avenir, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.BrokerageAttribution .Link {
  font-size: 11px;
}

.ComeHomeLogo {
  width: 170px;
  height: auto;
}

.LogoSection {
  padding: 0 9px 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BrokerageSection {
  border-left: 1px solid rgb(123 123 123 / 55%);
  padding: 4px 0 4px 10px;
}

.VerticalAlignment {
  &.BrokerageAttribution {
    flex-direction: column;
    justify-content: center;
  }

  & .LogoSection,
  & .BrokerageSection {
    padding: 0;
  }

  & .BrokerageSection {
    border-left: 0;
    margin-top: 3px;
  }
}

.MinimalStyling {
  &.BrokerageAttribution {
    align-items: center;
    padding-right: 16px;
  }

  & .BrokerageSection,
  &.BrokerageAttribution {
    line-height: 14px;
  }

  & .BrokerageSection {
    text-align: right;
    padding: 0;
    border-left: 0;
  }
}

@media (--tablet-screen) {
  .MinimalStyling {
    & .BrokerageSection {
      max-width: 200px;
    }
  }
}
