@import './_breakpoints.css';
@import './_constants.css';
@value border-color-light from './_colors.css';

.MapPropertyPage {
  composes: row from './_common.css';
  margin-top: 20px;

  & .MapMarker {
    animation: none;

    &:hover {
      animation: none;
    }
  }

  & .LotSizeMap :global .mapboxgl-ctrl-top-right {
    top: 0;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  & :global .mapboxgl-ctrl-top-right {
    /* placing zoom controls under popup */
    z-index: 1;
  }

  & .LayerItemsControlRow.DataAttribution {
    margin-top: 25px;
  }
}

.MapNullState {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 158px;
  border: 1px solid #f7f7f7;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 -1px 4px 0 rgb(0 0 0 / 7%), 0 15px 14px -3px rgb(0 0 0 / 17%);
  padding: 0 15px;

  & svg {
    margin-right: 10px;
  }
}

.MapNullStateIcon {
  width: 31px;
  height: 31px;
  padding-right: 15px;
}

.MapSection {
  position: relative;
  width: 100%;
  height: 630px;
  margin-bottom: 40px;
  /* Required since the .MapContainer can't have overflow due to controls placed outside of it */
  overflow: hidden;

  /* Need to override due to the attribution pushing the controls higher */
  & .MapNotification.MapNotificationPositionedHigher {
    bottom: 190px;
  }
}

.MapViewTabs {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.MapViewTabList {
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
}

.MapViewTab {
  border-right: 1px solid #ccc;
  border-top: none;
  border-bottom: none;
  border-left: none;
  display: flex;
  cursor: pointer;
  font-weight: 300;
  font-size: 12px;
  background: #fff;
  height: 25px;
  justify-content: center;
  margin-left: 10px;
  padding: 0 29px;
  flex-direction: column;
}

.MapViewTabSelectedIndicator {
  height: 0.0625rem;
  margin-top: 6px;
  width: 100%;
  transition: background 0.2s;
}

.MapViewTab:first-child {
  padding-left: 12px;
}

.MapViewTab:last-child {
  border: none;
}

.MapViewTabPanels {
  width: 100%;
  margin-top: 24px;
}

.MapViewTabPanel {
  width: 100%;
  outline: 0;
}

.option-divider-wrapper {
  composes: option-divider-wrapper from './HorizontalSelectorTabs.css';
}

.MapOptionDivider {
  display: block;
  height: 15px;
  width: 1px;
  border-right: 1px solid #ccc;
}

.MapViewSelector {
  overflow: hidden;
}

/* Styles for San Antonio AB Test */
.StreetAddress {
  font-weight: 900;
  font-size: 20px;
  text-align: center;
  margin: 4px 0 18px;
}

.SectionDivider {
  display: none;
  margin: 40px 0;
  padding: 0 10px;
  border-bottom: 1px solid border-color-light;
  border-top: none;
  border-right: none;
  border-left: none;
  width: 100%;
  box-sizing: border-box;
}

.LotPopupContent {
  border-radius: 4px;
  padding: 10px 15px;
  text-align: center;
  position: relative;
  border: 1px solid white;
  box-sizing: border-box;
  color: white;
  font-size: 16px;
}

.LotPopupContentTip {
  position: absolute;
  width: 15px;
  height: 15px;
  bottom: -8px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  left: 50%;
  transform: translate(-50%, 0) rotate(45deg);
  box-sizing: border-box;
}

@media (--desktop-screen) {
  .MapSection {
    max-height: calc(100vh - var(--header-height));
    min-height: 400px;
  }
}

/* Special width value needed here since we want to switch the UI when the floating map control comes
 * close to touching the sides of the map container */
@media (max-width: 900px) {
  .MapSection {
    & :global .mapboxgl-ctrl-top-right {
      top: 65px;
    }

    /* Need to override due to the attribution pushing the controls higher */
    & .MapNotification.MapNotificationPositionedHigher {
      bottom: 180px;
    }

    & .LayerGroupsControlInner {
      width: 100%;
      justify-content: space-around;

      & .LayerGroupsControlButton {
        padding: 0;
        flex: 1;
      }
    }

    & .LayerItemsControl {
      bottom: -10px;

      & .LayerItemsControlInner {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 10px;
        border-radius: 0;
      }

      & .LayerItemsControlMainColumn {
        margin: auto;
      }
    }

    & .LegendWrapper {
      width: 215px;
    }
  }
}

@media (--tablet-screen-and-smaller) {
  .SectionDivider.SectionDividerWithReducedMargin {
    margin-bottom: 0;
  }
}

@media (--small-screen) {
  .MapViewTabPanels {
    box-sizing: border-box;
    padding: 0 15px;
    margin: 24px auto 0;
  }

  .MapSection {
    & .LayerItemsControl .LayerItemsControlInner {
      /* Padding to make sure data attribution is not cut off*/
      padding-bottom: 10px;
    }
  }
}
