.MapNotificationInner {
  background-color: white;
  border-radius: 4px;
  box-shadow: box-shadow-soft;
  padding: 15px 20px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  position: relative;
  z-index: 2;

  & svg {
    position: absolute;
    left: calc(50% - 18px);
    top: -18px;
    width: 36px;
    height: 36px;
  }
}
