@value text-color from './_colors.css';

.HorizontalToggle {
  height: 26px;

  &.OffState {
    background-color: #cdcdcd !important;
  }

  & .SelectedMask {
    width: 20px;
    height: 22px;
    top: 2px;
    background-color: white;
  }
}
