@value border-color-light from './_colors.css';

.NumberAdjuster {
  display: flex;
  margin: 10px 0;

  & button {
    /* Matching Chrome's default button text color to fix color inconsistency between browsers */
    /* TODO should this be the cobrand's "link_on_white" color? */
    color: #000;
  }
}

.Button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #999;
  font-weight: 100;
  cursor: pointer;
  transition: transform 0.1s;
  background: white;

  &:hover {
    transform: scale(1.05);
  }

  &:disabled {
    color: inherit;
    cursor: not-allowed;
  }

  &.ButtonDisabled {
    border-color: border-color-light;
    background-color: white;
  }
}

.NumberAdjusterControls {
  display: flex;
}

.NumberAdjusterLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 37px;
  color: inherit;
}

.ValueLabelAriaText {
  composes: hide-off-screen from './_common.css';
}

.ValueLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  margin: 0 10px;
  width: 50px;
}

.OffScreenLabel {
  composes: hide-off-screen from './_common.css';
}
