@import './_constants.css';

@value box-shadow from './_fontsAnimations.css';

.MapAvmDeepDiveLocation {
  padding: 15px;

  & .MapMarker {
    animation: none;

    &:hover {
      animation: none;
    }
  }
}

.MapMarkerPopup {
  box-shadow: box-shadow;
  background: white;
  padding: 10px;
  width: 370px;
}

.DeepDiveComponentSummary {
  color: #20203f;
  font-size: 14px;
  margin: 10px;
  padding: 10px 0;
}

.LayerItemsControlRow {
  display: flex;
  margin: 15px auto;
}

.LayerItemsControlLabel {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Map Legend */
.LegendWrapper {
  width: 400px;
  min-height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.MapSection {
  position: relative;
  width: 100%;
  height: 374px;
  /* Required since the .MapContainer can't have overflow due to controls placed inside it */
  overflow: hidden;
}

.Trigger {
  cursor: pointer;
  margin-left: 10px;

  & svg {
    width: 13px !important;
    height: 13px !important;
  }
}

.TooltipText {
  margin-bottom: 10px;
}

.TooltipIntervalExplanation {
  margin-left: 5px;
}

.TooltipDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & .TooltipLabel {
    width: 90%;
  }
}

.TooltipColorCodes {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin-right: 10px;
}

.TooltipColorCodesHalftone {
  height: 16px !important;
  width: 16px !important;
  min-width: 16px;
  min-height: 16px;
  border-radius: 0 !important;
}

.LayerItemsControlMarkerToggleColumn {
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px 0 18px;
  margin-top: -5px;

  & .MonochromeLabelWrapper {
    white-space: nowrap;
  }

  & label {
    display: inline-block;
    font-size: 10px;
    margin: 0 auto 7px;
    text-align: center;
    line-height: 12px;
  }

  & .Tooltip {
    display: inline-block;
    white-space: normal;
  }

  & .Tooltip .Trigger {
    margin-left: 5px;
    transform: translateY(3px);
  }

  & .HorizontalToggleWrapper {
    align-self: center;
  }
}

.NoDataLegend {
  margin-top: 10px;
  display: flex;
  font-weight: 300;
  font-size: 12px;
  white-space: nowrap;
  transform: none;
  align-items: flex-end;
}

.GrayColorTile {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #eee;
  margin-left: 6px;
}

.LegendList {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
