.AvmLabel {
  font-size: 12px;
  font-weight: 900;
  text-align: center;
}

.AvmBreakdownAvmValue {
  font-weight: 900;
  font-size: 30px;
}

.AvmSubtext {
  font-size: 10px;
  font-weight: 900;
}
