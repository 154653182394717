@import './_breakpoints.css';

.HeaderMobileBottomSection {
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2px;
}

.BackButtonWrapper {
  margin-left: 16px;
}

.HeaderMobileBottomSection .ComeHomeLogoSection {
  height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & .ClickableLogo {
    top: 0;
  }

  & .ClickableLogo,
  & .ClickableLogoByHouseCanary {
    width: 130px;
  }
}

.HeaderMobileBottomSection .StackedComeHomeLogoSection {
  width: 35%;
  justify-content: flex-start;

  & .ClickableLogoWrapper {
    text-align: left;
  }

  & .BrokerageAttributionComeHomeStackedLogo {
    height: 30px;
  }
}

@media (--high-zoom-screen) {
  .HeaderMobileBottomSection .ComeHomeLogoSection {
    height: 30px;
  }
}
