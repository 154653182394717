@value box-shadow-soft, square-border from './_fontsAnimations.css';

.SchoolMapMarkerPopup {
  box-shadow: box-shadow-soft;
  background: white;
  cursor: pointer;
  border-radius: square-border;
  display: flex;
  width: 230px;
  padding: 9px 15px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  transform: translateY(15px);
  animation: fade-in-up 0.4s ease 1 forwards;
}

.SchoolName {
  font-size: 14px;
  font-weight: 900;
  padding-right: 15px;
}

.RankingCol {
  text-align: center;
}

.topLine,
.midLine,
.bottomLine {
  margin: 0;
  line-height: 1.125em;
}

.topLine {
  font-size: 12px;
  font-weight: 300;
}

.midLine {
  font-size: 20px;
  font-weight: 900;
}

.bottomLine {
  font-size: 10px;
  font-weight: 900;
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(15px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
