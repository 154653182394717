@import './_breakpoints.css';

.MapNotificationInner {
  background-color: white;
  border-radius: 11px;
  box-shadow: 0 0 26px 0 rgb(0 0 0 / 30%);
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  position: relative;
  z-index: 2;
  width: 290px;
  max-width: 75vw;

  & .InputElement {
    border-radius: 20px;
    transition: border-radius 200ms ease-out;
    font-size: 16px;
    font-family: inherit;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    height: 42px;
    box-sizing: border-box;

    &::placeholder {
      color: inherit;
      font-weight: 200;
      font-size: 12px;
      text-align: left;
    }
  }
}

.TopRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal-container .Modal {
  height: 126px;
  background-color: #fff;
  justify-content: start;
  bottom: 0;
  position: absolute;
}

.ToastMessage {
  color: #626262;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  width: auto;
  height: auto;
}

.ArrowIcon,
.ArrowIcon-svg {
  height: 15px;
  width: auto;
}

.ArrowIcon {
  display: flex;
  width: 10px;
  height: 10px;
  justify-content: center;
  align-items: center;
}

.ArrowIcon-svg {
  color: #fff;
  transform: rotate(180deg);
  position: static;
}

.RegisterArrowIcon {
  display: block;
  height: 10px;
  width: 10px;
}

.RegisterArrowIcon-svg {
  color: black;
  transform: rotate(180deg);
  cursor: pointer;
}

.circle {
  height: 31px;
  width: 31px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 7px 1px rgb(0 0 0 / 13%);
  background-color: #4a4a4a;
}

.SearchBar {
  position: relative;
  margin-top: 10px;

  & .circle {
    position: absolute;
    right: 7px;
    top: 6px;
    z-index: 10;
    padding: 0;
    border: 0;
    cursor: pointer;
    text-align: left;
  }
}

.LimitedDataText {
  white-space: nowrap;
  display: block;
}

.Image {
  padding-right: 6px;
}

.Header {
  display: flex;
  padding-top: 2px;
}

.ArrowContainer {
  display: flex;
  align-items: center;
  padding-left: 4px;
  border: none;
  background: none;
}

/* We want a line-break between the lines normally, but the text to flow together in a paragraph on
 * very small screens */
@media (max-width: 380px) {
  .LimitedDataText {
    white-space: initial;
    display: inline;
  }
}

@media (--small-screen) {
  .Image {
    display: none;
  }

  .Header {
    margin: auto;
    padding-left: 4px;
  }
}

@media (--small-screen-with-keyboard-open) {
  .MapNotificationInner {
    display: none;
  }
}
