.CTAButtonPDP {
  width: 90%;
  height: 44px;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .CTAButton {
    font-size: 14px;
    font-weight: 900;
    height: 44px;
    min-height: 44px;
  }
}
