@import './_breakpoints.css';

.StreetViewError {
  position: relative;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.StreetViewErrorImg,
.StreetViewErrorImg-svg {
  height: 630px;
  width: auto;
}

.OverlayText {
  position: absolute;
  font-size: 24px;
  line-height: 33px;
  font-weight: 300;
  align-self: flex-start;
  margin-top: 48px;
  text-align: center;
}

@media (--tablet-screen-and-smaller) {
  .StreetViewErrorImg,
  .StreetViewErrorImg-svg {
    height: 460px;
    width: auto;
  }

  .OverlayText {
    font-size: 16px;
    margin-top: 32px;
    max-width: 230px;
    line-height: 25px;
  }
}
