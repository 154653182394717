@import './_breakpoints.css';

.HcAvmExplanation {
  font-size: 9px;
  font-weight: 300;
  position: absolute;
  bottom: 15px;
  margin-bottom: 15px;

  &.NoAvmFactors {
    position: static;
    bottom: 0;
    margin-bottom: 0;
    margin-top: 10px;
    padding: 0;
  }

  & .LearnMoreAside {
    cursor: pointer;
    display: inline-block;
    font-size: 9px;
    font-weight: 300;
    margin-left: 5px;
    text-decoration: underline;
  }
}

@media (--tablet-screen-and-smaller) {
  .HcAvmExplanation {
    position: static;
    margin: 5px 0;
  }
}
