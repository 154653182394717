@import './_breakpoints.css';
@import './_constants.css';
@value border-color from './_colors.css';
@value box-shadow-soft from './_fontsAnimations.css';
@value border-color-lightest from './_colors.css';
@value border-color-light from './_colors.css';

:root {
  --lendco-desktop-header-top-row-height: 68px;
  --lendco-mobile-header-height: 88px;
  --lendco-desktop-header-bottom-row-height: 54px;
}

.DesktopContainer {
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .DesktopHeaderTopRow {
    width: 100%;
    height: var(--lendco-desktop-header-top-row-height);
  }

  & .DesktopHeaderBottomRow {
    width: 100%;
    height: var(--lendco-desktop-header-bottom-row-height);
    background-color: #f4f4f4;
  }

  & .BackToSiteContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    & .DesktopChevronIcon {
      position: relative;
      top: 3px;
      right: 8px;

      & .DesktopChevronIcon-svg {
        height: 13px !important;
        width: 15px !important;
      }
    }

    & .VerticalSeparator {
      height: 44px;
      border-right: 1px solid #c0c0c0;
    }

    & .BackToSiteText {
      color: inherit;
      font-size: 12px;
      font-weight: 900;
      letter-spacing: 0.2px;
      line-height: 16px;
      margin-left: 5px;
    }
  }
}

.BackToSiteLink {
  composes: strip-button-styles from './_common.css';
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: inherit;
}

.DesktopHeaderTopRowLeftContainer,
.DesktopHeaderBottomRowLeftContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.DesktopHeaderTopRowRightContainer,
.DesktopHeaderBottomRowRightContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.DesktopHeaderTopRowInnerContainer,
.DesktopHeaderBottomRowInnerContainer {
  max-width: var(--header-max-width);
  width: 100%;
  padding: 0 20px;
  margin: auto;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;

  &.DesktopInnerContainerFullWidth {
    padding: 0 40px 0 30px;
    max-width: initial;
  }
}

.removeRightPadding {
  padding-right: 0 !important;
}

.DesktopHeaderTopRowLeftContainer {
  height: 100%;

  & .LendcoLogo {
    cursor: pointer;
    margin-right: 20px;
    background: none;
    border: none;
    padding: 0;

    & svg {
      display: block;
      height: 50px;
      max-width: 140px;
    }

    & img {
      display: block;
      height: 50px;
      max-width: 140px;
    }
  }

  & .DesktopTopRowLeftElement {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    color: #545454;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.2px;
    opacity: 0.8;
    padding: 0 10px;
    margin-right: 15px;
  }
}

.DesktopAuthButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DesktopHeaderTopRowRightContainer {
  margin-right: 48px;

  & .DesktopAuthButtonContainer {
    margin-right: 25px;
    margin-top: 4px;

    & .FlatButton {
      min-width: 70px;
    }

    & .FlatButtonLabel {
      font-size: 14px;
      font-weight: 900;
    }
  }

  & .ApplyNowButton {
    font-size: 14px;
    margin-bottom: 0;
    margin-right: 16px;
    width: 120px;
    white-space: nowrap;
  }
}

.DesktopHeaderBottomRowLeftContainer {
  color: #545454;
  font-size: 14px;
  font-weight: 900;

  & .MenuNotificationCount {
    position: absolute;
    top: -5px;
    right: -18px;
  }
}

.DesktopHeaderTopRowRightContainer.removeRightMargin,
.DesktopHeaderTopRowRightContainer.AuthButtonWrapper {
  margin-right: 0;
}

.DesktopHeaderBottomRowRightContainer {
  & .ClickableLogo,
  & .ClickableLogoByHouseCanary {
    cursor: pointer;
    width: 150px;
    margin-bottom: 6px;
  }

  & .ClickableLogo.LargeLogo.ClickableLogoByHouseCanary {
    width: 180px;
  }

  & .DesktopBottomRowIconElement {
    padding: 5px;
    margin-right: 5px;
    cursor: pointer;
    display: flex;
    background: none;
    border: none;
  }

  & .InputElement {
    margin: 0;
  }

  & .SearchIcon {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;

    svg {
      margin: auto;
      height: 20px;
      width: 20px;
    }

    & :global .element-to-stroke {
      stroke: #20203f;
    }

    & :global .element-to-fill {
      fill: #20203f;
    }
  }

  & .AutoComplete {
    width: 350px;
    margin-right: 10px;

    & .InputElement,
    & .Results {
      border-color: border-color;
    }

    & .Results {
      border-top: 1px solid border-color-light;
    }

    & .SearchIcon {
      margin-bottom: 0;

      & :global .element-to-stroke {
        stroke: #fff;
      }

      & :global .element-to-fill {
        fill: #fff;
      }
    }
  }
}

.DesktopHeaderBottomRowRightContainerWithStackedLogo {
  & .ClickableLogoWrapper {
    & img {
      height: 35px;
    }
  }
}

.DesktopComehomeLogoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  & .ClickableLogo {
    top: -1px;
    height: 25px;
  }

  & .ClickableLogoByHouseCanary {
    top: 2px;
    height: 35px;
  }
}

.MobileContainer {
  display: none;
  width: 100%;
  margin: auto;
  overflow-x: visible;
}

.MobileComehomeLogoContainer {
  display: flex;
  align-items: center;
  height: 100%;

  & .ClickableLogo {
    margin-left: 0;
    width: 100%;
  }
}

.DesktopMenuScreen {
  position: absolute;
  top: calc(
    var(--lendco-desktop-header-top-row-height) +
      var(--lendco-desktop-header-bottom-row-height)
  );
  left: 0;
  width: 100vw;
  z-index: 1;
  height: calc(
    100vh -
      calc(
        var(--lendco-desktop-header-top-row-height) +
          var(--lendco-desktop-header-bottom-row-height)
      )
  );
}

.PartialNavItemLink {
  display: flex;
  flex-direction: row;
  color: inherit;
  margin-right: 30px;
}

.PartialNavItemLink:last-child {
  margin-right: 0;
}

.PartialNavItemText {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}

/* stylelint-disable-next-line selector-no-vendor-prefix */
_:-ms-fullscreen,
:root .Header ul.Results {
  top: 3.4rem;
}

.MobileMenuContainerFullHeight {
  height: 100vh;
}

@media (--tablet-screen) {
  .MobileSearchItemAnimationHiddenOnTablet.MobileSearchItemAnimation {
    /* Override Motion's inline display: block */
    display: none !important;
  }

  .Suggestion {
    display: flex;
    flex-direction: column;

    & .SuggestionText {
      display: block;
    }

    & .SuggestionMultiUnit {
      text-align: left;
      margin-left: 0;
    }
  }
}

@media (--tablet-screen-and-smaller) {
  .DesktopContainer {
    display: none;
  }

  .MobileSearchHomesBackArrow {
    top: 1px;
  }

  .MobileContainer {
    display: block;
  }

  .MobileTopSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 0 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #f3f3f3;

    & .ClickableLogo svg,
    & .ClickableLogo {
      height: 28px;
      width: 114px;
    }

    & .SearchIcon.hidden {
      opacity: 0;
    }

    & .SearchIcon svg {
      height: 20px;

      & :global .element-to-stroke {
        stroke: #fff;
      }

      & :global .element-to-fill {
        fill: #fff;
      }
    }
  }

  .MenuIcon {
    z-index: 1;

    & .MenuIconLine,
    & .MenuIconLineChild {
      background-color: #000;
    }
  }

  .MobileMenuContainer {
    & .MobileAccountMenuContainer {
      height: 100vh;
      z-index: 99;
      background: white;
      overflow-y: auto;
      overscroll-behavior-y: contain;
      position: absolute;
      top: 0;
    }

    & .MobileAccountMenuContainer > * {
      pointer-events: auto;
    }

    & .MobileSearchItemAnimation {
      background: #f4f4f4;
      width: 100%;
      box-sizing: border-box;
      position: relative;
    }

    & .MobileAccountMenuAnimation {
      position: absolute;
      /* `top` set via JS */
      background: #fff;
      width: 100%;
      box-sizing: border-box;
      z-index: 103;
    }

    & .MobileAccountMenuBackdrop {
      z-index: 102; /* Sit behind MobileAccountMenuAnimation */
      position: absolute;
      /* `top` set via JS */
      right: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgb(0 0 0 / 20%);
      animation: fade-in 0.3s;
    }

    & .SearchItem {
      height: 60px;
    }

    & .AutoComplete {
      max-width: 100% !important;
      transform: translateY(-5px);
      /* Stack above the .LayerItemsControl (SRP map controls) and the mobile bottom nav.
       * This is necessary due to the `transform` above pulling the child .Results
       * element out of the stacking context (nullifying the z-index of .Results) */
      z-index: 102;

      & .InputElement {
        width: calc(100% - 20px);
        margin: 0 auto;
        border: 1px solid #e5e5e5;
        border-radius: 6px;
        padding: 15px 12px 17px;
        background-color: white;
      }

      & .InputElement::placeholder {
        font-size: 14px !important;
        color: inherit;
      }

      & .SearchButton {
        width: 50px;
        top: 18px;
        right: 10px;
        border-radius: 0 6px 6px 0;
      }

      &.AutoCompleteWithDropdown .InputElement {
        border-bottom: 1px solid rgb(0 0 0 / 12%);
      }

      &.AutoCompleteWithDropdown .SearchButton {
        border-radius: 0 6px 6px 0;
      }

      & .InputWrapper {
        padding-top: 18px;
      }

      & .ResetButton {
        top: 18px;
        right: 60px;
      }

      & .Results {
        top: 58px;
        border-radius: 0;
        border: none;
        background: #f4f4f4;
      }

      & .HasVisibleLabel {
        background-color: #f4f4f4;
        padding: 0 2px;
        border-radius: 4px;
        font-size: 11px;
        left: 21px;
        top: 11px;
      }
    }
  }

  .MobileMenuList {
    padding: 0;
    margin: 0;
    list-style-type: none;

    &.hidden {
      opacity: 0;
    }

    & .MobileMenuListItemButton {
      font-size: 14px;
      font-weight: 900;
      line-height: 1.5;
      padding: 20px;
      width: 270px;
      box-sizing: content-box;
      cursor: pointer;
      -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
      background: none;
      border: none;
      text-align: left;
      color: #2a2a2a;

      & .BackToSiteWrapper {
        position: relative;
      }

      & .ChevronIcon {
        position: relative;
        top: 4px;
        margin-right: 5px;

        & .ChevronIcon-svg {
          height: 17px !important;
          width: 20px !important;
        }
      }
    }

    & .MobileMenuListItemSelected {
      padding: 20px;
      font-size: 14px;
      font-weight: 900;
      line-height: 1.5;
      cursor: pointer;
    }

    & .MobileMenuListItem + .MobileMenuListItem,
    & .LenderCTAButtonPDPWrapper {
      border-top: 1px solid rgb(0 0 0 / 12%);
    }

    & .MobileMenuListItem:last-of-type {
      border-bottom: 1px solid rgb(0 0 0 / 12%);
      padding-bottom: 30px;
    }

    & .MobileMenuListItem:last-child {
      margin-bottom: 20px;
    }
  }

  .MobileMenuScreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1;
    height: 100vh;
  }

  .MobileSearchIconContainer {
    display: flex;
    align-items: center;
    animation: fade-in 0.2s;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    width: 23px;
  }

  .MobileSearchIconContainer .SearchIcon {
    position: relative;
    opacity: 1;
    transition: opacity 30ms;
    cursor: pointer;
  }

  .MobileSearchIconContainer.MobileSearchIconContainerActive .SearchIcon {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }
}

@media (--xx-small-screen) {
  .MobileMenuList {
    padding: 0;

    & .MobileMenuListItem:last-child {
      /* margin bottom is set to allow small screens, like iphone se, to scroll down to the last menu */
      margin-bottom: 100px;
    }
  }
}

.HeaderLogo {
  margin: 0;
  position: relative;
  top: 0.125rem;
  cursor: pointer;
  height: 1.75rem;

  /* stylelint-disable no-descending-specificity */
  & img {
    height: 100%;
  }
  /* stylelint-enable no-descending-specificity */
}

.LargerMobileHeaderLogo {
  top: 0;
  height: 2.4rem;
}

@media (--high-zoom-screen) {
  .MobileTopSection {
    height: 35px;
  }

  .HeaderLogo {
    top: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
