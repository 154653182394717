.ThumbnailCarousel {
  height: 100%;
  width: 100%;
  position: relative;
  margin: auto;
  user-select: none;
}

.CurrentImage {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  /* stylelint-disable  property-no-vendor-prefix */
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  /* stylelint-enable  property-no-vendor-prefix */
}

.Arrow {
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  width: 50px;
  height: 50px;
  top: calc(50% - 25px);
  left: 0;
  z-index: 2;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.Arrow-right {
    right: 0;
    left: initial;
    transform: rotate(180deg);
  }
}

.Loading {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Controls > * {
  width: 25px;
  height: 50px;
  top: calc(50% - 17px);
  animation: fade-in 0.3s;
  opacity: 1;

  &:first-child {
    left: 10px;
  }

  &:nth-child(2) {
    right: 10px;
  }
}

.Controls svg {
  transform: scale(1.8);

  & :global .element-to-fill {
    fill: white;
  }
}

.Overlay {
  height: 32px;
  width: 64px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom left;
  position: absolute;
  left: 6px;
  bottom: 6px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
