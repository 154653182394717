.EmailOptInSignUpCta {
  display: flex;
}

.TextCol {
  font-size: 12px;
  line-height: 16px;
  max-width: 188px;
  text-align: right;
  font-weight: 300;
}

.ImageCol {
  height: 100%;
  padding-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
}

.BellIcon,
.BellIcon-svg {
  height: 24px;
  width: 24px;
}

.EmailOptInSignUpCta .TextButton {
  font-size: 12px;
  text-decoration: none;
  font-weight: 300;
}

.EmailOptInSignUpCta.isInMobileView {
  flex-direction: row-reverse;
  justify-content: flex-end;
  border-top: 1px solid rgb(0 0 0 / 12%);
  padding-top: 20px;
  padding-left: 19px;

  & .TextCol {
    font-size: 14px;
    line-height: 19px;
    max-width: 218px;
    text-align: left;
    margin-left: 8px;
  }

  & .TextButton {
    font-size: 14px;
  }

  & .ImageCol {
    padding-top: 7px;
    margin-left: 0;
  }
}
