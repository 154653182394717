@import './_breakpoints.css';
@import './_constants.css';

@value border-color-light from './_colors.css';

:root {
  --filters-border: 1px solid border-color-light;
}

.SearchPage {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  position: relative;
  /* Ad block banner styling specific to search results page only. */
  & .AdBlockMessageContainer {
    margin-bottom: 12px;
  }
}

/* These styles are specific to search results page */
.PageBody {
  display: flex;
  flex-direction: column;
  overflow: visible;
  height: 100%;
}

/* NOTE: Filters CSS from here on */

.PageBody.twoCol {
  /* Height in vh for IE 11 */
  height: 100vh;
  min-width: 334px;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 32px;
}

.TwoColHeader {
  margin: 0 20px;
  padding: 18px 0;
  box-sizing: border-box;
  border-bottom: var(--filters-border);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.TitleAndSavedSearch {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.FiltersTitle {
  position: relative;
  font-size: 36px;
  font-weight: 900;
  margin: 0;
  cursor: pointer;
}

.ArrowIcon {
  position: absolute;
  left: -36px;
  top: -4px;
  /* This a fix for IE 11 */
  right: 95px;
}

.ArrowIcon-svg {
  height: 18px;
  width: auto;
  transition: transform 200ms;
}

.FiltersTitle:hover .ArrowIcon-svg {
  transform: translateX(-6px);
  transition: transform 200ms;
}

.SavedSearchFilterDropdown {
  background: #fff !important;
  max-width: 294px;
  width: 100%;
}

.SavedSearchFilterDropdown input {
  max-width: 294px;
  width: 100%;
  margin: 0;
}

.DropdownInput::after {
  top: 28%;
}

.FilterRow {
  padding: 0 !important;
  height: auto !important;
  position: relative;
  bottom: 5px;
}

.PropertyCount {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  width: 176px;
}

.TitleAndSavedSearch,
.SpacerDiv,
.PropertyCount {
  box-sizing: border-box;
}

.TitleAndSavedSearch,
.SpacerDiv {
  width: calc(50% - 88px);
  max-width: 456px;
}

@media screen and (max-width: 1100px) {
  .SpacerDiv {
    display: none;
  }

  .TitleAndSavedSearch {
    width: calc(100% - 176px);
  }

  .PropertyCount {
    text-align: right;
  }
}

@media (--small-screen) {
  .SearchPage .PageBody {
    display: block;
  }
}

@media screen and (max-width: 722px) {
  .SavedSearchFilterDropdown {
    padding: 20px 20px 0 0;
  }

  .TitleAndSavedSearch {
    display: block;
  }
}
