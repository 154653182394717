.BuyingPowerPill {
  border-radius: 4px;
  color: #fff;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  box-sizing: border-box;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  padding: 8px 28px;
  margin: 10px 0;
}

.Subtext {
  font-weight: 200;
  font-size: 10px;
}
