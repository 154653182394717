@value ch-blue from './_colors.css';

.LegalDoc {
  min-height: 100vw;
  min-width: 300px;
  padding: 10px 16px 62px;
  max-width: 900px;
  margin: 40px auto;

  & a {
    color: ch-blue;
    font-weight: 600;
    text-decoration: none;
  }

  & ol {
    counter-reset: section;
    list-style-type: none;
  }

  & ol h3 {
    display: inline;
    text-decoration: underline;
    font-size: 1em;
  }

  & li > p {
    margin-left: 5px;
    display: inline;
  }

  & li.Letters {
    list-style-type: lower-latin;
  }

  & li.Letters::before {
    content: '';
  }

  & li.CounterHidden::before {
    content: '';
  }

  & hr {
    margin-bottom: 2em;
  }

  & h4 {
    font-size: 18px;
    font-weight: 300;
  }

  & h5 {
    text-align: center;
    font-size: 1em;
  }

  & table {
    border-collapse: collapse;
  }

  & td,
  & tr,
  & th {
    border: 1px solid #d7dee0;
    padding: 3px 6px;
    vertical-align: middle;
    border-collapse: collapse;
  }

  & :global(.ccpa-infographic) {
    width: 1000px;
    max-width: 100%;
  }
}

.Title {
  font-size: 24px;
  text-align: center;
}

.Date {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 2em;
}

.Content {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.625em;
}

.Content li {
  list-style-type: none;
  margin-bottom: 1em;
}

/**
 * this fixes styling issue with unordered list
 * on Privacy Policy Page.
 */
.Content ul {
  list-style-type: disc;

  & li {
    list-style-type: disc;
  }
}

.LegalDoc .Footer {
  position: static;
}

.Indent {
  text-indent: 40px;
}
