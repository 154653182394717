@import './_breakpoints.css';

.NoSavedSearchesTabletAndDesktop {
  display: block;
}

.NoResultsBackgroundImage {
  background-image: url('../assets/images/no-alerts-saved-homes-searches-tablet-desktop-background.png');
  height: 554px;
  max-height: 554px;
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
}

.NoResultsCard {
  box-sizing: border-box;
  width: 431px;
  border-radius: 10px;
  background-color: rgb(255 255 255 / 92%);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NoResultsIcon {
  height: 98px;
  width: 98px;
  margin: 0 auto;

  & .Icon {
    height: 98px;
    width: 98px;
  }
}

.NoResultsText {
  align-self: center;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  margin: 10px 0;
  text-align: center;
}

.AccessFiltersButton {
  margin: 10px auto;
  max-width: 311px;
}

.NoSavedSearchesMobile {
  display: none;
}

@media (--tablet-screen) {
  .NoResultsBackgroundImage {
    height: 447px;
    max-height: 447px;
    max-width: 726px;
    /* 3x3 grid of houses visible */
    background-size: 134% auto;
  }

  .NoResultsText {
    line-height: 22px;
  }
}

@media (--small-screen) {
  .NoSavedSearchesTabletAndDesktop {
    display: none;
  }

  .NoSavedSearchesMobile {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    padding: 0 10px;
    width: 100vw;
  }

  .NoResultsNotice {
    margin-bottom: 60px;
  }

  .NoResultsText {
    align-self: center;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    margin-top: 30px;
    text-align: center;
  }
}

@media (--xxx-small-screen) {
  .NoSavedSearchesMobile {
    margin-top: 30px;
  }

  .NoResultsNotice {
    margin-bottom: 20px;
  }
}
