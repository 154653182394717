@value animation-emphasize from './_fontsAnimations.css';

.LoadingMoreWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
}

.LoadingMoreIndicator {
  width: 120px;
  height: 120px;
}

.EmphasizedListItem {
  animation: emphasize 0.2s linear 0.2s 2 alternate forwards;
}

.UlStyledAsDiv {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

@keyframes emphasize {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.05);
  }
}
