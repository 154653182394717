@import './_constants.css';

.PropertyCardValue {
  display: flex;
  flex-direction: column;
  line-height: 24px;
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;
  width: 200px;
}

.PropertyCardValue.PropertyCardValueNoEstimatedPayment {
  width: auto;
}

.PriceRow {
  font-weight: 900;
}

.ValueLabel {
  margin-right: 6px;
}

.ValueLabel,
.Value {
  font-size: 15px;
  font-weight: 900;
  display: inline-block;
}

.RentalEstimate {
  font-size: 12px;
  font-weight: normal;
  display: flex;
  justify-content: center;
}

.RentalEstimateLabel {
  white-space: pre-wrap;
}

.PriceRowNoEstimatedPayment {
  margin-top: 10px;

  & .ValueLabel,
  & .Value {
    font-size: 18px;
  }
}

.HorizontalSeparator {
  height: 2px;
  transform: scaleY(-1);
  background-color: #e9e9e9;
}

.EstimatedMonthlyPayment {
  font-size: 11px;
  font-weight: 100;
  white-space: nowrap;
}

.BottomLabel {
  font-size: 9px;
  color: #6d7278;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabled {
  filter: blur(2px);
  opacity: 0.7;
}
