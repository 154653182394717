@import './_breakpoints.css';

.LotMapNullState {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 24px;

  & p {
    align-self: flex-start;
    text-align: center;
    margin: 24px;
  }

  & .DesktopPinSVG {
    height: 108px;
    width: 85px;
    position: absolute;
    top: 48%;
    left: 48%;
    transform: translate(-50%, -50%);
  }

  & .MobilePinSVG {
    display: none;
    height: 71px;
    width: 56px;
    position: absolute;
    top: 48%;
    left: 48%;
    transform: translate(-50%, -50%);
  }
}

@media (--small-screen) {
  .LotMapNullState {
    font-size: 16px;
    background-image: url('../assets/images/lot-line-null-state-image-mobile.png') !important;

    & .DesktopPinSVG {
      display: none;
    }

    & .MobilePinSVG {
      display: block;
    }
  }
}
