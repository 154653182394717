@import './_breakpoints.css';

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    /* transform: translateY(0); */
  }

  to {
    opacity: 0;
    /* transform: translateY(-20px); */
  }
}

.MobileStickyFooter {
  display: none;
}

@media (--small-screen) {
  .MobileStickyFooter {
    box-sizing: border-box;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 70px;
    left: 0;
    right: 0;
    width: 100vw;
    padding: 40px 11px 0 13px;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0%) 0%,
        #fff 72%,
        #fff 100%
      );
      opacity: 1;
      transition: opacity 1s ease-in;
    }

    &.isHidingGradient {
      &::before {
        opacity: 0;
        transition: opacity 0.2s ease-in;
      }
    }

    & .YourTeamTriggerButton,
    & .YourTeamTriggerButtonWellsFargo {
      bottom: 55px;
      right: 21px;
    }

    & .ScrollToTop,
    & .YourTeamTriggerButton {
      border: 1px solid #e9e9e9;
    }

    & .ScrollToTop,
    & .LenderCTAButton,
    & .YourTeamTriggerButton,
    & .YourTeamTriggerButtonWellsFargo {
      z-index: 1;
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: 0 14px 15px rgba(0, 0, 0, 15%) !important;
      animation: fade-in 0.3s;
    }

    & .ScrollToTop.hidden,
    & .LenderCTAButton.hidden,
    & .YourTeamTriggerButton.hidden,
    & .YourTeamTriggerButtonWellsFargo.hidden {
      display: none !important;
    }

    & .RightCol {
      width: calc(100% - 48px);
      display: flex;
      justify-content: flex-end;
      align-items: center;

      & .LenderCTAButtonPDP {
        margin: 0;
        width: auto;
      }

      & .YourTeamTriggerButton,
      & .YourTeamTriggerButtonWellsFargo,
      & .LenderCTAButton {
        box-sizing: border-box;
        flex-shrink: 0;
        min-height: auto;
        max-height: 35px;
        height: 35px;
        min-width: 120px;
        font-weight: 500;
        font-size: 10px;
        letter-spacing: -0.2px;
        margin-left: 17px;
      }

      & .YourTeamTriggerButton .ModalButtonSVG,
      & .YourTeamTriggerButton .ModalButtonSVG-svg {
        height: 15px;
        width: 15px;
      }
    }
  }
}
