@import './Dropdown.css';

.ButtonMenuContainer {
  position: relative;

  & .Menu {
    list-style-type: none;
    padding: 0;
    position: absolute;
    top: 30px;
    left: 6px;
    z-index: 200;
    background: #fff;
    box-shadow: 0 0.125rem 0.125rem 0 rgb(0 0 0 / 14%),
      0 0.1875rem 0.0625rem -0.125rem rgb(0 0 0 / 20%),
      0 0.0625rem 0.3125rem 0 rgb(0 0 0 / 12%);
  }
}
