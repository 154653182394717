@import './_breakpoints.css';

.PDPLoanOfficerAd {
  color: #fff;
  padding: 25px 25px 34px;
}

.HeadlineCopy {
  font-size: 0.9375rem;
  font-weight: 900;
  line-height: 1.75rem;
  margin-bottom: 0.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.AdCopy {
  font-size: 32px;
  font-weight: 900;
  letter-spacing: -0.61px;
  line-height: 33px;
}

.MessageIcon {
  margin-right: 10px;
}

.PDPLoanOfficerAd .ContactButton {
  font-size: 14px;
  width: auto;
  max-width: 294px;
  height: 46px;
  padding: 15px 23px 13px 19px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-top: 19px;
  margin-bottom: 20px;

  & .ButtonText {
    /* If LO's firstname is too long to
     * fit in UI, we add ellipsis */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.LastMessageDate {
  font-size: 11px;
  font-weight: 300;
  position: absolute;
  bottom: 20px;
  left: 51px;
  opacity: 0.8;
}

.PDPLoanOfficerAd.v2Designs .PhoneAndEmail {
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
}

.PDPLoanOfficerAd.v2Designs .PhoneAndEmail > * {
  display: block;
}

.PDPLoanOfficerAd.v2Designs .PhoneAndEmailDivider {
  display: none;
}

.TextButton.CombinedContactBtn {
  padding: 0;
  font-size: 14px;
  font-weight: 800;
  text-decoration: underline;
  margin-top: 13px;

  & .MessageIcon {
    position: relative;
    top: 3px;
  }
}

@media (--small-screen) {
  .PDPLoanOfficerAd {
    display: none;
  }
}
