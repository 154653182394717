@import './_breakpoints.css';

.AgentContactInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.TopSection {
  display: flex;
  margin-bottom: 13px;
}

.BottomSection {
  display: flex;
  flex-direction: row;
}

.PhotoColumn {
  height: 59px;
  width: 59px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-right: 10px;
  box-sizing: border-box;
  border: 1px solid #fff;
}

.AgentPhoto {
  min-height: 59px;
  height: 100%;
}

.AgentName {
  font-size: 18px;
  font-weight: 900;
  /* To take care of exceptionally long agent names */
  max-width: 234px;
  word-wrap: break-word;
}

.Company {
  font-size: 14px;
  font-weight: 900;
  line-height: 19px;
}

.LoIdData {
  font-size: 11px;
  font-weight: 300;
  margin-top: 2px;
}

.PhoneAndEmail {
  display: inline-block;
  font-size: 11px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.3px;
  /* To take care of exceptionally long agent emails */
  max-width: 275px;
  word-wrap: break-word;
}

.PhoneAndEmail a {
  text-decoration: none;
  color: currentcolor;
}

.PhoneCallLink {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  background: #f4f4f4;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PhoneCallIcon,
.PhoneCallIcon-svg {
  height: 17px;
  width: 17px;
}

.ContactLOButton {
  display: flex;
  padding: 14px 0 0;
  border: none;
  background: none;
  cursor: pointer;
  color: inherit;
}

.ContactLOMessageIcon,
.ContactLOMessageIcon-svg {
  height: 18px;
  width: 18px;
}

.ContactLOMessageIcon {
  margin-right: 5px;
  cursor: pointer;
}

.ContactLOButtonText {
  font-size: 11px;
  font-weight: bold;
  line-height: 18px;
  cursor: pointer;
}

/* Allows City/State line to break on to a new line if too big for one line */
.BranchAddressCityState {
  display: inline-block;
}

@media (--small-screen) {
  .PhotoColumn {
    height: 42px;
    width: 42px;
    margin-right: 8px;
  }

  .AgentPhoto {
    min-height: 42px;
  }

  .AgentName {
    font-size: 13px;
    line-height: 16px;
    /* To take care of exceptionally long agent names */
    max-width: 200px;
  }

  .Company {
    font-size: 11px;
    line-height: 11px;
  }

  .LoIdData {
    font-size: 10px;
    line-height: 10px;
  }

  .PhoneAndEmail {
    font-size: 12px;
    font-weight: 300;
    /* To take care of exceptionally long agent emails */
    max-width: 200px;
  }

  .PhoneAndEmail.withLeftMargin {
    margin-left: 50px;
  }

  .TopSection {
    margin-bottom: 0;
  }
}

@media (--xxx-small-screen) {
  .AgentName {
    /* To take care of exceptionally long agent names */
    max-width: 160px;
  }

  .PhoneAndEmail {
    /* To take care of exceptionally long agent emails */
    max-width: 160px;
  }
}
