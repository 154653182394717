@import './_breakpoints.css';
@value box-shadow-long-big-blur from './_fontsAnimations.css';

.ConnectWithATopLocalAgentContainer {
  width: 355px;
  height: 341px;
  border-radius: 14px;
  background: #364656;
  position: sticky;
  box-shadow: box-shadow-long-big-blur;
  top: 55px;
  overflow: hidden;
  transition: top 300ms;
  margin: 40px auto;
  padding: 27px 24px 63px 35px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.LogoAndLabel {
  display: flex;
  flex-direction: row;

  & .LogoLabel {
    font-size: 18px;
    color: white;
    font-family: Helvetica;
    font-weight: bold;
    align-self: center;
  }

  & .LogoWrapper {
    width: 23px;
    height: auto;
    margin-right: 5px;
  }
}

.Description {
  font-size: 38px;
  color: white;
  font-family: Helvetica;
  font-weight: bold;
  line-height: 40px;
}

.PillButton {
  font-family: Helvetica;
  font-weight: bold;
  width: 232px;
  padding: 15px 0;
  border-radius: 23px;
  font-size: 15px;
}

@media (--large-screen) {
  .ConnectWithATopLocalAgentContainer {
    /* z-index is needed to ensure that the box-shadow overlaps other sections on the page */
    z-index: 4;
    margin: 0;
  }
}
