@import './_breakpoints.css';

.TrackOrBuyHomeLayout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 28px;

  &:first-child {
    margin-bottom: 60px;
  }
}

.TextContent {
  text-align: left;
  padding: 44px 57px 27px 0;

  & .Title {
    font-weight: 900;
    font-size: 31px;
    line-height: 40px;
    margin: 0 0 12px;
    padding: 0;
  }

  & .Content {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin: 0;
    max-width: 415px;
  }

  & .CTAButton {
    margin-top: 28px;
  }
}

.TrackOrBuyHomeLayout.ImageLeft {
  flex-direction: row-reverse;
  padding-left: 0;
  padding-right: 28px;

  & .TextContent {
    padding: 44px 0 27px 60px;
  }
}

.ImageColumn {
  & .Image {
    width: 475px;
    border-radius: 14px;
    box-shadow: 0 16px 20px rgb(0 0 0 / 16%);
  }
}

@media (--tablet-screen-and-smaller) {
  .TrackOrBuyHomeLayout {
    padding-left: 24px;
  }

  .ImageColumn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }

  .TrackOrBuyHomeLayout.ImageLeft {
    padding-left: 0;
    padding-right: 24px;

    & .ImageColumn {
      margin-left: 0;
      margin-right: 20px;
    }
  }

  .ImageColumn .Image {
    width: 100%;
    height: auto;
  }

  .TextContent {
    padding-right: calc(100vw / 20);
  }

  .TrackOrBuyHomeLayout.ImageLeft .TextContent {
    padding-left: calc(100vw / 20);
  }
}

@media (--small-screen) {
  .TrackOrBuyHomeLayout,
  .TrackOrBuyHomeLayout.ImageLeft {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    position: relative;
    width: 100vw;
    margin: 0;
    max-height: 50%;
    min-height: 225px;

    & .TextContent {
      box-sizing: border-box;
      position: relative;
      top: -36px;
      left: 0;
      right: 0;
      margin-right: 16px;
      margin-left: 16px;
      background: #fff;
      box-shadow: 0 14px 20px rgb(0 0 0 / 16%);
      border-radius: 14px;
      max-width: 343px;
      padding: 20px 16px;

      & .Title {
        font-size: 21px;
        line-height: 24px;
        margin: 0 0 8px;
        padding: 0;
      }

      & .Content {
        font-size: 14px;
        line-height: 19px;
        margin: 0;
      }

      & .CTAButton {
        margin-top: 20px;
        font-size: 15px;
        padding: 11px;
        min-width: 152px;
        box-sizing: border-box;
      }
    }

    & .ImageColumn {
      width: 100vw;
      margin: 0;
      height: 130px;
      overflow: hidden;

      & .Image {
        border-radius: 0;
        box-shadow: none;
      }
    }
  }

  .TrackOrBuyHomeLayout:first-child {
    margin-bottom: 0;
  }

  .TrackOrBuyHomeLayout + .TrackOrBuyHomeLayout {
    top: -5px;
  }

  .TrackOrBuyHomeLayout:only-of-type,
  .TrackOrBuyHomeLayout.ImageLeft:only-of-type {
    & .ImageColumn {
      height: 361px;

      & .Image {
        height: 100%;
        width: auto;
      }
    }
  }
}
