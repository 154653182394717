@import './_breakpoints.css';
@import './_constants.css';
@value rounded-border, box-shadow-property-card from './_fontsAnimations.css';
@value text-color from './_colors.css';
@value twoColumnLayoutMinWidth, threeColumnLayoutMinWidth from './_column_breakpoints.css';

.ImageCTAWrapperHODashboard {
  /*
   * DON'T CHANGE without informing clients that
   * we are changing the size of the image cta on the search page
   * because we use this value as a standard full cover image.
   * Ref: https://docs.comehome.com/#create-a-new-cta
   */
  max-width: var(--ho-card-max-width);
  width: 100%;
  border-radius: rounded-border;
  background: #fff;
  box-shadow: box-shadow-property-card;
  overflow: hidden;
  /* Margin-right is added by CHOPDP.css in media query */
  margin-bottom: 25px;
  /*
   * DON'T CHANGE this without informing clients that
   * we are changing the size of the image cta on the search page
   * because we use this value as a standard full cover image.
   * Ref: https://docs.comehome.com/#create-a-new-cta
   */
  height: 326px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ImageFullCover {
  object-fit: cover;
  width: 100%;
}

.ImageFixedSize {
  width: 300px;
  height: 250px;
  object-fit: contain;
}

.ImageCTAWrapperPDP {
  /*
   * DON'T CHANGE this without informing clients that
   * we are changing the size of the image cta on the search page
   * because we use this value as a standard full cover image.
   * Ref: https://docs.comehome.com/#create-a-new-cta
   */
  width: 100%;
  /*
   * DON'T CHANGE this without informing clients that
   * we are changing the size of the image cta on the search page
   * because we use this value as a standard full cover image.
   * Ref: https://docs.comehome.com/#create-a-new-cta
   */
  height: 250px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.ImageCTAWrapperPDPFullCover {
  /*
   * DON'T CHANGE this without informing clients that
   * we are changing the size of the image cta on the search page
   * because we use this value as a standard full cover image.
   * Ref: https://docs.comehome.com/#create-a-new-cta
   */
  height: 275px;
}

.ImageCTAWrapper {
  background: #fff;
  border: 1px solid #f0f0f0;
  color: text-color;
  border-radius: rounded-border;
  transition: box-shadow 0.2s, transform 0.2s;
  margin-right: 15px;
  /*
   * DON'T CHANGE this without informing clients that
   * we are changing the size of the image cta on the search page
   * because we use this value as a standard full cover image.
   * Ref: https://docs.comehome.com/#create-a-new-cta
   */
  height: var(--propertyCardHeight);
  /*
   * DON'T CHANGE this without informing clients that
   * we are changing the size of the image cta on the search page
   * because we use this value as a standard full cover image.
   * Ref: https://docs.comehome.com/#create-a-new-cta
   */
  width: calc(var(--propertyCardWidth) - 15px);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: box-shadow-property-card;
  }
}

.ImageCTAAnchor {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NoBorder {
  border: none;
}

.ImageCTAWrapperHIC {
  margin: 0 auto 40px;
  /*
   * DON'T CHANGE without informing clients that
   * we are changing the size of the image cta on the search page
   * because we use this value as a standard full cover image.
   * Ref: https://docs.comehome.com/#create-a-new-cta
   */
  width: 380px;
  height: 333px;
}

.ScreenReaderVisuallyHidden {
  composes: screen-reader-only from './_common.css';
}

@media (--x-small-screen) {
  .ImageCTAWrapper {
    margin-right: auto;
    margin-left: auto;
    width: 347px;
    height: 348px;
  }

  .ImageCTAWrapperHIC {
    width: 335px;
    height: 294px;
  }
}

@media (min-width: twoColumnLayoutMinWidth) {
  /*
   * DON'T CHANGE this without without informing clients that
   * we are changing the size of the image cta on the search page
   * because we use this value as a standard full cover image.
   * Ref: https://docs.comehome.com/#create-a-new-cta
   */
  .ImageCTAWrapperHODashboard {
    width: 400px;
  }
}

@media (min-width: threeColumnLayoutMinWidth) {
  .ImageCTAWrapperHODashboard {
    margin-right: 30px;
    height: var(--ho-card-height);
  }

  .ImageCTAWrapperHIC {
    margin: 20px auto 0;
  }
}

@media (--xx-small-screen) {
  .ImageCTAWrapperHODashboard {
    max-width: 335px;
    width: 100%;
    height: 319px;
  }
}
