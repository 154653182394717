@value ch-black, text-color from './_colors.css';

.ScreenReaderOnly {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.HomeownerHomeBuyerToggle {
  border-radius: 9px;
  background-color: rgb(118 118 128 / 9%);
  padding: 2px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  cursor: pointer;
}

.ToggleButton {
  box-sizing: content-box;
  padding: 6.5px 18px 4.5px;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  text-decoration: none;
  color: text-color;
  border: none;
  background: none;
}

.ToggleButton.isActive {
  box-sizing: border-box;
  box-shadow: 0 3px 8px 0 rgb(0 0 0 / 12%), 0 3px 1px 0 rgb(0 0 0 / 4%);
  border-radius: 7px;
  border: 0.5px solid rgb(0 0 0 / 4%);
  background: #fff;
  border-color: #fff;
  color: ch-black;
}
