@import './_constants.css';

.DismissibleBanner {
  width: 100%;
  height: 81px;
  padding: 15px 30px;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  cursor: default;
  box-shadow: 0 2px 8px 0 rgb(220 212 212 / 50%);
  z-index: 100;

  & .CloseButton {
    border: none;
    background: transparent;
    height: 24px;
    width: 24px;
    cursor: pointer;
    padding: 0;
    transform: scale(0.7);
  }

  & .Window {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 38px;
  }

  & .Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
