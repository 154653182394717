@import './_constants.css';

.PropertyIntro {
  margin-bottom: 30px;
}

.PropertyPageDesktopContents {
  & .PropertyIntro {
    margin-bottom: 30px;
  }
}

.FooterSectionWithSeparator {
  padding: 0 15px;
}

.Columns {
  display: flex;
  position: relative;
  width: 100%;
  min-width: 320px;
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 1.25rem;
  box-sizing: border-box;
  z-index: 1;
}

.LeftSide {
  flex: 1;
  /* Cause content to fit within this column and not push the right-column off the page */
  overflow: hidden;
  padding-right: 20px;
  /* Since we need overflow:hidden here, we need to padding to allow long BreakoutSections modals to
   * show completely */
  padding-bottom: 175px;
}

.RightSide {
  flex: none;
}
