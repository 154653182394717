@import './_breakpoints.css';

.AvmFactorValueDelta {
  font-size: 16px;
  font-weight: 600;
}

@media (--small-screen) {
  .AvmFactorValueDelta {
    font-size: 12px;
  }
}
