.CTAHeader {
  font-size: 14px;
  line-height: 19px;
  padding-top: 28px;
  padding-left: 40px;
}

.CTABody {
  font-size: 32px;
  letter-spacing: -0.61px;
  line-height: 38px;
  padding-top: 12px;
  padding-left: 40px;
}

.PillButton {
  height: 55px;
  width: 280px;
  background-color: #4d4de6;
  display: block;
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  border: none;
  margin: auto;
  font-weight: 900;
}

.PDPFinanceCTA {
  height: 270px;
  background-color: #3a3a79;
  color: #fff;
  margin-top: 20px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  font-weight: 900;

  &.PDPFinanceCTAStandalone {
    border-radius: 14px;
    width: calc(100% - 20px);
    margin: 0 auto 25px;

    & .PillButton {
      width: 260px;
    }

    & .CTAHeader {
      padding-left: 20px;
    }

    & .CTABody {
      padding-left: 20px;
    }
  }
}

.MainContent {
  height: 152px;
  margin-bottom: 20px;
}
