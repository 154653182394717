@import './_breakpoints.css';
@value modal-overlay from './_colors.css';

.SmallModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Above .AutoComplete in Header.css, HeaderChase.css, HeaderComehome.css */
  z-index: 102;
  box-sizing: border-box;
  pointer-events: none;
}

.Modal {
  min-width: 200px;
  max-width: 100%;
  min-height: 100px;
  max-height: 100%;
  background: white;
  position: relative;
  font-size: 16px;
  padding: 15px;
  box-sizing: border-box;
  overflow: auto;
  overscroll-behavior-y: contain;
  pointer-events: auto;
}

.CloseIcon {
  background: none;
  border: none;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 1;
}

.Screen {
  width: 100%;
  height: 100%;
  /* Pose forces position: absolute here */
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  background: modal-overlay;

  &.DarkScreen {
    background: rgb(0 0 0 / 70%);
  }
}

.SmallModal.BottomAnchored {
  align-items: flex-end;
  /* Ensure the close button at the top is visible when the modal takes up the entire viewport */
  padding-top: 45px;
}

.SmallModal.NoTopPadding {
  padding-top: 0 !important;
}

.BottomAnchored .Modal {
  max-width: 100vw;
  width: 100vw;
  top: auto;
  bottom: 0;
}

.FloatingCloseIcon {
  background: none;
  border: none;
  position: fixed;
  top: 24px;
  right: 22px;
  transform: scale(0.65);
  z-index: 102;

  & svg :global .element-to-fill {
    fill: #fff;
  }
}

@media (--small-screen) {
  .Dialog {
    width: calc(100% - 30px);
  }

  .FloatingCloseIcon {
    right: 10px;
    top: 10px;
    width: 30px;
    transform: scale(1);
  }
}
