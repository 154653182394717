@import './_breakpoints.css';
@import './_constants.css';
@value rounded-border, box-shadow-property-card from './_fontsAnimations.css';
@value text-color from './_colors.css';

.AdCardForCobrand {
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  border: 1px solid #dcdcdc;
  color: text-color;
  padding: 18px 40px 34px;
  border-radius: 14px;
  transition: box-shadow 0.2s, transform 0.2s;
  margin-right: 15px;
  height: var(--propertyCardHeight);
  width: calc(var(--propertyCardWidth) - 15px);
  box-sizing: border-box;
  position: relative;

  &:hover {
    box-shadow: box-shadow-property-card;
  }
}

.IntroText {
  font-size: 15px;
  font-weight: 900;
  line-height: 28px;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.HeadlineText {
  font-size: 30px;
  font-weight: 900;
  line-height: 35px;
  max-height: 125px;
}

.ButtonContainer {
  display: flex;
  flex-grow: 1;
  align-self: center;
  bottom: 40px;
  width: 100%;
}

.Button {
  align-self: flex-end;
  height: 45px;
  font-size: 14px;
  font-weight: 900;
}

@media (--small-screen) {
  .AdCardForCobrand {
    margin-right: 0;
    width: 100%;
  }
}

@media (--xxx-small-screen) {
  .AdCardForCobrand {
    margin-right: 0;
    width: 300px;
    padding: 20px;
  }
}
