@import './_breakpoints.css';

.PropertyDataTable {
  width: 100%;
  border-spacing: 0;

  & .Heading {
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    margin-bottom: 10px;
  }
}

.ListingNotes {
  box-sizing: border-box;
}

.listingNotesTitle {
  font-size: 15px;
  font-weight: bold;
}

.listingNotesContent {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.584em;
  margin: 10px 0 0;
}

.DataList,
.propDetailsItem,
.propDetailsType,
.propDetailsData {
  font-size: 12px;
  box-sizing: border-box;
  padding: 0;
}

.DataList {
  padding-left: 0;
  margin: 0;
}

.propDetailsItem {
  display: block;
  margin-bottom: 6px;
  border-bottom: 1px solid #e9e9e9;
  margin-top: 6px;
}

.propDetailsItem:first-of-type {
  margin-top: 0;
}

.propDetailsItem:last-of-type {
  margin-bottom: 0;
  border-bottom: 0;
}

.Legal {
  font-size: 12px;
  margin-top: 20px;
  text-align: center;
  list-style-type: none;
}

.propDetailsType,
.propDetailsData {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  line-height: 1.2667em;
  box-sizing: border-box;
  margin-bottom: 4px;
}

.propDetailsData {
  font-weight: 500;
  overflow-wrap: break-word;
}

.propDetailsType {
  font-weight: bold;
  padding-right: 15px;
  box-sizing: border-box;
  text-align: left;
}

@media screen and (min-width: 650px) {
  .propDetailsItem {
    margin-bottom: 0;
    margin-right: 40px;
  }

  .propDetailsItem:first-of-type {
    margin-top: 0;
  }

  .DataList {
    display: block;
    columns: 2;
  }


  .propDetailsItem:last-of-type {
    border-bottom: 1px solid #e9e9e9;
  }
}

@media (--small-screen) {
  .PropertyDetails {
    & .propDetailsType {
      line-height: 30px;
      margin-bottom: 0;
      font-weight: 400;
    }

    & .propDetailsData {
      line-height: 30px;
      margin-bottom: 0;
      font-weight: 200;
    }

    & .propDetailsItem {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
