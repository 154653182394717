.CTARequestForm .Modal {
  height: 732px;
  width: 364px;
  border-radius: 14px;
  background-color: #fff;
  box-shadow: 0 15px 14px 0 rgb(0 0 0 / 14%);
  justify-content: center;
  z-index: 6;
  padding: 14px 14px 0;
}

.FormHeader {
  color: #4a4a4a;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 21px;
  width: 280px;
  margin-bottom: 20px;
}

.FormSection {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
}

.InputField {
  margin: 5px 0;
}

.InputElement {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  border-radius: 4px;
  border: 0.6px solid #9e9e9e;
}

.ButtonWrapper {
  display: flex;
  padding-top: 24px;
}

.ContentFont {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 10px;
}

.TextAreaWrapper {
  position: relative;
}

.TextAreaWrapper .InputWrapper {
  outline: 0;
}

.TextArea .InputElement {
  font-size: 12px;
  font-family: Avenir;
  color: #4a4a4a;
  font-weight: 500;
  border: 0.6px solid #9e9e9e;
}

.TextAreaWrapper .InputElement {
  font-size: 12px;
  font-family: Avenir;
  color: #4a4a4a;
  font-weight: 500;
  border: 0.6px solid #9e9e9e;
}

.SendButton {
  height: 44px;
  width: 320px;
  border-radius: 23px;
  background-color: #4d4de6;
  margin: auto;
}

.SendButtonDisabled {
  background: #c8c8c8 !important;
}

.RequestSuccessContent {
  margin: 0 8px;

  & .GreenCheckIcon {
    height: 0;
    margin: 35px auto 41px;
    padding-right: 5px;
  }

  & .GreenCheckIcon-svg {
    width: 45px;
    height: 45px;
  }

  & .RequestSuccessHeader {
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 21px;
    text-align: start;
    padding-top: 20px;
    width: 305px;
    margin: 16px auto;
  }

  & .HeaderContent {
    display: flex;
  }

  & .RequestSuccessBody {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    margin: auto;
    padding-top: 10px;
  }

  & .ButtonWrapper {
    display: flex;
    margin-top: 40px;
  }

  & .CloseButton {
    height: 44px;
    width: 306px;
    border-radius: 23px;
    background-color: #4d4de6;
    margin: auto;
  }
}

@media (max-width: 420px) {
  .CTARequestForm .Modal {
    width: 99% !important;
    height: 100%;
  }

  .SendButton {
    width: 240px;
  }

  .InputElement {
    font-size: 16px;
  }

  .TextArea .InputElement {
    font-size: 16px;
  }

  .TextAreaWrapper .InputElement {
    font-size: 16px;
  }
}
