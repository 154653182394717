@import './_breakpoints.css';
@value ch-black from './_colors.css';

.Marker {
  background: none;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

  &:hover {
    /* Above all other markers which range from 2,000,000 to 6,000,000 (based on latitude) */
    z-index: 10000000 !important;
  }

  & .MarkerImage {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
  }

  & .MarkerCheckImage {
    top: -5px;
    right: -2px;
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 1;
  }

  & .MarkerGrantProgramLabel {
    top: -6px;
    left: -6px;
    height: 18px;
    position: absolute;
    z-index: 1;
  }

  & .MarkerLabel {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -9px;
    z-index: 2;
    padding: 0 6px;
    box-sizing: border-box;
  }

  & .MarkerLabelInner {
    border-radius: 10px;
  }

  &.MarkerCluster .MarkerLabel {
    top: 0;
  }

  &.MultiUnitCluster .MarkerLabel {
    height: 38px;
    line-height: 10px;
    text-align: center;
    top: 0;
  }
}

.MarkerInner:focus {
  border: 2px solid ch-black;
}

.Marker:not(.MarkerCluster) {
  & .MarkerInner {
    width: 100%;
    height: 100%;
    animation: raise-return 0.3s forwards;

    &:hover {
      animation: raise 0.3s forwards;
    }
  }
}

@media (--small-screen) {
  /* Pulse animation when tapped on mobile */
  .Marker:not(.MarkerCluster):hover::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 8px;
    background-color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    transform: scale(1);
    opacity: 0.8;
    z-index: -1;
    animation: circular-expand 1.8s ease-out forwards infinite,
      circular-expand-fade-out 1.8s ease-out forwards infinite;
  }
}

@keyframes raise {
  from {
    transform: scale(1);
    transform-origin: bottom center;
  }

  to {
    transform: scale(1.05);
    transform-origin: bottom center;
  }
}

@keyframes raise-return {
  from {
    transform: scale(1.05);
    transform-origin: bottom center;
  }

  to {
    transform: scale(1);
    transform-origin: bottom center;
  }
}

@keyframes circular-expand {
  from {
    transform: scale(1) rotateX(50deg);
  }

  to {
    transform: scale(4) rotateX(50deg);
  }
}

@keyframes circular-expand-fade-out {
  0% {
    opacity: 0.8;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    opacity: 0;
  }
}
