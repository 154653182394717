@value light-grey from './_colors.css';

.pickerContainer {
  z-index: 1;
  width: 100%;
  max-width: 270px;
  margin: 0 auto;
  box-sizing: border-box;
  user-select: none;
}

.pickerInner {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  height: 100%;
  font-size: 16px;
  mask-image: linear-gradient(
    to top,
    transparent,
    transparent 3%,
    white 35%,
    white 65%,
    transparent 97%,
    transparent
  );
}

.TooltipContainer {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  height: 0;

  & .TooltipContainerSection {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}

.TooltipConfirmation {
  font-size: 12px;
  font-weight: 200;
  max-width: 260px !important;
  box-sizing: border-box;
}

.TooltipConfirmationButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  font-size: 13px;

  & button {
    background-color: transparent;
    border: none;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    font-weight: 600;
    font-size: 13px;
  }
}

.pickerColumn {
  box-sizing: border-box;
  flex: 1 1;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  text-align: center;
}

.pickerItemSelected {
  font-weight: 900;
}

.pickerColumn .pickerScroller {
  box-sizing: border-box;
  transition: transform;
  transition-duration: 100ms;
  transition-timing-function: ease-out;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.pickerColumn .pickerItem {
  box-sizing: border-box;
  position: relative;
  padding: 0 10px;
  white-space: nowrap;
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}

.pickerColumn .pickerItem.pickerItemSelected {
  box-sizing: border-box;
  color: inherit;
}

.pickerColumn .pickerScroller:focus .pickerItem.pickerItemSelected {
  border: 1px solid #000;
}

.pickerHighlight {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  pointer-events: none;

  &::before,
  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    right: auto;
    display: block;
    width: 100%;
    height: 1px;
    background-color: light-grey;
    transform: scaleY(0.5);
  }

  &::before {
    top: 0;
    bottom: auto;
  }

  &::after {
    bottom: 0;
    top: auto;
  }
}
