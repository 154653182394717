@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.ScrollToTop {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 80px;
  left: 10px;
  background: #fff;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  box-shadow: 0 -1px 10px 2px rgb(0 0 0 / 13%);
  z-index: 4;
  cursor: pointer;
  animation: fade-in 0.3s;
  padding: 0;
  box-sizing: content-box;
  border: none;

  & svg {
    transform: rotate(90deg);
    height: 18px;
    width: 18px;
  }
}

.ScrollToTopHidden {
  display: none;
}
