@import './_breakpoints.css';
@value square-border from './_fontsAnimations.css';
@value text-color from './_colors.css';

.ConfirmModal {
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DesktopCloseIcon {
  display: none;
}

.ConfirmModalContent {
  box-sizing: border-box;
  border-radius: square-border;
  transform: translateY(0);
  overflow: auto;
  padding: 1.5rem;
  max-width: 375px;
  margin: auto;
}

.ConfirmModalTitle {
  font-size: 1.5rem;
  font-weight: 900;
  text-align: center;
  margin: 0 auto 14px;
}

.ConfirmModalBody {
  font-size: 1rem;
  font-weight: 300;
  color: text-color;
  padding-bottom: 42px;
}

.ConfirmModalFooter {
  display: flex;
  justify-content: space-evenly;

  & .PillButton {
    max-width: 135px;

    /* Ensure that they don't touch even on very small screens */
    &:first-child {
      margin-right: 5px;
    }
  }
}

@media (--x-small-screen) {
  .ConfirmModalContent {
    width: 96vw;
  }
}

/* Buttons would reach edge of modal on very small screens,
 * this makes them on top of each other instead of side by side:
 */
@media (--xxx-small-screen) {
  .Primary {
    margin-bottom: 1em;
  }

  .Body {
    margin-bottom: 2em;
  }

  .ConfirmModalFooter {
    display: block;
    text-align: center;
  }
}
