.ContactButton {
  width: auto;
  height: auto;
  max-width: 311px;
  max-height: 46px;
  font-size: 14px;
  line-height: 19px;
  padding: 13px 23px 13px 19px;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;
  white-space: nowrap;

  & .ButtonText {
    /* If LO's firstname is too long to
     * fit in UI, we add ellipsis */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.MessageIcon,
.MessageIcon-svg {
  height: 18px;
  width: 18px;
}

.MessageIcon {
  position: relative;
  top: 1px;
  margin-right: 18px;
}
