.RecentProperty,
.RecentPropertyItem {
  display: flex;
  width: 100%;
}

.RecentPropertyWrapper {
  width: 100%;

  & .PulseLoader {
    display: flex;
    width: 100%;
    height: 55px;
  }
}

.RecentPropertyItem {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  align-items: inherit;
  font-weight: lighter;
  padding: 0;
}

.ImageWrapper {
  height: 57px;
  width: 57px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 11px;
  background: #e9e9e9;
  background-size: cover;
}

.Image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.Details {
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 160px;
}

.DetailItem {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  width: 100%;
}

.ListPrice {
  font-weight: 800;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  width: 100%;
}

.Divider {
  display: inline-block;
  margin: 0 5px;
  color: #767676;
}

.WatchListButtonWrapper {
  width: 35px;

  & .CircleButton {
    transform: scale(0.6);
  }

  & .WatchListActionButton {
    height: unset;
  }
}
