@import './_constants.css';

.PropertyPageDesktopContents {
  & .PropertyIntro {
    margin-bottom: 30px;
  }

  & .PDPRightRailCard {
    & .ListingStatusSectionWithLogo {
      display: flex;
      flex-direction: row;
    }

    & .ComeHomeIcon {
      margin-right: 5px;
    }

    & .ComeHomeIcon svg {
      width: 60px !important;
      height: 55px;
    }
  }
}

.FooterSectionWithSeparator {
  padding: 0 15px;
}

.Columns {
  display: flex;
  position: relative;
  width: 100%;
  min-width: 320px;
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 1.25rem;
  box-sizing: border-box;
  z-index: 1;
}

.LeftSide {
  flex: 1;
  overflow: hidden;
  padding-right: 20px;
  /* Cause content to fit within this column and not push the right-column off the page */
  /* Since we need overflow:hidden here, we need to padding to allow long BreakoutSections modals to
   * show completely */
  padding-bottom: 175px;
}

.AttributionWrapper {
  margin-top: 6px;
}

.RightSide {
  flex: none;
}

.FindAgentWrapper {
  margin-top: 48px;
  margin-bottom: 40px;
}
