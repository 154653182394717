@import './_breakpoints.css';
@value text-color from './_colors.css';
@value square-border from './_fontsAnimations.css';

.ResizableContainer {
  position: relative;
}

.Resizer {
  border: none;
  padding-inline: 0;
  position: absolute;
  top: 50%;
  width: 20px;
  height: 45px;
  background-color: text-color;
  border-radius: square-border;
  z-index: 2;
  cursor: pointer;
  -ms-scroll-chaining: none;

  &.positioned-right {
    transform: translateX(10px);
  }

  &.positioned-left {
    transform: translateX(-10px);
  }
}

.ResizerIcon {
  display: block;
  margin-top: 9px;

  & svg {
    transform: scaleY(1.2);
    height: 24px !important;
  }

  & svg :global .element-to-fill {
    fill: white;
  }

  &.ResizeIconRight {
    transform: scaleX(-1);
  }
}

/* For now, we're not supporting resizing on mobile */
@media (--small-screen) {
  .Resizer {
    display: none;
  }
}
