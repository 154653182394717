.Realtor {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 36px;
}

.PhotoColumn {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-right: 17px;
  box-sizing: border-box;
  min-width: 70px;
  border: 1px solid #dcdcdc;
}

.AgentPhoto {
  min-height: 70px;
  height: 100%;
}

.AgentVerbiage {
  font-size: 16px;
  font-weight: 900;
  line-height: 21px;
}
