@import './_breakpoints.css';
@import './_constants.css';

.PropertyPageTopBar {
  background-color: #fff;
  width: 100%;
}

.PropertyPageTopBarInner {
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: var(--header-max-width-chase);
  margin: auto;
  padding: 0 20px;
  box-sizing: border-box;
}

@media (--tablet-screen-and-smaller) {
  .PropertyPageTopBar {
    display: none;
  }
}
