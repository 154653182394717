@import './_breakpoints.css';

.PDPRightRailCard {
  width: 375px;
  border: 1px solid #f0f0f0;
  background-color: #fff;
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 14%);
  box-sizing: border-box;
  padding: 15px 30px;
  transform: translate(0, -40px);
  position: sticky;
  top: var(--topSpacing, 126px); /* --topSpacing is set by JS */
  transition: top 300ms;
  /* z-index is needed to ensure that the box-shadow overlaps other sections on the page */
  z-index: 1;

  & .ListingStatusSection .ValueLabel,
  & .ListingStatusSection .Value {
    transition: font-size 0.3s;
  }

  & .PropertyCardValueInline {
    transition: margin 0.2s;
  }

  &.PDPRightRailCardWithLargePriceFont {
    & .ListingStatusSection .ValueLabel,
    & .ListingStatusSection .Value {
      font-size: 24px;
    }

    & .PropertyCardValueInline {
      margin: 15px 0;
    }
  }

  & .ContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }

  & .LenderPropertyCTAChase {
    display: flex;
    margin: unset;
  }
}

.ListingStatusSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;

  /* stylelint-disable-next-line no-descending-specificity */
  & .PropertyCardValueInline {
    margin: 9px 0;
  }

  & .ListingStatus {
    font-size: 15px;
    font-weight: 900;
    white-space: nowrap;
  }

  & .MonthlyPaymentsSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;

    & .Label {
      font-size: 11px;
      font-weight: 300;
      margin-right: 10px;
    }
  }
}

.HorizontalSeparator {
  width: 100%;
  margin: 30px 0 0;
  border-bottom: 1px solid #e9e9e9;
}

.InEligibleForFinanceContainer {
  box-sizing: border-box;
  margin: 0 auto;
  width: 85%;

  & .ContentWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin: auto;
    max-width: 270px;
  }

  & .CircleIcon {
    height: 14px;
    width: 25px;
    border: 2px solid #e07b00;
    border-radius: 50%;
    background-color: #fff;
    margin-top: 3px;
  }

  & .SubHeading {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    letter-spacing: 0;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
  }

  & .SubText {
    font-size: 12px;
    font-weight: 200;
    line-height: 17px;
    margin-top: 20px;
  }
}

.NewSearchButton {
  padding: 0 15px;
  max-width: 311px;
  margin-bottom: 15px;
}

.LenderCTA {
  width: 90%;
  height: 36px;
  margin: 20px 0;

  & .LenderCTAButton {
    font-size: 14px;
    font-weight: 900;
  }
}

.SavePropertySection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px 40px 30px;
  box-sizing: border-box;
  width: 100%;

  & .WatchListActionButton {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: initial;

    & .CircleButton {
      cursor: pointer;
      height: 56px;
      width: 56px;
    }

    & .ButtonLabel {
      font-weight: 300;
      position: absolute;
      bottom: 5px;
      left: 0;
      width: 100%;
      text-align: center;
      display: block;
    }
  }

  & .SaveHomesText {
    font-size: 12px;
    font-weight: 200;
    margin: 0 10px 0 30px;
  }
}

.ShareAndSavePropertySection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 5px;
  width: 100%;

  & .WatchListActionButton,
  & .ShareButtonContainer {
    height: 75px;
    font-size: 12px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & .ShareButton {
      transform: none;

      & .HeartIcon svg {
        transform-origin: top center;
        width: 22px;
        height: 20px;
      }
    }

    & .CircleButton {
      cursor: pointer;
      height: 56px;
      width: 56px;
      transform: translateY(-10px);
    }

    & .ButtonLabel {
      font-weight: 300;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      display: block;
    }
  }

  & .ShareButtonContainer {
    border-right: 1px solid #f0f0f0;
  }
}

.MLSAttribution {
  font-size: 9px;
  font-weight: 200;
  /* 10px spacing below the bottom of the price section, 10px spacing above the bottom of the right rail
   * card when the mobile PDP is expanded */
  margin: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: #6d7278;
}

@media (--small-screen) {
  /* place mls label lower towards the bottom for mobile pdp expanded page to match design requirement */
  .MLSAttributionOnMobilePDP {
    margin: 20px 0 7px;
  }

  .HorizontalSeparator {
    display: none;
  }

  .NewSearchButton {
    margin-top: 30px;
  }

  .LenderPropertyCTAChase {
    margin-bottom: 25px !important;
  }
}
