.CircleButton {
  border: none;
  width: 60px;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  border-radius: 50%;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

  & .CircleButtonBackground {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
    border-radius: 50%;
  }

  &.ActiveButton .CircleButtonBackground {
    opacity: 1;
  }
}

.ChildrenContainer {
  z-index: 1;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
