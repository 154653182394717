.MobileFiltersSlider {
  width: 100%;
  padding: 25px 0 10px;
  box-sizing: border-box;
  /* To prevent scrollbar from appearing in mobile filters UI.  We need to allow overflow
   * in the filter control to account for large multi-select lists on small screens but
   * this particular control should never need to overflow */
  overflow: hidden;
}

.MobileFiltersSlider .RangeSlider {
  max-width: calc(100% - 40px);
  margin: 0 auto;
}

.hotToCool {
  & :global .input-range__track.input-range__track--active {
    background-image: linear-gradient(
      to right,
      rgb(253 137 93),
      rgb(246 197 68),
      rgb(226 230 52),
      rgb(140 226 104),
      rgb(39 223 168)
    );
  }

  & :global .input-range__track:not(.input-range__track--active)::after {
    background-color: #fff;
  }

  & :global .input-range__track.input-range__track--background {
    background-color: #fff;
  }
}

.coolToHot {
  & :global .input-range__track.input-range__track--active {
    background-image: linear-gradient(
      to left,
      rgb(253 137 93),
      rgb(246 197 68),
      rgb(226 230 52),
      rgb(140 226 104),
      rgb(39 223 168)
    );
  }

  & :global .input-range__track:not(.input-range__track--active)::after {
    background-color: #fff;
  }

  & :global .input-range__track.input-range__track--background {
    background-color: #fff;
  }
}
