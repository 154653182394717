.PropertyCardValueSection {
  position: relative;
  margin-bottom: 10px;

  & .HidePropertyCardValueInPropertyIntro {
    margin-bottom: 20px;
  }
}

.PropertyCardPriceInfo {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  &.ShowPropertyCardPriceInfo {
    margin-top: 0;
  }
}
