@import './_breakpoints.css';
@value border-color-light from './_colors.css';

.MenuListContainer {
  border-radius: 3px;
  padding: 0;
  list-style-type: none;
  right: 0;
  left: 0;
  top: 0;
  margin: 0;
  z-index: 1;
}

.ListItem {
  padding: 5px 16px;
  color: inherit;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  height: 48px;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: #eee;
  }
}

.ListItem + .ListItem {
  border-top: 1px solid border-color-light;
}

/* stylelint-disable block-no-empty */
.ListItemTitle {
}
/* stylelint-enable block-no-empty */

.menu > .menuInner {
  width: 132px;
  border-radius: 3px;
  padding: 0;
  position: absolute;
}

.menu.topRight {
  top: 0;
  right: 0;
}

.menuItem {
  padding: 5px 16px;
  color: inherit;
}

.caption {
  font-size: 14px;
  font-weight: 600;
}

.menuItem + .menuItem {
  border-top: 1px solid border-color-light;
}

.MenuNotificationCount {
  transform: translate(6px, -1px);
}

@media (--small-screen) {
  .menu > .menuInner {
    width: 140px;
  }

  .menu.topRight {
    left: 0;
  }
}

.outline {
  box-shadow: none;
}
