.HudLogoContainer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0;
}

.HudLogoButton {
  border: none;
  background: none;
  padding: 0;
}

.HudLogo {
  display: block;
  width: 70px;
}
