@import './_breakpoints.css';

.WatchListAlertsLoggedOut {
  width: 400px;
  margin: 100px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;

  & .Button.PrimaryButton,
  & .Button.SecondaryButton {
    color: white;
  }
}

.IconSection {
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    height: 50px;
    width: 50px;
    transform: scale(2);
  }
}

.CustomIconWrapper {
  width: 98px;
  height: 98px;
  border-radius: 50%;

  & .CustomIcon {
    display: block;
    margin: 0 auto;
    position: relative;
    top: 23px;
  }
  
}

.TitleSection {
  margin-top: 30px;
  text-align: center;

  & h1 {
    font-size: 36px;
    font-weight: 900;
    margin-block: 0;
  }
}

.DescriptionSection {
  margin-top: 25px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.ButtonSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.Button {
  width: 12rem;
}

.PrimaryButton {
  /* stylelint-disable-next-line value-keyword-case */
  composes: Button;
  margin-bottom: 20px;
}

.SecondaryButton {
  /* stylelint-disable-next-line value-keyword-case */
  composes: Button;
}

@media (--small-screen) {
  .WatchListAlertsLoggedOut {
    margin-top: 4rem;
    width: 100vw;
  }

  .TitleSection {
    & h1 {
      font-size: 22px;
    }
  }

  .DescriptionSection {
    font-size: 12px;
  }
}
