@value box-shadow from './_fontsAnimations.css';
@value text-color, text-color-light, border-color, ch-orange, ch-red, hc-yellow from './_colors.css';

.LoadingSection {
  height: 170px;
}

.PriceLabel {
  line-height: 30px;
  font-weight: 600;
  color: ch-orange;
}

.PriceRow {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: calc(100% - 22px);
}

.MapMarkerPopup {
  width: 370px;
  box-shadow: box-shadow;
  background: white;
  cursor: pointer;

  &.MapMarkerPopupNoValue {
    & .PriceRow {
      width: calc(100% - 24px);
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-10px);
    }

    & .PriceRow::after {
      content: '--';
      color: inherit;
      font-weight: 400;
      font-size: 32px;
    }
  }

  & .PriceLabel {
    color: text-color;
  }
}

.MapMarkerPopup.BusinessPopup {
  width: auto;
  min-height: 113px;
  min-width: 292px;
  border-radius: 3px;
  overflow: hidden;
}

.PropertyCard {
  width: 370px;
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 8px #dadada;
}

.ThumbnailWrapper {
  position: relative;
  background: #ccc;
  background-size: cover;
  background-position: center;
}

.MapMarkerPopup.MapMarkerPopup-NoPhoto {
  width: 220px;

  & .ThumbnailWrapper {
    display: none;
  }

  & .LoadingSection {
    height: 150px;
  }

  & .PropertyCard {
    width: 220px;
  }
}

.PropertyCard.PropertyCard--bordered {
  box-shadow: none;
}

.PinWrapper {
  position: absolute;
  z-index: 2;
  bottom: 10px;
  left: 10px;
}

.PropertyDetailsWrapper {
  padding: 12px;
  font-size: 12px;
  color: text-color;
  font-weight: 600;
}

.ChildContentWrapper {
  padding: 0 12px 12px;
}

.Address,
.CityStateZip,
.OtherDetails {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Address {
  composes: strip-button-styles from './_common.css';
  white-space: nowrap;
  text-align: left;
  color: inherit;
}

.OtherDetails {
  line-height: 20px;
}

.SingleAttribute {
  display: inline-block;

  &:not(:last-child)::after {
    content: '|';
    font-weight: 100;
    font-size: 16px;
    color: border-color;
    margin: 0 5px;
  }
}

.Similarity {
  line-height: 30px;

  & .SimilarityValue {
    font-weight: 600;
    color: text-color-light;
    text-transform: capitalize;
  }
}

.Price {
  font-size: 26px;
  line-height: 26px;
  font-weight: 900;
}

.SelectCell {
  flex: 0 0 42px;
  align-self: flex-end;
}

.SelectButton {
  position: relative;
  top: 5px;
}

.MLSLabel {
  font-size: 8px;
  font-weight: 200;
  color: gray;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 22px);
}

.PriceLabelWrapper {
  height: 25px;
}

:export {
  colorGreen: #70d48a;
  colorYellow: hc-yellow;
  colorRed: ch-red;
}
