@import './_breakpoints.css';
@value background-color-lightest from './_colors.css';

.PhotoPlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
}

.StreetViewWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.MapTilePhoto {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
