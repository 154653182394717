.CantFindAddressLinkChase {
  display:block;
  font-size: 11px;
  line-height: 16px;
  text-decoration: underline;

  & svg {
    transform: scale(0.7) translate(5px, 7px);
  }
}
