@import './_constants.css';
@import './_breakpoints.css';
@value border-color-light from './_colors.css';

.InitDataSection {
  /* Needed to space the footer down so that it doesn't appear in the viewport while the
   * init data is loading */
  min-height: 300px;
}

.InitDataLoadingSection {
  min-height: 300px;
  width: 100%;
}

.FooterSection {
  & .HudLogoContainer {
    padding: 20px 0;
  }
}

.SectionDivider {
  margin: 40px 0;
  padding: 0 10px;
  border-bottom: 1px solid border-color-light;
  border-top: none;
  border-right: none;
  border-left: none;
  width: 100%;
  box-sizing: border-box;
}

.FindAgentWrapper {
  margin: 33px 13px;
}

/* Overrides to PropertyPageMobleContents components */
.PropertyPageMobileContents {
  & .PropertyIntro {
    margin-bottom: 0;

    & .DesktopPropertyInfo {
      display: none;
    }

    & .InfoToggle {
      display: block;
      height: 0;
      margin-top: 0;
      margin-bottom: 0;
      overflow: hidden;
      box-sizing: border-box;
      transition:
        height 200ms,
        margin-top 200ms,
        margin-bottom 200ms;
      transition-delay: 0s;

      &.InfoToggleShown {
        height: 22px;
        margin-top: 5px;
        margin-bottom: 15px;
        transition-delay: 100ms;
      }
    }

    & .PropertyInfoTable {
      margin-bottom: 15px;
    }
  }

  & .SmallPropertyInfo {
    padding: 16px 16px 0;
  }

  & .ListingInfo {
    padding: 15px;
  }

  & .AlternateBrandingDesign {
    & .ListingInfo {
      margin-bottom: 0;
    }

    & .BrokerageAttribution {
      padding: 0 15px 15px;
    }
  }

  & .PDPRightRailCard {
    position: static;
    width: 350px;
    margin: 0 auto 25px;
    border-radius: 14px;
    transform: none;
    top: 0;
    box-shadow: none;
    border: none;
    padding: 0;
    margin-top: 0;
    transition:
      margin-top 0.3s,
      box-shadow 0.3s;
    transition-delay: 0.1s;

    &.PDPRightRailCardWithBoxShadow {
      margin-top: 25px;
      box-shadow:
        0 -1px 4px 0 rgb(0 0 0 / 7%),
        0 15px 14px -3px rgb(0 0 0 / 17%);
    }

    & .LenderCTA {
      width: 70%;
    }

    & .MortgageContent {
      margin-bottom: 15px;
    }

    & .ListingStatusSection {
      width: 175px;
    }
  }

  & .HomeownerUpsellAd {
    width: auto;
    padding: 23px 18px 70px;
    margin: 40px 18px;

    & .Title {
      font-size: 18px;
      margin-top: 10px;
      letter-spacing: normal;
      line-height: 25px;
    }

    & .Description {
      font-size: 14px;
    }

    & .CtaButton {
      min-height: 33px;
      height: 33px;
      max-width: 138px;
      width: 138px;
      padding: 0;
      font-size: 14px;
    }

    & .MobileIllustration {
      display: flex;
    }

    & .DesktopIllustration {
      display: none;
    }
  }

  & .ListingInfo .LicenseNumber {
    display: block;
  }

  & .MapPropertyPage {
    & .MapSection {
      margin-bottom: 0;
      height: 460px;
    }

    & .SectionDivider {
      display: block;
    }

    & .MapNullState {
      height: 100px;
      border: none;
      border-radius: 0;
      box-shadow: none;
      font-size: 14px;
    }
  }

  & .BreakoutSections {
    & .BreakoutSections {
      padding-right: 20px;
      margin-bottom: 40px;
      margin-top: 30px;
    }

    & .BreakoutSectionsInner {
      padding-right: 0;
    }
  }

  & .AvmBreakdown {
    position: relative;
    width: calc(100% - 20px);
    margin: 0 auto;
    transform: none;
    top: 0;

    & .Heading {
      display: block;
    }

    & .AvmLabel {
      font-size: 11px;
      font-weight: 200;
    }

    & .AvmSubtext {
      font-size: 11px;
      font-weight: 200;

      & .LearnMoreAside {
        font-size: 11px;
        font-weight: 200;
        margin-left: 12px;
      }
    }

    & .AvmFactorDescription {
      font-weight: 200;
    }

    & .AvmFactorsSection {
      padding: 0 20px 0 15px;
    }

    & .AvmFactorIcon {
      margin-right: 15px;
    }
  }

  & .HOCardWithBackgroundImage {
    max-width: 100%;
    margin: 0 !important;
    width: 100% !important;
  }
}
/* End overrides to PropertyPageMobleContents components */

@media (--tablet-screen) {
  .PropertyPageMobileContents {
    & .AvmSection {
      border-top-right-radius: 12px !important;
      border-top-left-radius: 12px !important;
    }

    & .OpenHouseInfo {
      padding: 0;
    }

    & .ListingInfo {
      padding: 0;
    }

    & .SectionDivider {
      display: none;
    }

    & .SmallPropertyInfo {
      padding: 10px 0;
    }

    & .AlternateBrandingDesign {
      & .BrokerageAttribution {
        padding: 15px 0;
      }
    }
  }
}
