@import './_constants.css';

.MultiSelect {
  position: relative;

  & .LockedComponentContainer {
    & .LockedContentContainer {
      left: 0;
    }
  }
}

/* hide label off screen but still make it visible for screen readers */
.MultiSelectGroupLabel {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.MultiSelectGroup {
  display: flex;
  flex-wrap: wrap;

  & .Checkbox {
    width: calc(50% - 15px);
  }

  & .CheckboxInput {
    border-radius: 4px;
    border: 0.0625rem solid #999;
  }
}
