.AvmLabel {
  font-family: inherit;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.AvmBreakdownAvmValue {
  font-family: inherit;
  font-weight: 300;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 33px;
}
