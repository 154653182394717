@value ch-blue from './_colors.css';

.ExternalLink {
  text-decoration: none;
  color: ch-blue;
  display: inline-flex;
  align-items: center;

  & .ExternalLinkIcon {
    margin: 0 0 0 6px;
    height: 12px;

    & svg {
      height: 12px;
    }
  }
}
