.DisclosureContainer {
  & .Disclosure {
    margin-top: 20px;
    color: #6d7278;
    font-size: 10px;
    font-weight: 300;
    display: flex;
    text-align: left;

    & .Asterisk {
      margin-right: 5px;
    }
  }

  & .Divider {
    border-bottom: 1px solid #fff;
    opacity: 0.22;
    margin: 20px 0;
  }
}
