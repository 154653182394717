.ShareButton {
  transform: translateY(-10px);

  & .CircleButton {
    width: 70px;
    height: 70px;
    overflow: hidden;
    position: relative;
  }

  & .ShareIcon svg {
    width: 23px;
    height: 23px;
  }
}
