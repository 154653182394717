@import './_breakpoints.css';

.PropertyBrief {
  font-size: 12px;
  font-weight: 300;
  white-space: nowrap;
  margin-block-start: 0;
  margin-block-end: 0;
  line-height: 1.4em;
  display: inline-flex;
}

.VerticalSeparator {
  margin: 0 6px;
  font-size: 0.8em;
  position: relative;
  bottom: 1px;
  border-color: #ddd;
}

@media (--tablet-screen-and-smaller) {
  .PropertyBrief { font-weight: 200; }
  
  .VerticalSeparator {
    margin: 0 5px;
    border-right: 1px solid #d7d7d7;
  }
}
