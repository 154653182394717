@import './_breakpoints.css';
@import './_constants.css';

.OpenHouseInfo {
  composes: details-container from './_common.css';
  margin: 30px 0;
  padding: 0;
}

.Heading {
  font-size: 12px;
  font-weight: 900;
}

.OpenHouseInfoTable {
  width: fit-content;
}

.OpenHouseInfoRow {
  display: flex;
  border-bottom: 1px solid #e9e9e9;
}

.OpenHouseItemDate {
  width: 120px;
  flex: none;
}

.OpenHouseTimesContainer {
  display: flex;
  flex-wrap: wrap;
}

.OpenHouseTime {
  width: 130px;
}

.OpenHouseItemDate,
.OpenHouseTime {
  display: flex;
  height: 25px;
  align-items: center;
  color: inherit;
  font-family: inherit;
  font-weight: 200;
  font-size: 12px;
}

.SeeMoreLink {
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 12px;
  font-weight: 200;
  margin-top: 10px;
  text-decoration: underline;
  padding: 0;
}

@media (--tablet-screen-and-smaller) {
  .OpenHouseInfoTable {
    width: 100%;
  }

  .OpenHouseInfo {
    padding: 0 15px;
  }

  .OpenHouseTimesContainer {
    flex-direction: column;
  }

  .OpenHouseItemDate {
    width: 90px;
  }

  .OpenHouseTime {
    width: 125px;
  }
}

@media (--xxx-small-screen) {
  .OpenHouseInfoTable {
    width: 100%;
  }

  .OpenHouseTimesContainer {
    flex-direction: column;
  }

  .OpenHouseTime {
    width: 105px;
  }
}
