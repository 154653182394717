@value text-color from './_colors.css';

.HeadingSection {
  display: flex;
  flex-direction: row;
  text-align: center;

  & .TypicalHomeFilledCircle {
    background-color: text-color;
    border-radius: 100%;
    width: 17px;
    height: 17px;
    margin-right: 8px;
  }

  & .Heading {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    white-space: nowrap;
  }
}

.PropertyDataContainer {
  padding: 10px 0;
}

.PropertyData {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
}

.VerticalSeparator {
  height: 12px;
  color: text-color;
  margin: 0 10px;
}
