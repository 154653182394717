@import './_breakpoints.css';
@import './_constants.css';

.HomeownerUpsellAd {
  border-radius: 10px;
  background-color: rgb(60 223 194 / 9%);
  position: relative;
  margin: 40px 0;
  padding: 25px 28px 29px;
  box-sizing: border-box;
  overflow: hidden;

  & .LockedComponentContainer {
    margin: 0;
    min-height: 44px;
    max-width: 205px;
    width: 205px;

    & .LockedContentButton {
      height: 100%;
      width: 100%;
      text-align: left;

      & .LockIcon {
        & svg {
          height: 30px;
          width: 30px;
        }
      }
    }

    & .CtaButton.disabled {
      width: 205px;
      max-width: 205px;
      opacity: 0.4;
      padding-left: 25px;
    }
  }
}

.Title {
  font-size: 24px;
  font-weight: 900;
  line-height: 33px;
  letter-spacing: -0.5px;
  margin-top: 16px;
}

.Description {
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 19px;
}

.HomeownerAdImgContainer {
  width: 100%;
  min-width: 340px;
  display: flex;
  justify-content: flex-end;
}

.HomeownerAdImg {
  width: 250px;
  height: auto;
  right: -30px;
  bottom: -5px;
  position: absolute;
}

.CtaButton {
  margin: 0;
  min-height: 44px;
  max-width: 167px;
  width: 167px;
  z-index: 1;
  position: relative;
}

@media (--tablet-screen) {
  .HomeownerAdImg {
    right: -137px;
    bottom: -32px;
    width: 250px;
    height: auto;
  }
}

@media (--xx-small-screen) {
  .HomeownerAdImg {
    right: -137px;
    bottom: -32px;
    width: 250px;
    height: auto;
  }
}

@media (--tablet-screen-and-smaller) {
  .HomeownerUpsellAd {
    & .LockedComponentContainer {
      & .CtaButton.disabled {
        min-height: 44px;
        width: 185px;
        max-width: 185px;
      }
    }
  }
}
