@import './_breakpoints.css';

.ClickableLogoWrapper {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
}

.ClickableLogo {
  display: block;
  position: relative;
  top: 2px;
  cursor: pointer;
}

.ClickableTextLogo {
  composes: strip-button-styles from './_common.css';
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
  color: inherit;
  font-size: 12px;
  font-weight: 900;
}

@media (--small-screen) {
  .CobrandedHeaderLogo {
    width: 200px;
  }

  .ClickableLogo {
    margin: 0;
  }

  .ClickableLogo svg {
    /* Prevent overflowing flex container */
    height: 20px;
    max-width: 100%;
    width: auto !important;
  }
}
