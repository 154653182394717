@import './_breakpoints.css';
@import './_constants.css';

.UpdateEmailBanner {
  box-sizing: border-box;

  & .DismissibleBanner {
    box-shadow: none;
    padding: 0;
    z-index: 103;
  }

  & .DismissibleBanner .Window {
    width: 100%;
    padding: 8px 0;
  }

  & .DismissibleBanner .Content {
    height: 60px;
    width: 100%;
    padding: 0 20px 0 33px;
  }

  & .UpdateEmailBannerContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
  }

  & .EmailIcon .EmailIcon-svg {
    width: 30px;
    height: 27px;
  }

  & .BannerText {
    font-size: 14px;
    font-weight: 300;
    margin-left: 30px;
  }

  & .UpdateNow {
    cursor: pointer;
    font-weight: 700;
    text-decoration: underline;
  }
}

@media (--larger-than-tablet-screen) and (max-width: 1450px) {
  .UpdateEmailBanner {
    & .DismissibleBanner .Window {
      padding: 8px 0;
    }

    & .DismissibleBanner .Content {
      max-width: 100%;
      padding: 0 16px;
    }
  }
}

@media (--tablet-screen) {
  .UpdateEmailBanner {
    & .DismissibleBanner .Content {
      width: 95%;
      max-width: auto;
      margin: 0;
      padding: 0 16px;
    }
  }
}

@media (--small-screen) {
  .UpdateEmailBanner {
    & .DismissibleBanner {
      height: 45px;

      & .Window {
        padding: 8px 0;
      }

      & .Content {
        height: auto;
        width: 100%;
        margin: 0;
        padding: 0 16px;
      }
    }

    & .BannerText {
      font-size: 11px;
      margin-left: 0;
    }
  }
}
