.ContactNewAgentButton {
  font-size: 14px;
  font-weight: 500;
  height: 44px;
  min-height: 44px;
}

.ContactNewAgentButtonWrapper {
  width: 100%;
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
}
