@import './_breakpoints.css';
@import './_constants.css';

.PageBody {
  overflow: visible;
}

.PropertyDetailsPage {
  -webkit-overflow-scrolling: touch;
  position: relative;
  width: 100%;
}

.LoadingSection {
  min-height: calc(100vh - var(--header-height));
}

@media (--larger-than-tablet-screen) {
  .TabletScreenContents,
  .SmallScreenContents {
    display: none;
  }
}

@media (--tablet-screen) {
  .LargeScreenContents,
  .SmallScreenContents {
    display: none;
  }
}

@media (--small-screen) {
  .LargeScreenContents,
  .TabletScreenContents {
    display: none;
  }

  .PropertyDetailsPage {
    /* Applying this to the desktop page breaks the .AvmBreakdown's position: sticky */
    overflow-x: hidden;
  }
}
