.LoadingSection {
  position: relative;
}

.SpinnerWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: inherit;

  & .Spinner {
    display: flex;
    align-items: center;
    height: 100px;
    max-height: 60%;
    max-width: 60%;
    margin: auto;
  }

  & svg {
    display: block;
    height: 100%;
    width: 100%;
    animation: spin 4s linear infinite;

    & circle {
      stroke-dashoffset: 0;
      stroke-dasharray: 300;
      stroke-width: 5.334%;
      stroke-miterlimit: 10;
      stroke-linecap: round;
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-animation: circle-spin 3s linear infinite;
      animation: circle-spin 3s linear infinite;
      stroke: #4a4a4a;
      fill: transparent;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}

@keyframes circle-spin {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -600;
  }
}
