@import './_constants.css';
@value text-color, ch-blue from './_colors.css';

:root {
  --ease-timing: 60ms;
}

.MobileFiltersModalContainer {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  font-weight: 300;
  z-index: 6;
}

.BgScreen {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.MobileFiltersModal {
  width: 100vw;
  max-width: 100vw;
  position: absolute;
  /* Room for .ActionButtonText */
  bottom: 10px;
  background: transparent;
  box-shadow: none;
  box-sizing: border-box;
}

.MobileFiltersModal .Body {
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px 10px;
  margin: 0 auto;
  max-width: 460px;
}

.overlay {
  background: transparent;
}

.CurrentFilters,
.FilterSelector {
  box-shadow: 0 1px 11px 1px rgb(0 0 0 / 12%);
  border-radius: 14px;
}

.CurrentFilters {
  overflow: hidden;
  margin-bottom: 9px;
}

.Heading,
.FilterSelector {
  display: flex;
  align-items: center;
}

.Heading,
.FilterRow {
  padding-left: 20px;
  padding-right: 30px;
}

.FilterSelector {
  margin-bottom: 18px;
  flex-grow: 1;

  &.FilterSelectorExpanded {
    overflow: hidden;
  }
}

.Heading {
  font-size: 18px;
  justify-content: space-between;
  background: rgb(255 255 255 / 70%);
  height: var(--columnTitleHeight);
}

.Title {
  font-size: 18px;
  font-weight: 200;
  margin: 0;
}

.Results {
  font-size: 12px;
}

.FilterSelector,
.FilterRow {
  min-height: 57px;
}

.FilterRowWrapper {
  display: flex;
  border-top: 1px solid #eee;
}

.FilterRow {
  background: none;
  border: none;
  color: inherit;
  text-align: left;
  display: flex;
  flex-grow: 2;
  align-items: center;
  cursor: pointer;
  position: relative;
  /* Needed to show focus when tabbing through filters */
  outline-offset: -5px;
}

.CurrentFilters .FilterRow {
  /* background: #fff; */
}

.FilterTitle {
  width: 100px;
  min-width: 100px;
  font-size: 14px;
  margin-right: 12px;
}

.ActiveFilterPill {
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  align-items: center;
  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: calc(100% - 158px);
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2rem;
  cursor: pointer;
}

.FilterList {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
  background: #fff;
  /* z-index prevents overflow of child borders: */
  z-index: 0;
  position: relative;
}

.FilterOptionCloseWrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FilterOptionCloseButton {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  width: 24px;
  height: 24px;

  & svg {
    transform: scale(0.75);
  }
}

.AddFilterButton {
  justify-content: center;
  font-size: 16px;
  padding: 18px 0;
  background: #fff;
  border-radius: 14px;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  border: 0;
}

.ActionButtons {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.ActionButtons .ActionButtonText {
  font-size: 11px;
  position: absolute;
  bottom: -12px;
  width: 100px;
  text-align: center;
  white-space: nowrap;
}

.ResultsBtn .ActionButtonText {
  left: -30px;
}

.BackBtn,
.ResultsBtn {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
}

.BackBtn,
.ResultsBtn,
.SaveFiltersButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.SaveFiltersButtonContainer.LoadingState {
  display: block;
  width: 50px;
}

.circle {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 7px 1px rgb(0 0 0 / 13%);
  margin-bottom: 5px;
}

.BackBtn .circle {
  background: #fff;
}

.ResultsBtn .circle {
  background: text-color;
}

.BackBtn .ArrowIcon,
.ResultsBtn .ArrowIcon {
  height: 17px;
  width: 17px;
}

.ResultsBtn .ArrowIcon {
  color: #fff;
  transform: rotate(180deg);
}

.PlusIcon {
  display: inline-block;
  height: 14px;
  width: 14px;
  vertical-align: top;
  position: relative;
  margin-right: 10px;
  bottom: 2px;
}

.PlusIcon-svg {
  transform: rotate(45deg) scale(0.7);
}

.SaveSearchButton {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  height: 44px;
  width: 167px;
  cursor: pointer;
  box-shadow: 0 15px 14px -3px rgb(0 0 0 / 17%);
  transition:
    box-shadow var(--ease-timing) ease-in-out,
    transform var(--ease-timing) ease-in-out;

  & .FlatButtonLabel,
  .FlatButtonIcon {
    margin-top: 3px;
  }

  & .FlatButtonIcon {
    margin-left: -6px;
  }

  & .SaveSearchText {
    position: relative;
    font-weight: 900;
    top: -2px;
    left: 3px;
    color: #fff !important;
  }
}

.SaveSearchButton,
.SaveSearchButton:hover {
  background-color: text-color;
  transition:
    box-shadow var(--ease-timing) ease-in-out,
    transform var(--ease-timing) ease-in-out;
}

.SaveSearchButton[disabled],
.SaveSearchButton[disabled]:hover,
.SaveSearchButton[disabled]:focus {
  box-shadow: 0 15px 14px -3px rgb(0 0 0 / 0%);
  transition:
    box-shadow var(--ease-timing) ease-in-out,
    transform var(--ease-timing) ease-in-out;
}

.HeartIcon {
  & svg.HeartIcon-svg {
    height: 16px;
    width: 23px;
    fill: #fff;
    stroke: #fff;

    path {
      fill: inherit;
      stroke: inherit;
    }
  }
}

.SavedSearchButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: -13px;
  left: 0;

  & .SavedText {
    color: #414042;
    font-size: 12px;
    position: relative;
    top: -3px;
  }
}

.GreenCheckIcon {
  & svg.GreenCheckIcon-svg {
    width: 28px;
    height: 28px;
  }
}

.SaveFiltersButtonContainer .LoadingSection {
  width: 50px;
  height: 50px;
}

.BuyingPower {
  position: absolute;
  bottom: calc(100% + 15px);
  left: 15px;
  width: 100vw;
  padding: 0 10px;
  box-sizing: border-box;

  & .BuyingPowerValue {
    font-size: 16px;
    margin-bottom: 5px;
  }

  & .BuyingPowerSubtext {
    font-size: 12px;
  }
}

:export {
  headerAndControlsAndTitleCombinedHeight: var(
    --headerAndControlsAndTitleCombinedHeight
  );
  columnTitleHeight: var(--columnTitleHeight);
  headerAndControlsCombinedHeight: var(--headerAndControlsCombinedHeight);
}
