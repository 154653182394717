@import './_breakpoints.css';
@import './_constants.css';
@value rounded-border, box-shadow-property-card from './_fontsAnimations.css';

.PropertyCard {
  min-height: var(--propertyCardHeight);
  width: var(--propertyCardWidth);
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

  &:only-child {
    width: var(--propertyCardWidth);
  }

  & .Clickable {
    cursor: pointer;
  }
}

.PropertyCardInner {
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  width: calc(var(--propertyCardWidth) - 15px);
  height: 100%;
  border-radius: rounded-border;
  background-color: white;
  border: 1px solid #f0f0f0;
  margin-right: 15px;
  overflow: hidden;
  transition:
    box-shadow 0.2s,
    transform 0.2s;

  &:hover {
    box-shadow: box-shadow-property-card;
  }
}

.PropertyCard .PropertyCardPhoto {
  height: var(--propertyCardPhotoHeight);
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  z-index: 1;
}

.InActiveHeartIconOnLoad {
  position: absolute;
  z-index: 2;
  background-color: #e9e9e9;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  right: 15px;
  top: 130px;

  & span {
    width: 25px;
    height: 25px;
  }

  & span > svg {
    width: 25px;
    height: 25px;
  }
}

.WatchListButtonPositioner {
  position: absolute;
  top: calc(var(--propertyCardPhotoHeight) - 56px);
  right: 29px;
  z-index: 2;
}

.MobileMapCloseButton {
  background: none;
  border: none;
  padding: 0;
  top: 8px;
  right: 8px;
  z-index: 2;
  position: absolute;

  & .MobileMapCloseIcon {
    & svg :global .element-to-fill {
      fill: #fff;
    }
  }
}

@media (--small-screen) {
  .PropertyCardInner {
    width: calc(var(--propertyCardWidth));
    margin-right: 0;
  }

  .PropertyCardInner:hover {
    box-shadow: none;
  }
}

@media (--x-small-screen) {
  .PropertyCardInner,
  .PropertyCard,
  .PropertyCard:only-child {
    margin-right: 0;
    width: 100%;
  }
}

/* Fixes IE11 height issue */
/* stylelint-disable-next-line selector-no-vendor-prefix */
_:-ms-fullscreen,
:root .PropertyCard {
  height: var(--propertyCardHeight);
}

:export {
  cardHeight: var(--propertyCardHeightWithSpacing);
  cardWidth: var(--propertyCardWidth);
}
