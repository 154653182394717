@import './_breakpoints.css';
@import './_constants.css';

.HeaderTopRowContainer {
  width: 100%;
}

.HeaderTopRow {
  box-sizing: border-box;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--header-chase-left-padding);
  max-width: var(--header-max-width-chase);
  margin: auto;
  width: 100%;

  &.DesktopInnerContainerFullWidth {
    max-width: initial;
    padding-left: var(--header-chase-left-padding);
  }
}

.OverviewLink {
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  /** To center Chase MyHome logo */
  position: absolute;
  background: none;
  border: none;
  padding: 0;
}

.OverviewLink:hover {
  cursor: pointer;
}

.ChevronIconOverview {
  position: relative;
  top: 3px;

  & .ChevronIconOverview-svg {
    height: 16px !important;
    width: 16px !important;
  }

  & svg :global .element-to-fill {
    fill: #fff;
  }
}

.MyHomeLogoContainer {
  margin: 0 auto;
}

.MyHomeLogo .MyHomeLogo-svg {
  height: 22px;
  width: 161px;
}

@media (--small-screen) {
  .OverviewLinkText {
    display: none;
  }

  .ChevronIconOverview {
    position: relative;
    top: 0;

    & .ChevronIconOverview-svg {
      height: 20px !important;
      width: 20px !important;
    }
  }
}
