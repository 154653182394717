@import './_breakpoints.css';
@value ch-electric-blue, ch-black from './_colors.css';

.ChartContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
}

.Chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 500px;
}

.Label {
  font-size: 24px;
  font-weight: 700;
}

.LabelSubText {
  font-size: 14px;
  font-weight: 300;
  stroke-width: 0.2;
}

.DeepDiveComponentSummary {
  color: ch-black;
  font-size: 14px;
  margin: 10px;
  padding: 10px 0;
}

.ChartLegendItem {
  font-size: 12px;
  display: flex;
  flex-direction: row;
}

.withFactor,
.NoFactor {
  width: 17px;
  height: 17px;
  border-radius: 100%;
  margin-right: 10px;
}

.withFactor {
  background-color: ch-electric-blue;
}

.NoFactor {
  background-color: #e5e5e5;
}

@media (--small-screen) {
  .Chart {
    height: 100%;
    width: 100%;
  }
}
