@import './_breakpoints.css';

.PDPRightRailCard {
  width: 352px;
  border: 1px solid #f0f0f0;
  border-radius: 14px;
  background-color: #fff;
  box-shadow: 0 22px 19px -3px rgb(0 0 0 / 17%);
  box-sizing: border-box;
  transform: translate(0, -40px);
  overflow: hidden;
  position: sticky;
  top: var(--topSpacing, 126px); /* --topSpacing is set by JS */
  transition: top 300ms;
  /* z-index is needed to ensure that the box-shadow overlaps other sections on the page */
  z-index: 1;

  & .ContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 25px;
    /* For search page PDP mobile card expanding to full PDP */
    transition: padding 0.2s;
  }

  &.PDPRightRailCardWithMonthlyPaymentDetails .ContentWrapper {
    padding: 30px 0 10px;
  }

  &.PDPRightRailCardWithHiddenShareAndSave .ShareAndSavePropertySection {
    display: none;
  }
}

.ChevronIconButton {
  position: relative;
  top: 3px;
  margin-left: 8px;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
}

.ChevronIcon {
  .ChevronIcon-svg {
    transform: rotate(270deg);
    height: 18px;
    transition: transform 100ms;
  }
}

.active {
  .ChevronIcon-svg {
    transform: rotate(90deg);
    transition: transform 100ms;
  }
}

.MortgageInfo {
  width: 95%;
  margin-bottom: 10px;
  will-change: contents, opacity, height;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.MortgageContent {
  margin-top: 15px;
  width: 95%;
}

.ListingStatusSectionWrapper {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.ListingStatusSection {
  width: 65%;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .ListingStatus {
    font-size: 15px;
    font-weight: 900;
    white-space: nowrap;
  }

  & .RentalEstimate {
    font-size: 12px;
    margin-top: 10px;
    font-weight: normal;
    display: flex;
  }

  & .RentalEstimateLabel {
    white-space: pre-wrap;
  }

  & .MonthlyPaymentsSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;

    & .Label {
      font-size: 11px;
      font-weight: 300;
      margin-right: 10px;
    }
  }
}

.ListingStatusSection.ListingStatusWithNoEstimatedPayment {
  width: auto;
  margin-bottom: 12px;

  & .ListingStatus {
    font-size: 18px;
  }
}

.HorizontalSeparator {
  border-bottom: 2px solid #e9e9e9;
  margin: 5px 0;
}

/* stylelint-disable-next-line no-descending-specificity */
.ShareAndSavePropertySection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 15px;
  width: 100%;

  & .IconCtaButton,
  & .RequestATourButton,
  & .WatchListActionButton,
  & .ShareButtonContainer {
    height: 75px;
    font-size: 12px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & .ShareButton {
      transform: none;

      & .HeartIcon svg {
        transform-origin: top center;
        width: 22px;
        height: 20px;
      }
    }

    & .CircleButton {
      cursor: pointer;
      height: 56px;
      width: 56px;
      transform: translateY(-10px);
    }

    & .ButtonLabel {
      font-weight: 300;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      display: block;
    }
  }

  & .IconCtaButton,
  & .RequestATourButton,
  & .WatchListActionButton {
    border-right: 1px solid #f0f0f0;
  }
}

.MortgageDefaultsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  line-height: 14px;
  width: 100%;

  & .VerticalSeparator {
    height: 57px;
    margin: 0 8px;
    border-right: 1px solid #e9e9e9;
  }

  & .MortgageDefault {
    & .Value {
      font-size: 24px;
      font-weight: 900;
      text-align: center;
    }

    & .Label {
      font-size: 10px;
      font-weight: 900;
      margin-top: 5px;
      display: flex;
      justify-content: center;
    }
  }
}

.AdditionalPropertyDetails {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 95%;

  & .PropertyDetailsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    & .Label,
    & .Value {
      font-size: 10px;
      font-weight: 300;
    }

    & .Label {
      text-align: left;
    }

    & .Value {
      text-align: right;
    }
  }
}

.MLSAttribution {
  font-size: 9px;
  font-weight: 200;
  /* 12px spacing below the bottom of the price section, 10px spacing above the bottom of the right rail
   * card when the mobile PDP is expanded */
  margin: 12px 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: #6d7278;
}

.PDPFinanceCTAContainer {
  width: 352px;
}

.CTAWrapper {
  position: relative;
  width: 100%;
}

.disabled {
  filter: blur(2px);
  opacity: 0.7;
}

@media (--small-screen) {
  /* In this case, the Finance CTA (when enabled via feature flag) is already shown in PropertyPageMobileContents */
  .PDPFinanceCTAContainer {
    display: none;
  }
}
