@import './_breakpoints.css';
@import './_constants.css';

.MobileBottomNav {
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--mobileBottomNavHeight);
  padding-top: 12px;
  /* Above both .SearchPageMap and .SearchPageList */
  z-index: 3;
  background-color: white;
  box-sizing: border-box;
  width: 100vw;
  -webkit-tap-highlight-color: transparent;
}

.NavButton {
  flex: 1;
  text-align: center;
  padding: 0;
  border: none;
  background: none;
  color: inherit;

  & .Trigger {
    margin: auto;
  }
}

.MenuNotificationCount {
  position: absolute;
  display: inline;
  right: -15px;
  top: -6px;
}

.NavButtonIconContainer {
  position: relative;
  display: inline;
}

.NavButtonIcon svg {
  height: 16px;
}

.NavButtonLabelText {
  padding-bottom: 3px;
}

.NavButtonLabel {
  font-size: 11px;
  display: block;
}

.NavButtonSearch,
.NavButtonSettings {
  display: none;
}

@media (--tablet-screen) {
  .NavButtonMap,
  .NavButtonList {
    display: none;
  }

  .NavButtonSearch {
    display: block;
  }

  .NavButtonSettings {
    display: block;
  }
}

:export {
  navHeight: var(--mobileBottomNavHeight);
}
