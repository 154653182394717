@import './_breakpoints.css';
@import './_constants.css';
@value text-color from './_colors.css';
@value rounded-border from './_fontsAnimations.css';

/* Carousel */
.CarouselRow {
  composes: row from './_common.css';
  width: 100%;
  max-width: 1440px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.LoadingSection,
.Slider {
  width: 100%;
}

.CarouselRow,
.LoadingSection,
.Slider,
.SliderItem {
  box-sizing: border-box;
  height: var(--pdpCarouselHeight);
}

.SliderItem {
  padding: 0 1px;
}

.Photo {
  width: auto;
  height: 100%;
  cursor: pointer;
}

.Dialog {
  max-width: 345px;
  border-radius: rounded-border;
}

.DarkText {
  color: text-color !important;
}

.FullWidthSliderItem {
  width: 100vw;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.SinglePhoto {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.LoginUpsellMessage {
  padding: 30px 40px;
  background-color: rgb(0 0 0 / 50%);
  border-radius: 16px;
  color: white;
  text-align: center;
  z-index: 1;

  & button {
    font-weight: 900;
    cursor: pointer;
    text-decoration: underline;
    background: none;
    border: none;
    padding: 0;
    color: inherit;
    font-size: inherit;
  }
}

.LoginUpsell {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 80px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 28px;

  &.LoginUpsellFullWidth {
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 70px;
    font-size: 14px;
    line-height: 20px;

    & .LoginUpsellMessage {
      padding: 30px;
    }
  }
}

.Overlay {
  position: absolute;
  height: 32px;
  width: 64px;
  bottom: 6px;
  left: 6px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  /* Above .Slider .SliderTrackWrapper */
  z-index: 2;
}

@media (--small-screen) {
  .CarouselRow,
  .LoadingSection,
  .Slider,
  .SliderItem {
    height: var(--propertyCardPhotoHeight);
  }

  .SliderItem {
    padding: 0;
  }

  .CarouselRow {
    width: 100%;

    & .SliderArrow.SliderArrow--left {
      transform: scale(0.6);
    }

    & .SliderArrow.SliderArrow--right {
      transform: scale(0.6) rotate(180deg);
    }

    & .LoadingSection svg {
      height: 60px;
    }
  }
}

:export {
  carouselHeight: var(--pdpCarouselHeight);
  carouselMobileHeight: var(--propertyCardPhotoHeight);
}
