@import './_breakpoints.css';

.LegendItemColor {
  line-height: 14px;
  text-align: center;
  flex: 1 1;
  position: relative;
  height: 6px;
}

.LegendItemColorHalftone {
  height: 16px !important;
}

.LegendItemColorTile {
  opacity: 0.7;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.LegendItemColor:first-of-type > div {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.LegendItemColor:last-of-type > div {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.LegendItemColor > div:first-child {
  overflow: hidden;
}

@media (--xxx-small-screen) {
  .LegendItemColorSmall {
    max-width: 16px !important;
  }
}
