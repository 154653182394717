@import './_constants.css';

.PropertyCardValueInline {
  text-align: left;

  & .ShowDetailsCTA {
    font-size: 14px;
    font-weight: 500;
    margin-top: 4px;
    margin-right: 5px;
    color: white;
    white-space: nowrap;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    height: fit-content;
  }
}

.BottomLabel {
  font-size: 9px;
  color: #6d7278;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(var(--propertyCardWidth) - 30%);
  margin-top: 15px;
  font-weight: 200;
}

.ValueLabel {
  margin-right: 6px;
}

.ValueLabel,
.Value {
  font-weight: 100;
  font-size: 20px;
  display: inline-block;
}

.ShowDetailsAndChevronIcon {
  display: flex;
  flex-direction: row;
}

.ChevronIcon {
  position: relative;
  top: 5px;
}

.ChevronIcon-svg {
  transform: rotate(182deg);
  height: 18px;
}
