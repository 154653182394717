@import './_breakpoints.css';
@value text-color, ch-black from './_colors.css';

.ValueComparison {
  text-align: center;
  display: flex;
  width: 100%;
  margin-top: 56px;
  margin-bottom: 50px;
  padding: 0 20px;
}

.ValueRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.ValueColumn {
  text-align: left;
  position: relative;
  font-weight: 600;
  color: text-color;
}

.ValueColumn + .ValueColumn {
  margin-left: 20px;
}

.ValueColumn:first-of-type {
  font-weight: 300;
}

.ValueText,
.Value {
  font-size: 16px;
}

.ValueText {
  display: inline-block;
}

.Value {
  position: relative;
  display: inline-block;
  margin-left: 24px;
}

.Arrow {
  color: text-color;
  position: absolute;
  right: -32px;
  bottom: 4px;
}

.Arrow,
.Arrow-svg {
  height: 21px;
  width: 21px;
}

.Arrow.Up {
  transform: rotate(90deg);
}

.Arrow.Down {
  transform: rotate(-90deg);
}

@media (--small-screen) {
  .ValueRow {
    flex-direction: row-reverse;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .ValueColumn {
    padding: 0 0 0 28px;
    margin: 31px 0 44px;
  }

  .ValueColumn + .ValueColumn {
    border: 0;
    padding: 0 28px 0 0;
  }

  .Arrow {
    position: absolute;
    right: -26px;
    bottom: 8px;
  }

  .Arrow,
  .Arrow-svg {
    height: 16px;
    width: 16px;
  }
}
