@import './_breakpoints.css';

.ListingInfo {
  font-size: 12px;
  font-weight: 300;
  position: relative;
  width: 100%;
  min-width: 320px;
  margin: 30px auto;
  box-sizing: border-box;
  line-height: 18px;

  & hr {
    border: 0;
    border-bottom: 1px solid #f0f0f0;
    margin: 15px 0;
  }
}

.Heading {
  font-size: 12px;
  font-weight: 900;
  margin-bottom: 8px;
}

.ContactInfo .name {
  font-weight: 600;
}

.ListingNotes {
  margin-top: 16px;
}

.AgentName {
  font-weight: 600;
}

.MLSLogoSmall {
  display: inline-block;
  margin: 5px 0 -3px 8px;
  height: 15px;
}

.CompensationDisplayDisclaimer {
  font-size: 10px;
}

@media (--small-screen) {
  .ListingInfo {
    margin-top: 20px;
  }

  .Heading {
    margin-top: 0;
  }
}
