@value border-color, lighter-grey, text-color from './_colors.css';

.DropdownContainer {
  position: relative;
  color: text-color;
  cursor: pointer;
  width: 100%;

  & .DropdownListButton {
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px 0 10px;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid text-color;
    margin: 0;
    height: 45px;

    & span {
      display: block;
    }

    & .DropdownButtonText {
      font-size: 14px;
      font-weight: 500;
      max-width: 90%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .DropdownButtonText.DropdownButtonTextNoSelection {
      font-weight: 200;
    }

    & .DropdownButtonIcon {
      width: 7px;
      height: 7px;
      border-right: 2px solid text-color;
      border-bottom: 2px solid text-color;
      transform: rotate(45deg);
    }
  }

  & .DropdownList {
    /* Need to add 1px from the border of DropdownListButton  to make the dropdown list properly overlay on the button*/
    width: calc(100% + 1px);
    position: absolute;
    top: 0;
    padding: 0;
    background-color: #fff;
    border-radius: 2px;
    list-style: none;
    margin: 0;
    z-index: 100;
    color: text-color;
    max-height: 200px;
    overflow-y: scroll;
    box-shadow: 0 0.125rem 0.125rem 0 rgb(0 0 0 / 14%),
      0 0.1875rem 0.0625rem -0.125rem rgb(0 0 0 / 20%),
      0 0.0625rem 0.3125rem 0 rgb(0 0 0 / 12%);

    & .Active {
      background-color: hover-grey;
      border: 1px solid hover-grey;
      font-weight: 900;
    }
  }

  & .DropdownList.ExpandUpwards {
    top: auto;
    bottom: 0;
  }

  & .DropdownListOption {
    cursor: pointer;
    overflow: hidden;
    padding: 10px;
    font-size: 14px;
  }

  & .DropdownListOption.Focused {
    background-color: lighter-grey;
  }

  & .DropdownListOption[aria-selected='true'] {
    background-color: lighter-grey;
  }

  & .DropdownListOption:hover {
    background-color: lighter-grey;
  }

  & .DropdownListOption:focus {
    background-color: lighter-grey;
  }
}
