@import './_constants.css';
@value text-color from './_colors.css';

.CheckboxInput {
  margin-right: 10px;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* stylelint-enable property-no-vendor-prefix */
  appearance: none;
  border-radius: 0.1875rem;
  height: var(--checkboxInputHeight);
  width: var(--checkboxInputWidth);
  border: 0.0625rem solid text-color;
  background-color: #fff;
  cursor: pointer;
}

.CheckboxLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 37px;
  color: inherit;
}

.Checkbox {
  display: flex;
  min-width: calc(100% / 3);
  white-space: nowrap;
  padding-right: 10px;
  cursor: pointer;
  align-items: center;

  &:hover {
    & .CheckboxInput {
      transform: scale(1.05);
    }

    & .CheckboxLabel {
      cursor: pointer;
    }
  }
}

.OffScreenLabel {
  composes: hide-off-screen from './_common.css';
}

.CheckboxInputWrapper {
  position: relative;
  display: flex;
}

.CheckboxCheck {
  position: absolute;
  height: 9px;
  width: 4px;
  border-right: 0.1rem solid #fff;
  border-bottom: 0.1rem solid #fff;
  top: 8px;
  left: 11px;
  transform: rotate(45deg);
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.LockedComponentContainer {
  & .LockedContentContainer {
    & .LockedContentButton {
      display: flex;
      justify-content: flex-start;
      margin-right: 10px;

      & .LockIcon {
        display: inline-block;
        width: calc(var(--checkboxInputWidth) + 2px);
        height: calc(var(--checkboxInputHeight) + 2px);
        margin-left: -2px;

        & svg {
          width: var(--checkboxInputWidth);
          height: var(--checkboxInputHeight);
        }
      }
    }
  }
}
