@import './_breakpoints.css';
@import './_constants.css';
@value text-color from './_colors.css';

.AvmDeepDivesModal .Modal {
  width: calc(calc(var(--deepDiveComponentWidth) + 25px) * 2 + 155px);
  border-radius: 14px;

  & .DialogContent {
    padding: 0;
  }

  & .CloseIcon {
    color: inherit;
  }
}

.LoadingSection {
  min-height: 400px;

  & .SpinnerSection {
    transform: translateY(-20px);
  }
}

.DeepDiveComponent {
  width: var(--deepDiveComponentWidth);
  margin: 0 25px;
}

.DeepDiveComponentHeaderSection {
  background-color: #f7f7f7;
  padding: 20px;
  font-size: 16px;
  font-weight: 600;
  margin-block: 0;
}

.DeepDiveComponentSummary {
  color: #20203f;
  font-size: 14px;
  margin: 10px 10px 10px 20px;
  padding: 10px 0;
  min-height: 51px;
}

.ComponentContainer {
  height: 500px;
  width: var(--deepDiveComponentWidth);
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  box-sizing: border-box;

  &.NoDataContainerStyle {
    display: flex;
    justify-content: center;
  }
}

.HorizontalSeparator {
  margin: 16px 0 22px;
}

.SliderWrapper {
  margin: 0 40px;
}

.Slider,
.SliderItem {
  height: var(--deepDiveComponentHeight);
}

.DeepDiveComponentsContainer:nth-child(odd) {
  margin-right: 50px;
}

.Slider .SliderArrow {
  & svg {
    color: text-color;
  }

  &.SliderArrow--right {
    transform: rotate(180deg) translateX(-50px);
  }

  &.SliderArrow--left {
    transform: translateX(-50px);
  }

  & :global svg .element-to-fill {
    fill: black;
  }
}

.HeadingContainer {
  margin-bottom: 15px;
  color: #20203f;
  
  & .Heading {
    font-size: 20px;
    font-weight: 900;
    margin-block: 0;
    text-align: left;
  }
}

.ValueColumn {
  padding-left: 22px;
}

.Screenreader {
  composes: hide-off-screen from './_common.css';
}

.AvmDeepDivesModal .Address,
.AvmDeepDivesModal .PropertyBrief {
  font-size: 14px;
  font-weight: 300;
}

.AvmDeepDivesModal .Address {
  margin-top: 2px;
}

@media screen and (max-width: 1250px) {
  .AvmDeepDivesModal .Modal {
    width: calc(var(--deepDiveComponentWidth) + 155px);
  }

  .DeepDiveComponentsContainer:nth-child(odd) {
    margin-right: 0;
  }
}

@media (--small-screen) {
  .HeadingContainer {
    & .Heading {
      font-size: 17px;
      font-weight: 200;
      text-align: center;
    }
  }

  .AvmDeepDivesModal .Address,
  .AvmDeepDivesModal .PropertyBrief {
    font-size: 10px;
    text-align: center;
  }

  .AvmDeepDivesModal .Address,
  .AvmDeepDivesModal .PropertyBrief  h2 {
    font-size: 10px;
    font-weight: 300;
    white-space: normal;
  }

  .DeepDiveComponentSummary {
    margin: 10px 0;
    padding: 0;
    min-height: auto;
  }

  .ComponentContainer.NoDataContainerStyle {
    height: auto;
  }
}
