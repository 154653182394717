@import './_breakpoints.css';
@import './_constants.css';
@value border-color from './_colors.css';
@value box-shadow-soft from './_fontsAnimations.css';
@value border-color-lightest from './_colors.css';

:root {
  --ch-desktop-header-top-row-height: 71px;
  --ch-mobile-header-top-row-height: 48px;
  --ch-mobile-hamburger-menu-width: 272px;
}

.DesktopContainer {
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .DesktopHeaderTopRow {
    height: var(--ch-desktop-header-top-row-height);
    padding: 0 33px 0 20px;
  }
}

.MobileContainer {
  display: none;
  width: 100%;
  margin: auto;
}

.DesktopSearchContainer {
  box-sizing: border-box;
  width: 100%;
  max-width: 375px;
  margin: 0 26px;
}

.DesktopLogoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 182px;

  & .ClickableLogoByHouseCanary {
    height: 40px;
  }

  &.OnRight {
    margin-left: 30px;
  }
}

.DesktopRightContainer {
  width: auto;
  display: flex;

  &.DesktopRightContainerWithSearchField {
    max-width: 1000px;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }

  &.WithBackArrow {
    max-width: 380px;
  }
}

.button {
  min-width: 124px;
  padding-left: 5px;
  padding-right: 5px;
}

/* stylelint-disable-next-line selector-no-vendor-prefix */
_:-ms-fullscreen,
:root ul.Results {
  top: 3.4rem;
}

.DesktopHeaderBottomRowLeftContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #545454;
  font-size: 14px;
  font-weight: 200;

  & .DesktopNavItemContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  & .DesktopBottomRowIconElement {
    border: none;
    background: none;
    padding: 5px;
    cursor: pointer;
    margin-left: 30px;
    z-index: 2;

    & .SearchIcon svg {
      height: 18px;
      width: 23px;
    }

    & .SearchCloseIcon svg {
      display: block;
      height: 18px;
      width: 23px;
    }
  }

  & .DesktopNavItem {
    padding: 5px;
    margin-right: 2.5em;
    cursor: pointer;
    font-weight: 900;

    &:last-child {
      margin-right: 0;
    }
  }

  & .DesktopNavItemSelected {
    padding: 5px;
    margin-right: 2.5em;
    cursor: pointer;
    color: #5646ef;
    font-weight: 900;
  }

  & .MenuNotificationCount {
    position: absolute;
    top: -5px;
    left: 40px;
  }

  & .DesktopNavItem:hover {
    color: #5646ef;
  }

  & .AutoComplete {
    width: 350px;
    margin-right: -70px;
    margin-left: 40px;

    & .InputElement,
    & .Results {
      border-color: border-color;
    }

    & .InputElement {
      padding-right: 40px;
    }
  }

  & .SearchIcon:hover,
  & .DesktopBottomRowIconElement:hover {
    & :global .element-to-stroke {
      stroke: #5646ef;
    }

    & :global .element-to-fill {
      fill: #5646ef;
    }
  }
}

/* stylelint-disable no-descending-specificity */
.DesktopHeaderTopRow,
.DesktopHeaderBottomRowInnerContainer {
  max-width: var(--header-max-width);
  width: 100%;
  padding: 0 20px;
  margin: auto;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;

  &.DesktopInnerContainerFullWidth {
    max-width: initial;

    & .DesktopHeaderBottomRowLeftContainer:first-child {
      margin-left: 100px;
    }
  }
}
/* stylelint-enable no-descending-specificity */

.DesktopMenuScreen {
  position: absolute;
  top: var(--ch-desktop-header-top-row-height);
  left: 0;
  width: 100vw;
  z-index: 2;
  height: calc(100vh - var(--ch-desktop-header-top-row-height));
}

.DesktopAuthButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  margin-left: 40px;
}

.DesktopLogoCHOImprovementNav {
  margin-left: 30px;
}

/* stylelint-disable no-descending-specificity */
.DesktopRightContainerWithSearchFieldShowing
  .DesktopHeaderBottomRowLeftContainer {
  & .DesktopNavItem {
    margin-right: 15px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }
}
/* stylelint-enable no-descending-specificity */

.DesktopRightContainerWithSearchFieldShowing .DesktopNavItemContainer {
  display: none;
}

@media (--tablet-screen) {
  .MobileSearchItemAnimationHiddenOnTablet.MobileSearchItemAnimation {
    /* Override Motion's inline display: block */
    display: none !important;
  }

  .Suggestion {
    display: flex;
    flex-direction: column;

    & .SuggestionText {
      display: block;
    }

    & .SuggestionMultiUnit {
      text-align: left;
      margin-left: 0;
    }
  }
}

@media (--tablet-screen-and-smaller) {
  .DesktopContainer {
    display: none;
  }

  .MobileSearchHomesBackArrow {
    top: 1px;
  }

  .MobileContainer {
    display: block;
  }

  .MobileTopSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 0 16px;
    box-sizing: border-box;
  }

  .MobileLogoSearchContainer {
    display: flex;
    flex-direction: row;
    /* Align search button and right-side comehome logo */
    align-items: baseline;
  }

  .ClickableLogoByHouseCanary {
    height: 28px;
  }

  .MobileMenuContainer {
    & .MobileAccountMenuContainer > * {
      pointer-events: auto;
    }

    & .MobileMenuList {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    & .MobileAccountMenuBackdrop {
      z-index: 102; /* Sit behind MobileAccountMenuAnimation */
      position: absolute;
      /* `top` set via JS */
      right: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgb(0 0 0 / 20%);
      animation: fade-in 0.3s;
    }

    & .MobileSearchItemAnimation {
      background: #f4f4f4;
      width: 100%;
      box-sizing: border-box;
      position: relative;
    }

    & .MobileAccountMenuAnimation {
      position: absolute;
      /* `top` set via JS */
      background: #fff;
      width: 100%;
      box-sizing: border-box;
      z-index: 103;
    }

    & .SearchItem {
      height: 60px;
    }

    & .AutoComplete {
      max-width: 100% !important;
      transform: translateY(-5px);
      /* Stack above the .LayerItemsControl (SRP map controls) and the mobile bottom nav
       * This is necessary due to the `transform` above pulling the child .Results
       * element out of the stacking context (nullifying the z-index of .Results) */
      z-index: 102;

      & .InputElement {
        width: calc(100% - 20px);
        margin: 0 auto;
        border: 1px solid #e5e5e5;
        border-radius: 6px;
        padding: 15px 12px 17px;
        background-color: white;
      }

      & .InputElement::placeholder {
        font-size: 14px !important;
        color: inherit;
      }

      &.AutoCompleteWithDropdown .InputElement {
        border-bottom: 1px solid rgb(0 0 0 / 12%);
      }

      & .SearchButton {
        width: 50px;
        top: 18px;
        right: 10px;
        border-radius: 0 6px 6px 0;
      }

      &.AutoCompleteWithDropdown .SearchButton {
        border-radius: 0 6px 6px 0;
      }

      & .InputWrapper {
        padding-top: 18px;
      }

      & .ResetButton {
        top: 18px;
        right: 60px;
      }

      & .Results {
        top: 58px;
        border-radius: 0;
        border: none;
        background: #f4f4f4;
      }

      & .HasVisibleLabel {
        background-color: #f4f4f4;
        padding: 0 2px;
        border-radius: 4px;
        font-size: 10px;
        left: 18px;
        top: 6px;
      }
    }
  }

  .MobileAccountMenuContainer {
    height: 100vh;
    z-index: 2;
    background: white;
    position: absolute;
    top: 0;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    display: flex;
    flex-direction: column;
    /* Nav items at the top, app download links at the bottom */
    justify-content: space-between;

    & .MobileAccountMenuContainerNavItems {
      flex: 1;
      overflow: auto;
    }

    & .MobileMenuListItem {
      padding: 20px;
      font-size: 14px;
      font-weight: 900;
      line-height: 1.5;
      cursor: pointer;
      width: var(--ch-mobile-hamburger-menu-width);
      margin: auto;
      -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    }

    & .MobileMenuListItemSelected {
      padding: 20px;
      font-size: 14px;
      font-weight: 900;
      line-height: 1.5;
      cursor: pointer;
      width: var(--ch-mobile-hamburger-menu-width);
      margin: auto;
      color: #5646ef;
    }

    & .MobileMenuListItem + .MobileMenuListItem {
      border-top: 1px solid rgb(0 0 0 / 12%);
    }

    & .MobileMenuListItem:last-of-type {
      border-bottom: 1px solid rgb(0 0 0 / 12%);
      padding-bottom: 20px;
    }

    & .MobileMenuListItem:last-child {
      margin-bottom: 20px;
    }

    & .TextHeader {
      font-size: 16px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 22px;
      padding-top: 12px;
    }

    & .TextSubHeader {
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 16px;
      padding-bottom: 16px;
    }
  }

  .MobileMenuScreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1;
    height: 100vh;
  }

  .MobileSearchIconContainer {
    display: flex;
    align-items: center;
    animation: fade-in 0.2s;
    margin-right: 10px;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    width: 23px;

    & .SearchIcon-svg {
      height: 18px;
    }
  }

  .MobileSearchIconContainer .SearchIconButton {
    border: none;
    background: none;
    padding: 0;
  }

  .MobileSearchIconContainer .SearchIcon {
    position: relative;
    display: flex;
    opacity: 1;
    transition: opacity 30ms;
    cursor: pointer;
  }

  .MobileSearchIconContainer.MobileSearchIconContainerActive .SearchIcon {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }

  .MobileLogoContainer {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    width: 123px;

    & .ClickableLogo {
      margin-left: 0;
      width: 100%;
    }
  }
}

.NavItemDivider {
  box-sizing: border-box;
  height: 22px;
  width: 2px;
  background-color: #a6a6a6;
}

.ModalOpenHighlight {
  height: 3px;
  width: 40px;
  background: linear-gradient(104.11deg, #5840ea 0%, #3a61de 100%);
  margin: 20px 0 0 10px;
  position: absolute;
  bottom: 0;
}

.AppModalColumn {
  display: block;
  align-items: center;
  z-index: 6;
}

.NavItemDividerLeft {
  box-sizing: border-box;
  height: 22px;
  width: 2px;
  background-color: #a6a6a6;
  margin-left: 30px;
}

@media (--xx-small-screen) {
  .MobileMenuList {
    padding: 0;

    & .MobileMenuListItem:last-child {
      /* margin bottom is set to allow small screens, like iphone se, to scroll down to the last menu */
      margin-bottom: 100px;
    }
  }
}

@media (--high-zoom-screen) {
  .MobileTopSection {
    height: 35px;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
