@value ch-green, ch-orange, hc-yellow, text-color from './_colors.css';

.Pill {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.Price {
  margin-top: 3px;
}

.Active .Status {
  color: ch-green;
}

.Pending .Status,
.Contingent .Status {
  color: hc-yellow;
}

.Sold .Status,
.Withdrawn .Status {
  color: ch-orange;
}

.OffMarket .Status {
  color: text-color;
}
