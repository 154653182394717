@import './_breakpoints.css';
@import './_constants.css';
@value box-shadow-property-card from './_fontsAnimations.css';

.LenderCTACard.SmallCard,
.LenderCTACard.BothCard {
  border-radius: 14px;
  transition: box-shadow 0.2s, transform 0.2s;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: flex;

  &:hover {
    box-shadow: box-shadow-property-card;
  }

  & .HeadlineWrapper {
    padding: 17px 0 25px 33px;
  }

  & .LenderCTA {
    display: flex;
    align-items: flex-start;
    height: 26px;
    margin: 0;
  }

  & .LenderCTAButton {
    width: 134px;
    font-size: 10px;
    line-height: 14px;
    min-height: 26px;
  }

  & .HeadlineCopy {
    color: #fff;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 10px;
    text-align: left;
  }

  & .AdImgContainer {
    width: 93px;
    display: flex;
    justify-content: flex-end;
  }

  & .AdImg {
    width: 128px;
    height: auto;
    right: -36px;
    bottom: -7px;
    position: absolute;
  }
}

.LenderCTACard.FullCard {
  margin-right: 15px;
  height: var(--propertyCardHeight);
  width: calc(var(--propertyCardWidth) - 15px);
  display: block;
  border-radius: 14px;
  transition: box-shadow 0.2s, transform 0.2s;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  &:hover {
    box-shadow: box-shadow-property-card;
  }

  & .HeadlineWrapper {
    padding: 0;
  }

  & .LenderCTA {
    align-items: flex-start;
    margin: 0 0 0 36px;
    height: 45px;
  }

  & .LenderCTAButton {
    width: 182px;
    font-size: 14px;
    line-height: 19px;
    min-height: 45px;
  }

  & .HeadlineCopy {
    color: #fff;
    font-weight: 900;
    font-size: 28px;
    line-height: 41px;
    padding: 34px 10px 30px 36px;
  }

  & .AdImgContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  & .AdImg {
    width: 236px;
    right: -67px;
    bottom: -11px;
    height: auto;
    position: absolute;
  }
}

:export {
  mobileDefaultAdCardHeight: var(--mobileDefaultAdCardHeight);
}
