@value text-color from './_colors.css';

/* TODO: find a way for --horizontalToggleOptionPadding to be used via a theme instead: */
:root {
  --horizontalToggleOptionPadding: 1px;
}

.HorizontalToggle {
  display: inline-block;
  border-radius: 500px;
  background-color: text-color;
  position: relative;
  user-select: none;
  white-space: nowrap;
  width: 44px;
  border: none;
}

.Option {
  /* Changed attributes for Consumer Web: */
  font-size: 10px;
  font-weight: 300;
  padding: 11px 1px 10px !important;
  display: inline-block;
  text-align: center;
  cursor: default;
  position: relative;
  z-index: 2;
  color: text-color;

  &:not(.selected) {
    cursor: pointer;
  }
}

.Selected {
  color: #fff;
  font-weight: 600;
}

.SelectedMask {
  position: absolute;
  background-color: text-color;
  border-radius: 50%;
  height: 100%;
  padding: 0 var(--horizontalToggleOptionPadding) 0
    var(--horizontalToggleOptionPadding);
  z-index: 1;
  top: 0;
  transition: left 0.3s;
}

.Disabled {
  & .SelectedMask {
    background-color: transparent;
  }

  & .Option {
    cursor: default !important;
    color: text-color !important;
  }
}

:export {
  optionPadding: var(--horizontalToggleOptionPadding);
}
