@import './_breakpoints.css';
@import './_constants.css';
@value rounded-border, box-shadow-property-card from './_fontsAnimations.css';
@value text-color from './_colors.css';

.SearchLoanOfficerAd {
  background: #f7f7f7;
  border: 1px solid #dcdcdc;
  color: text-color;
  padding: 18px 40px 34px;
  border-radius: 14px;
  transition:
    box-shadow 0.2s,
    transform 0.2s;
  margin-right: 15px;
  height: var(--propertyCardHeight);
  width: calc(var(--propertyCardWidth) - 15px);
  box-sizing: border-box;
  position: relative;

  &:hover {
    box-shadow: box-shadow-property-card;
  }
}
/* Overriding AgentContactInfo element styling */
.AgentName {
  /* To take care of exceptionally long agent names */
  max-width: 205px;
  /* Adding ellipse only on Search page as the card height cannot be increased */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CanHelpText {
  font-size: 15px;
  font-weight: 900;
  line-height: 28px;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.FinanceText {
  font-size: 30px;
  font-weight: 900;
  letter-spacing: -0.58px;
  line-height: 35px;
  max-height: 125px;
}

.SearchLoanOfficerAd .PhotoColumn {
  height: 46px;
  width: 46px;
  margin-right: 12px;
}

.SearchLoanOfficerAd .AgentPhoto {
  min-height: 46px;
}

.SearchLoanOfficerAd .AgentName {
  font-size: 13px;
  line-height: 18px;
}

.SearchLoanOfficerAd .AgentSubtitle {
  font-size: 12px;
}

.SearchLoanOfficerAd .Company {
  font-size: 11px;
  line-height: 11px;
}

.SearchLoanOfficerAd .mlsId {
  font-size: 10px;
  line-height: 10px;
}

.SearchLoanOfficerAd .ContactButton {
  width: auto;
  height: 44px;
  /* accounting for padding, margin left & right on card width*/
  max-width: calc(var(--propertyCardWidth) - 95px);
  font-size: 14px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 15px;
  padding: 12px 24px 12px 19px;
  position: relative;

  & .ButtonText {
    /* If LO's firstname is too long to
     * fit in UI, we add ellipsis */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .MessageIcon {
    position: relative;
    top: 1px;
    margin-right: 10px;

    & .MessageIcon-svg {
      height: 18px;
      width: 18px;
    }
  }
}

.LastMessageDate {
  font-size: 11px;
  font-weight: 300;
  position: absolute;
  bottom: 14px;
  left: 40px;
  opacity: 0.8;
}

.LOEmailLink {
  text-decoration: none;
  color: currentcolor;
  font-size: 10px;
  font-weight: 300;

  /* To take care of exceptionally long agent emails */
  max-width: 275px;
  word-wrap: break-word;
  text-align: left;
}

/* needed to override AgentContactInfo styles on mobile */
@media (--small-screen) {
  .SearchLoanOfficerAd {
    margin-right: 0;
    width: 100%;
  }

  .SearchLoanOfficerAd .PhotoColumn {
    height: 46px;
    width: 46px;
    margin-right: 12px;
  }

  .SearchLoanOfficerAd .AgentPhoto {
    min-height: 46px;
  }

  .SearchLoanOfficerAd .AgentName {
    line-height: 18px;
  }

  .GenericLOInfo {
    padding: 0;
  }

  .PhoneCallLink {
    background: #fff;
  }

  .ContactContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (--xxx-small-screen) {
  .SearchLoanOfficerAd {
    margin-right: 0;
    width: 300px;
    padding: 20px;
  }
}
