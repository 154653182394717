.PulseLoader {
  width: 100%;
  height: 100%;
  animation: pulse 0.9s ease-in-out infinite;
  background: #f8f8f8;
}

@keyframes pulse {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}
