@import './_breakpoints.css';

.NoResultsNotice {
  width: 408px;
  margin: 180px auto 0;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
}

.IconSection {
  height: 95px;
  width: 96px;
  margin: 0 auto;
  align-self: center;
  align-items: center;
  justify-content: center;

  & svg {
    margin-bottom: -5px;
  }
}

.EmptyMessage {
  margin-top: 28px;
  font-size: 32px;
  font-weight: 900;
  align-self: center;
  text-align: center;
}

.InstructionMessage {
  margin-top: 25px;
  font-size: 16px;
  font-weight: 500;
  align-self: center;
  text-align: center;
}

@media (--small-screen) {
  .NoResultsNotice {
    margin-top: 60px;
    padding: 0 40px;
    width: 100vw;
    text-align: center;
  }

  .EmptyMessage {
    font-size: 22px;
  }

  .InstructionMessage {
    font-size: 12px;
  }
}
