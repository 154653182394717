@import './_breakpoints.css';

/* The button and modal container */
.AuthDropdownContainer {
  position: relative;
  text-align: right;

  & svg {
    height: 19px;
    width: 18px;
    cursor: pointer;
  }

  & .AuthButtonContainer {
    position: relative;

    & .MenuNotificationCount {
      position: absolute;
      top: 8px;
      left: -13px;
      border: 2px solid #fff;
    }
  }
}

.TooltipContent {
  /* Can't nest this since it's rendered inside of a portal */
  padding: 0 !important;
}

/* this is to account for a gap between the button and the menu */
.button {
  margin: 0 0 20px;
  position: relative;
  top: 10px;
  color: inherit;
  border-radius: 30px;

  &:hover {
    color: inherit;
    background: #fff;
  }
}

@media (--small-screen) {
  /* Hidden on mobile */
  .AuthDropdownContainer,
  .FlatButton {
    display: none;
  }
}
