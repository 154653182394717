@import './_constants.css';
@import './_breakpoints.css';
@value text-color from './_colors.css';

.Breadcrumbs {
  font-size: 10px;
  color: text-color;
  margin: 8px 0;
  max-width: 1200px;
  padding: 0;

  & .link {
    display: inline-block;
    margin-left: 6px;
  }

  & .link + .link::before {
    content: '•';
    margin-right: 6px;
  }

  & .link:first-child {
    margin-left: 0;
  }

  & .RouterLink {
    color: text-color;
    cursor: pointer;
    text-decoration: none;
  }
}

@media (--tablet-screen-and-smaller) {
  .Breadcrumbs {
    /* we want the HTML to be rendered to the DOM, but just hidden, for SEO purposes. */
    display: none;
  }
}
