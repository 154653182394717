@import './_breakpoints.css';

@value home-page-search-max-width: 490px;
@value home-page-search-height: 60px;
@value home-page-search-border-radius: 33px;
@value text-color from './_colors.css';
@value border-color-light from './_colors.css';

.HomeSubpageSearch {
  padding: 0 20px 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & .AutoComplete {
    /* Above .BackgroundScreen */
    z-index: 2;
    max-width: home-page-search-max-width;
    height: home-page-search-height;

    & .InputElement {
      border: 1px solid #f4f4f4;
      border-radius: home-page-search-border-radius;
      font-size: 16px;
      padding: 0 100px 0 20px;
      height: home-page-search-height;
      background-color: white;
      box-shadow: 0 4px 20px rgb(0 0 0 / 13%);

      &:focus {
        padding-right: 110px;
      }

      &::placeholder {
        font-size: 14px;
      }
    }

    &.AutoCompleteWithDropdown .InputElement {
      border-radius: home-page-search-border-radius
        home-page-search-border-radius 0 0;
      border-bottom: none;
    }

    & .SearchButton,
    & .ResetButton {
      height: home-page-search-height;
      width: home-page-search-height;
    }

    & .SearchButton {
      border-radius: 0 home-page-search-border-radius
        home-page-search-border-radius 0;
    }

    &.AutoCompleteWithDropdown .SearchButton {
      border-radius: 0 home-page-search-border-radius 0 0;
    }

    & .ResetButton {
      right: 62px;

      svg {
        height: 24px !important;
        width: 24px !important;
      }
    }

    & .Results {
      border: 1px solid #f4f4f4;
      width: home-page-search-max-width;
      padding: 0 20px;
      border-radius: 0 0 home-page-search-border-radius
        home-page-search-border-radius;
      border-top: 1px solid border-color-light;
      /* shift results element by the height amount defined above */
      top: home-page-search-height;
      /* Above .BackgroundScreen */
      z-index: 2;
    }
  }

  & .BrokerageAttribution {
    margin-top: 0;
  }
}

.AutoCompleteSSRPlaceholder {
  height: 60px;
}

/* .AutoComplete .BackgroundScreen */
.BackgroundScreen {
  opacity: 0;
  top: 0;
  /* Below .AutoComplete and .Results */
  z-index: 1;
}

.TopSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 20px 20px 120px;
  background: rgb(255 255 255);
  background: linear-gradient(
    180deg,
    rgb(255 255 255 / 100%) 0%,
    rgb(255 255 255 / 100%) 78%,
    rgb(255 255 255 / 0%) 100%
  );
  /* Above the photo columns */
  z-index: 2;
}

.TopSectionWithNoSelector {
  margin-top: 20px;
}

.HeaderSection {
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.Header {
  font-weight: 900;
  font-size: 66px;
  padding: 0 10px;
  line-height: 70px;
  letter-spacing: -1px;
  margin-block: 0;
  white-space: pre-wrap;
}

.SubHeader {
  font-weight: 600;
  font-size: 20px;
  margin: 12px 0 0;
  padding: 0 10px;
}

.SearchTypeSelector {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 12px;
}

.SelectButton {
  background: transparent;
  border: none;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  height: 28px;
  margin: 0 20px;
  border-bottom: 3px solid white;
  color: text-color;
  padding: 0;
}

.ActiveSelectButton {
  font-weight: 900;
  /* downsize active font to prevent UI jerk */
  font-size: 13.5px;
}

.PhotoSection {
  margin: 20px 0 0;
  display: flex;
}

.PhotoColumn {
  width: 360px;
  padding: 0 12px;
  /* Ensure the photo columns stay on top of objects in the next page when animating */
  z-index: 1;

  & .PhotoColumnPhoto {
    height: 450px;
    margin-bottom: 24px;
    border-radius: 14px;
    background-size: cover;
    background-position: center;
  }

  & .PhotoColumnPhoto:nth-child(2n) {
    height: 330px;
  }

  &:nth-child(2n - 1) {
    width: 300px;

    & .PhotoColumnPhoto {
      height: 330px;
    }

    & .PhotoColumnPhoto:nth-child(2n) {
      height: 450px;
    }
  }
}

/* 3 columns of larger photos */
@media (max-width: 1440px) {
  .PhotoColumn:nth-child(4) {
    display: none;
  }
}

/* 2 columns of larger photos */
@media (max-width: 1080px) {
  .PhotoColumn:nth-child(3) {
    display: none;
  }
}

/* 2 columns of smaller photos */
@media (max-width: 740px) {
  .PhotoSection {
    width: calc(100% + 12px);
  }

  .PhotoColumn {
    padding: 0 8px;
    flex: 1;

    & .PhotoColumnPhoto {
      height: 70vw;
      margin-bottom: 16px;
    }

    & .PhotoColumnPhoto:nth-child(2n) {
      height: 50vw;
    }

    &:nth-child(2n - 1) {
      width: initial;

      & .PhotoColumnPhoto {
        height: 50vw;
      }

      & .PhotoColumnPhoto:nth-child(2n) {
        height: 70vw;
      }
    }
  }
}

@media (--small-screen) {
  .HeaderSection {
    height: 192px;
  }

  .Header {
    font-size: 32px;
    line-height: 36px;
    font-weight: 800;
    white-space: pre-wrap;
  }

  .SubHeader {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  }

  .TopSection {
    padding-bottom: 80px;
  }
}

/* Allow the bottom photos to be seen on small mobile devices */
@media (height < 700px) {
  .HeaderSection {
    height: 170px;
  }

  .TopSection {
    padding-bottom: 70px;
  }
}

@media (--high-zoom-screen) {
  .HeaderSection {
    height: initial;
    margin-bottom: 0;
  }

  .Header {
    line-height: 25px;
    white-space: pre-wrap;
  }

  .SubHeader {
    line-height: 15px;
  }

  .TopSection {
    padding-top: 10px;
  }

  .SearchTypeSelector {
    margin: 0;
  }
}

@media (--four-hundred-percent-zoom) {
  .SelectButton {
    height: 42px;
  }
}
