@import './_breakpoints.css';
@import './_constants.css';
@value text-color from './_colors.css';
@value box-shadow-soft from './_fontsAnimations.css';

.NearbyListings {
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;

  & .WatchListActionButton {
    display: none;
  }
}

.Heading {
  font-size: 17px;
  font-weight: 200;
  margin-bottom: 20px;
}

.SubTitle {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 16px;
}

.NearbyListings .Slider {
  box-sizing: border-box;
  width: 100%;
  max-width: 1042px;
}

.NearbyListings .Slider,
.NearbyListings .SliderItem {
  height: calc(var(--propertyCardHeight) + 120px);
}

.NearbyListings .SliderArrow {
  top: calc(40% - 25px);
  z-index: 2;
}

.NearbyListings .Slider .SliderArrow {
  & :global svg .element-to-fill {
    fill: text-color;
  }
}

.SliderContainer {
  position: relative;
  width: 100%;
}

.NearbyListings .PropertyCard {
  max-width: 100%;
  width: var(--propertyCardWidth);
}

.SliderContainer::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
}

.NavDots {
  /* fixes nav dots layering issue on Desktop */
  z-index: 1;
  top: 65%;
  bottom: none;

  & .Dot {
    height: 15px;
    width: 15px;
    background-color: rgb(0 0 0 / 50%);
    margin: 0 10px;
  }

  & .Dot:hover {
    cursor: pointer;
  }

  & .Dot.active {
    height: 22px;
    width: 22px;
    background-color: #4a4a4a;
    margin: 0 10px;
  }
}

@media (--x-small-screen) {
  .SliderContainer::after {
    background: rgb(255 255 255 / 0%);
  }
}

@media (--tablet-screen-and-smaller) {
  .PropertyCardValueStacked {
    margin: 0;
    width: 100%;
    text-align: left;
  }

  .Slider {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    box-sizing: border-box;

    & .Spacer {
      width: 60px;
      height: 60px;
      flex: none;
    }

    & .PropertyCard {
      /* overriding default widths set by slider comp */
      width: 180px !important;
      max-width: unset !important;
      margin-right: 20px !important;
      min-height: initial;

      & .PropertyCardPhoto {
        height: var(--nearbyListingsPropertyCardPhotoHeightSmall);
        height: 84px;
      }

      & .PropertyIntro {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        margin-bottom: 0;
        /* Fixed height since we don't know if this will take up 2 or 3 lines */
        height: calc(100% - 135px);
      }

      & .SmallPropertyInfo {
        padding: 0;
      }

      & .PriceRowNoEstimatedPayment {
        margin-top: 0;

        & .ValueLabel,
        & .Value {
          font-size: 10px;
        }
      }

      /* replacing overflow with ... */
      & .StreetAddress,
      & .StreetAddress a,
      & .CityStateZipcode {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    & .PropertyCardInner {
      width: 180px;
      display: block;
      box-shadow: box-shadow-soft;
    }

    & .Controls {
      display: none;
    }

    /* stylelint-disable no-descending-specificity */
    & .PropertyIntro {
      padding-top: 6px !important;
    }
    /* stylelint-enable no-descending-specificity */

    & .SmallPropertyInfo .PropertyCardBasicInfo {
      display: none;
    }

    /* stylelint-disable no-descending-specificity */
    & .ValueLabel,
    & .Value {
      font-size: 12px;
      font-weight: 900;
      text-align: left;
    }

    & .PropertyCardValueStacked {
      display: flex;
      width: 100%;
      text-align: left;

      & .ValueLabel {
        margin-right: 5px;
      }
    }
    /* stylelint-enable no-descending-specificity */

    & .PropertyCardValue {
      width: 100%;
      text-align: left;
    }

    & .PropertyCardPriceInfo {
      margin-top: auto;
      align-items: flex-start;
    }

    & .ShowDetailsCTA,
    & .EstimatedMonthlyPayment,
    & .ShowDetailsAndChevronIcon,
    & .HorizontalSeparator {
      display: none;
    }
  }

  .NearbyListings .Slider,
  .NearbyListings .SliderItem {
    height: 275px;
  }

  .SmallPropertyInfo .PropertyCardAddress {
    margin-bottom: 8px;
  }

  .PropertyCardValueSection,
  .PropertyBrief h2,
  .SmallPropertyInfo .PropertyCardAddress {
    font-size: 10px;
    font-weight: 200;
    line-height: 14px;
  }

  .Slider .PropertyCardValueSection {
    padding: 0 10px 10px;
  }

  .PropertyBrief .VerticalSeparator {
    height: 10px;
    bottom: -1px;
  }
}

@media (min-width: 1124px) {
  .NavDotsWithLessThanThreeItems {
    display: none;
  }
}

:export {
  cardWidth: var(--propertyCardWidth);
  avmColWidth: var(--pdpAvmColWidth);
}
