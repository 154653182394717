@import './_constants.css';

.MobileFilterSelect {
  border-radius: 14px;
  flex-grow: 1;
}

.SelectFilters {
  font-size: 20px;
  font-weight: 200;
  margin: 0;
  border: none;
  background: none;
  padding: 0;
  color: inherit;
}

.Header {
  background: rgb(255 255 255 / 70%);
  border-radius: 14px 14px 0 0;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Header,
.FilterRow {
  padding-left: 18px;
  padding-right: 18px;
}

.SelectFilterHeadingAriaText {
  composes: hide-off-screen from './_common.css';
}

.FilterRow {
  display: flex;
  min-height: 57px;
  border-top: 1px solid #eee;
  cursor: pointer;
  align-items: center;
  position: relative;
}

.FilterTitle {
  font-size: 16px;
  margin-right: 12px;
  width: 100px;
}

.FilterDesc {
  font-size: 12px;
  width: calc(100% - 112px);
}

.FilterSelectTable {
  display: block;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
  border-radius: 0 0 14px 14px;
  background: #fff;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  -webkit-tap-highlight-color: transparent;

  & tbody {
    display: block;
  }
}

.Screenreader {
  composes: hide-off-screen from './_common.css';
}

.FilterSelectTable .FilterRow:last-of-type {
  border-radius: 0 0 14px 14px;
}

.disabled {
  /* Need "!important" to overwrite opacity that is set by framer-motion */
  opacity: 0.4 !important;
  cursor: not-allowed;
}

.LockedComponentContainer {
  & .LockedContentContainer {
    & .LockIcon {
      & svg {
        height: 30px;
        width: 30px;

        & path {
          stroke-width: 2px;
        }
      }
    }
  }
}

:export {
  headerAndControlsCombinedHeight: var(--headerAndControlsCombinedHeight);
  headerAndControlsAndTitleCombinedHeight: var(
    --headerAndControlsAndTitleCombinedHeight
  );
}
