/* The preferred usage is for this component's parent element to be "display: flex; align-items: center;" */
.Tooltip {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: 0;
}

.Trigger {
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.TooltipContentWrapper {
  color: text-color;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: calc(100% + 2px);
  left: 50%;
}

.TooltipContent {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  background-color: white;
  border: none;
  border-radius: 4px;
  padding: 20px;
  font-size: 14px;
  font-weight: 100;
  box-sizing: border-box;
  line-height: 1.2rem;
  text-align: left;
  max-width: calc(100vw - 40px);
  color: inherit;
  transform: translateY(-15px);
  box-shadow: -2px 2px 10px #dadada;
}

.TooltipArrow {
  position: absolute;
  left: calc(50% - 10px);
  font-size: 16px;
  width: 10px;
  transform: scaleX(1.5);
  border: none;
  line-height: 8px;
  color: white;
  top: 100%;
  text-shadow: 0 -2px 0 #fff;
}

.ArrowText {
  text-shadow: 0 -2px 0 #fff, 0 2px 4px #efefef;
}
