/* NOTE: Due to the complexity of this CSS file which was written prior to implementing Stylelint, there are a lot of `no-descending-specificity` errors.
  * These errors are not worth fixing at this time, so we are disabling the rule for this file. */
/* stylelint-disable no-descending-specificity */
@import './_breakpoints.css';
@value ch-blue, border-color-light, border-color from './_colors.css';

.LockedComponentContainer {
  & .LockedContentContainer {
    width: 480px;
    max-width: 480px;

    & .LockIcon {
      margin-right: 5px;

      & svg {
        & path {
          stroke-width: 4px;
        }
      }
    }
  }
}

.LockedSection {
  filter: blur(4px);
}

.AutoComplete {
  position: relative;
  width: 100%;

  &.AutoCompleteWithDropdown .InputElement {
    border-radius: 20px 20px 0 0;
    border-bottom: none;
    transition: border-radius 0.5ms;
  }

  &.AutoCompleteWithDropdown .SearchButton {
    border-bottom-right-radius: 0;
    transition: border-radius 0.5ms;
  }

  &.AutoCompleteWithSearchButton .InputElement {
    padding-right: 60px;

    &:focus {
      padding-right: 68px;
    }
  }

  & .InputElement {
    border-radius: 20px;
    transition: border-radius 200ms ease-out;
    font-size: 16px;
    padding-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
    overflow-x: hidden;
    text-overflow: ellipsis;

    &::placeholder {
      color: inherit;
      font-weight: 200;
      font-size: 12px;
      text-align: left;
    }
  }

  & .InputElement,
  & .SearchButton,
  & .ResetButton {
    height: 40px;
  }

  & .SearchButton {
    border-radius: 0 20px 20px 0;
    transition: border-radius 200ms ease-out;
    width: 40px;
  }

  & .ResetButton {
    right: 40px;
    top: 0;

    & svg {
      height: 15px !important;
      width: 15px !important;
    }
  }
}

.Results,
.Results .ResultsListItem,
.Results .Suggestion {
  max-width: 100%;
}

.Results {
  width: 100%;
  padding: 0 10px;
  border-radius: 0 0 25px 25px;
  border: 1px solid #dedede;
  border-top: 1px solid border-color-light;
  transition: border-radius 100ms;
  right: 0;
  left: 0;
  max-width: 100%;
  text-align: left;
  background: #fff;
  position: absolute;
  z-index: 999;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;

  & .ResultsList {
    margin: 0;
    padding: 10px 0 0;
    list-style-type: none;

    & li:first-child {
      padding: 0;
    }

    &:not(:first-child) {
      border-top: 1px solid border-color-light;
    }

    & .LocationItem.SuggestionActive {
      background-color: #e8e8f7;
    }
  }

  & .ResultsListItem,
  & .Suggestion,
  & .LocationItem {
    color: inherit;
    font-size: 13px;
    font-weight: 200;
    padding: 14px 2px;
    width: 100%;
    margin: 0;
    /* To avoid cutting off "descenders" of letters */
    line-height: 19px;
    height: auto;
    border: 0;
    box-sizing: border-box;
    cursor: pointer;

    & b {
      font-weight: 200;
    }

    &:not(:last-child) {
      border-bottom: 1px solid border-color-lightest;
    }
  }

  & .LocationItem svg {
    margin-right: 15px;
    transform: translateY(2px);
  }

  & .LocationItem:hover {
    background: #f8f8f8;
  }

  & a {
    text-decoration: none;
    font-weight: 200;
    color: ch-blue;
  }

  & li {
    border: 0;
  }

  & li:last-of-type {
    border-bottom: 0;
  }

  & .AutoCompleteLinkAnimation {
    & .ResultsList {
      /* Used for the "Can't Find Your Address?" link: */
      & .ResultsListItem.SuggestionActive {
        background-color: #e8e8f7;
      }
    }
  }
}

.ScreenReaderVisuallyHidden {
  composes: screen-reader-only from './_common.css';
}

.MobileResultsScreen {
  display: none;
  position: fixed;
  top: 2.6rem;
  left: 0;
  width: 100vw;
  height: calc(100vh - 2.6rem);
  background-color: white;
}

.ResultsListItem {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  padding: 0 1.5em;
  line-height: 3.8rem;
  height: 3.8rem;
  border-bottom: 1px solid #d8d8d8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.4rem;
  cursor: pointer;
}

.ResultsListItem.ResultsListItemNoResultsLink {
  padding-left: 0;
  background: none;
}

.ResultsListItem b {
  font-weight: 200;
}

.ResultsListItem:hover {
  background: #f8f8f8;
}

.ResultsListItem.result--active {
  background-color: #e8e8f7;
}

.ResetButton,
.SearchButton {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  width: 36px;
  text-align: left;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  display: flex;
}

.SearchIconElement {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: 0;
  padding: 0;
  border: 0;
  width: 50px;
  text-align: left;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  display: flex;
}

.SearchIcon,
.CloseIcon {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.SearchIcon {
  & svg {
    height: 18px;
    width: auto;
    max-height: 100%;

    & :global .element-to-stroke {
      stroke: #fff;
    }

    & :global .element-to-fill {
      fill: #fff;
    }
  }
}

.CloseIcon svg {
  height: 26px;
}

.NoResultsLink {
  composes: strip-button-styles from './_common.css';

  & span {
    display: block;
  }
}

.NoResultsLink,
.InvalidCharactersText {
  width: 100%;
  height: 100%;
  font-size: 13px;
  font-weight: 200;
  white-space: initial;
  text-align: center;
}

.InvalidCharactersText {
  cursor: default;
}

.NoResultsLink .HideMobile {
  display: none;
}

.ResultsCategoryLabel {
  display: block;
  font-weight: 900;
  padding: 6px 2px;
  color: inherit;
  font-size: 16px;
  margin: 0;
}

.ViewMoreResults {
  width: 100%;
  height: 100%;
}

.NormalColor {
  color: inherit;
}

.HiddenInputForIOSFocusing {
  position: absolute;
  /* Arbitrary amount, but needs a non-zero width for iOS to be able to focus it successfully. This hidden
   * input is used for the purposes of programmatically triggering focus on the input in various scenarios */
  width: 20px;
  height: 0;
  opacity: 0;
  font-size: 16px;
  margin: 0;
  padding: 0;
  border: 0;
}

.Suggestion {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  padding: 0 1.5em;
  line-height: 3.8rem;
  height: 3.8rem;
  border-bottom: 1px solid #d8d8d8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.4rem;
  cursor: pointer;

  & b {
    font-weight: bold;
  }

  &:hover {
    background: #f8f8f8;
  }

  &.SuggestionActive {
    background-color: #e8e8f7;
  }

  & .SuggestionText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .SuggestionMultiUnit {
    text-align: right;
    margin-left: 10px;
  }

  & .SuggestionMultiUnit svg {
    display: inline-block;
    margin-left: 10px;
  }
}

.SuggestionChevronIcon svg {
  transform: rotate(180deg) scale(1.8) translate(2px, -1px);
  width: 12px !important;
  height: 12px !important;
}

.AutoCompleteLinkAnimation {
  padding: 0;
}

.BackgroundScreen {
  margin-top: 8px;
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0 0 0 / 40%);
  opacity: 0.8;
  /* stylelint-disable-next-line */
  filter: alpha(opacity=80); /* For IE8 and earlier */
  animation: fade-in 0.3s;
}

.RecentUserActivityCTA {
  width: 100%;
  text-align: left;
}

.ScrollActiveElementIntoView {
  width: 100%;
}

@media (--small-screen) {
  .NoResultsLink .HideMobile {
    display: inline;
  }

  .MobileResultsScreen {
    display: block;
  }

  .InputElement {
    /* Prevent zoom-in after focus on mobile devices in every usage throughout the app */
    font-size: 16px !important;
  }

  .LockedComponentContainer {
    & .LockedContentContainer {
      width: 84%;
      max-width: 84%;
    }
  }
}
/* stylelint-enable no-descending-specificity */
