@import './_breakpoints.css';

.AdditionalHomeDetails {
  box-sizing: border-box;

  & .propDetailsType,
  & .propDetailsData {
    font-size: 12px;
  }

  & .Legal {
    text-align: left;
    margin-bottom: 40px;
  }
}

@media (--small-screen) {
  .AdditionalHomeDetails {
    padding: 0 15px;
  }
}
