@import './_breakpoints.css';

.Slider {
  position: relative;
  width: 100%;
}

.SliderTrackWrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* Needed to prevent the SliderTrack from being higher in the stacking index than SliderArrow since
   * its TranslateZ property is changed via framer for the slide animation */
  position: relative;
  z-index: 1;
}

.SliderTrack {
  position: relative;
  height: 100%;
}

.SliderTrack.clickable {
  cursor: pointer;
}

.SliderItem {
  position: absolute;
  top: 0;
}

.Offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
  width: 100%;
}

.Index {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 20px;
  padding: 5px;
  background-color: white;
  color: black;
  z-index: 1;
}

.SliderArrow {
  background: none;
  border: none;
  color: inherit;
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  top: calc(50% - 25px);
  left: 10px;
  z-index: 1;
  cursor: pointer;

  &.SliderArrow--right {
    right: 10px;
    left: initial;
    transform: rotate(180deg);
  }

  & svg {
    height: 50px !important;
    width: 50px !important;
  }

  & svg :global .element-to-fill {
    fill: white;
  }
}

.NavDots {
  display: flex;
  position: absolute;
  bottom: -18px;
  top: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Dot {
  height: 6px;
  width: 6px;
  background: #d8d8d8;
  margin: 0 3px;
  border-radius: 50%;
  transition: margin 100ms, height 100ms, width 100ms;
}

.Dot.active {
  height: 8px;
  width: 8px;
  margin-left: 0 2.5px;
  background: #387be9;
  transition: margin 100ms, height 100ms, width 100ms;
}

@media (--tablet-screen) {
  .SliderArrow {
    left: 20px;

    &.SliderArrow--right {
      right: 12px;
    }
  }
}
