@import './Dropdown.css';

.DropdownRange {
  display: flex;
  align-items: center;

  & button {
    /* Matching Chrome's default button text color to fix color inconsistency between browsers */
    /* TODO should this be the cobrand's "link_on_white" color? */
    color: #000;
  }

  & .LabelWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  & .Label {
    color: text-color;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    padding-bottom: 5px;
  }

  & .DropdownWrapper {
    display: block;
  }

  & .DropdownRangeSeparator {
    margin: 0 8px;
  }

  & .DropdownRangeSeparatorWithMarginTop {
    margin-top: 20px;
  }

  & .DropdownRangeMin,
  & .DropdownRangeMax {
    min-width: 100px;
    width: 140px;

    & .DropdownListButton {
      border-radius: 4px;
      border: 1px solid #999;
      height: 34px;
      width: 140px;
    }

    & .DropdownButtonIcon {
      border-right: 2px solid #949494 !important;
      border-bottom: 2px solid #949494 !important;
    }
  }
}
