/* NOTE: disabled this stylelint rule for the entire file as the nesting in here is a bit unruly.
 * In the future we can take another stab at reorganizing it to stylelint's liking. */
/* stylelint-disable no-descending-specificity */
@import './_breakpoints.css';
@import './_constants.css';
@value border-color-light,
ch-green,
border-color-lightest from './_colors.css';
@value box-shadow-soft,
square-border from './_fontsAnimations.css';

:root {
  --mapsSearchBottomLayerGroupsHeight: 310px;
}

/* Contains the list of groups, displayed at map top */
/* Z-index should be placed on .LayerGroupsControlInner since that actually contains the controls, while
 * this element just positions the controls */
.LayerGroupsControl {
  display: flex;
  justify-content: flex-start;
  pointer-events: auto;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
}

.LayerGroupsControlInner {
  display: flex;
  height: 52px;
  box-shadow: box-shadow-soft;
  background: #fff;
  border-radius: square-border;
  cursor: pointer;
  z-index: 2;
}

.LayerGroupsControlButton {
  display: flex;
  flex: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-weight: 200;
  font-size: 14px;
  cursor: pointer;
  padding: 0 18px;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  background: transparent;
  /* Matching Chrome's default button text color to fix color inconsistency between browsers */
  /* TODO should this be the cobrand's "link_on_white" color? */
  color: #000;

  &:last-child {
    border-right: 0;
  }

  &:not(:last-child) {
    border-right: 1px solid border-color-light;
  }
}

.LayerGroupsControlButton.locked {
  height: 100%;
  color: #474747;
}

.LockedContentContainer {
  opacity: 0.4;

  & .LockIcon {
    & svg {
      height: 40px;
      width: 40px;

      & path {
        stroke-width: 3px;
      }
    }
  }
}

.LayerGroupsControlButtonLabel {
  display: block;
  padding: 2px;
  box-shadow: 0 0 0 0 ch-green;
  transition: box-shadow 0.1s linear;
  font-weight: 400;
}

.SelectedIndicator {
  transform: translateY(2px);
  width: 100%;
  height: 1px;
  background: rgb(0 0 0 / 0%);
  transition: background 0.2s;
}

/* Contains the list of layer items within the active group and map legend displayed at map bottom */
/* Z-index should be placed on .LayerItemsControlInner since that actually contains the controls, while
 * this element just positions the controls */
.LayerItemsControl {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: auto;
  font-size: 12px;

  & .MapLegend {
    margin-top: 0;
  }
}

/* An alert displayed at map bottom */
.LayerItemsControl.LayerItemsControlAlert {
  height: 80px;

  & .LayerItemsControlInner {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding-right: 40px;

    & svg {
      margin-right: 10px;
    }

    & .LayerItemsControlCloseButton {
      right: 0;
    }
  }
}

.LayerItemsControlInner {
  position: relative;
  padding: 15px 20px;
  display: flex;
  cursor: auto;
  box-shadow: box-shadow-soft;
  background: #fff;
  border-radius: square-border;
  pointer-events: auto;
  z-index: 2;
}

.LayerItemsControlMainColumn {
  flex: none;
  max-width: 100%;

  & .LayerControlRow {
    margin-bottom: 10px;
  }
}

.LayerItemsControlMarkerToggleColumn {
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: -5px;

  &.LayerItemsControlMarkerToggleColumnMonochrome {
    padding: 0 15px 0 18px;
  }

  & .MonochromeLabelWrapper {
    margin: 0 auto 7px;
    text-align: center;
    line-height: 8px;

    & label {
      margin: 0;
    }
  }

  & label {
    margin: 0 auto 7px;
    display: block;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }

  & .HorizontalToggleWrapper {
    align-self: center;
  }
}

.LayerGroupsControl,
.LayerItemsControlInner {
  border-radius: square-border;
  box-sizing: border-box;
}

.LayerItemsControlRow {
  display: flex;

  &.DataAttribution {
    color: inherit;
    font-size: 10px;
    margin-top: 5px;
    justify-content: center;
    align-items: flex-end;
  }
}

.LayerItemsControlLabel {
  font-weight: 600;
  text-align: center;
  font-size: 10px;
  margin-bottom: 2px;
}

.LayerGroupsControlItem {
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
}

.LayerGroupsControlItem:not(:last-child)::after {
  content: '';
  border-right: 1px solid #ddd;
  height: 100%;
}

.LayerGroupsControlItem-label {
  display: inline-block;
  margin: 0 15px;
}

.LayerItemsControlCloseButton {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;

  & svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.Trigger {
  transform: translate(4px, 3px);
  cursor: pointer;

  & svg {
    width: 13px !important;
    height: 13px !important;
  }
}

.TooltipText {
  margin-bottom: 10px;
}

.TooltipIntervalExplanation {
  margin-left: 5px;
}

.TooltipDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & .TooltipLabel {
    width: 90%;
  }
}

.TooltipColorCodes {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin-right: 10px;
}

.TooltipColorCodesHalftone {
  height: 16px !important;
  width: 16px !important;
  min-width: 16px;
  min-height: 16px;
  border-radius: 0 !important;
}

.SchoolsPopupMapboxContainer {
  & :global .mapboxgl-popup-tip {
    display: none;
  }

  & :global .mapboxgl-popup-content {
    background: none;
    box-shadow: none;
    padding: 0;
  }
}

/* Map Legend */
.LegendWrapper {
  width: 300px;
  min-height: 24px;
}

.NoLegendMsg {
  transform: translateY(7px);
  margin: 4px 0;
  color: #666;
}

.NoDataLegend {
  margin-left: 20px;
  display: flex;
  align-items: center;
  transform: translateY(9px);
  font-weight: 300;
  flex: 1;
  justify-content: flex-end;
  white-space: nowrap;
}

.GrayColorTile {
  display: block;
  width: 12px;
  height: 12px;
  background-color: #eee;
  margin-left: 6px;
}

.BottomGroupsControl {
  position: fixed;
  /* Account for search field and header */
  max-height: calc(100vh - 96px);
  left: 0;
  bottom: 0;
  height: auto;
  width: 100vw;
  z-index: 101;
  font-size: 12px;
  font-weight: 100;
  background-color: white;
  border-radius: 0;
  overflow: auto;
  padding: 10px 0;
  box-sizing: border-box;

  & .BottomGroupsControlCloseIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    /* Need to be above the locked content */
    z-index: 3;
  }

  & .BottomGroupsControlRow {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;

    &:not(:last-child) {
      border-bottom: 1px solid border-color-lightest;
    }
  }

  & .LockedComponentContainer {
    &:not(:last-child) {
      border-bottom: 1px solid border-color-lightest;
    }
  }

  & .LockedContentContainer {
    display: flex;
    justify-content: center;
    opacity: 1;

    & .LockedContentButton {
      width: 350px;
      text-align: left;
    }

    & .LockIcon {
      & svg {
        height: 30px;
        width: 30px;

        & path {
          stroke-width: 2px;
        }
      }
    }
  }
}

.BottomGroupsControlRowInner.BottomGroupsControlRowInnerLocked {
  & .HorizontalSelectorButtons,
  & label {
    opacity: 0.4;
  }
}

.BottomGroupsControlRowInner {
  width: 270px;
  white-space: nowrap;
}

.LayerControlRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & .HorizontalSelectorTabs,
  & .HorizontalSelectorButtons {
    flex-wrap: wrap;

    & .SelectedIndicator {
      margin-top: 0;
    }

    & .Option:first-child {
      margin: 0;
    }
  }
}

.ControlGroupLabel {
  flex: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.MapNotification {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 0 15px;

  &.MapNotificationPositionedHigher {
    bottom: 170px;
  }
}

.LegendList {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@media (--small-screen) {
  .LayerGroupsControl {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    max-width: 100vw;
    overflow-x: auto;
    /* Prevent markers from overlapping controls on mobile iOS */
    z-index: 1;
  }

  .LayerGroupsControlInner {
    border-radius: 0;
    box-shadow: none;
    height: 48px;
    padding: 10px 0;
    box-sizing: border-box;
  }

  .LayerGroupsControlButton {
    height: 26px;
  }

  .LayerItemsControlMarkerToggleColumn {
    padding: 0;
    padding-left: 2px;

    &.LayerItemsControlMarkerToggleColumnMonochrome {
      padding: 0;
    }
  }

  .LayerItemsControl {
    bottom: 0;
    width: 100%;
    background-color: white;
    /* We want this above the map and map attribution on mobile, but below the map on desktop */
    z-index: 2;

    &.LayerItemsControlWithMarkersToggle {
      & .LayerItemsControlInner {
        padding-top: 20px;
      }
    }

    & .LayerItemsControlInner {
      width: 100%;
      margin: 0;
      padding: 10px 32px 5px 20px;
      box-shadow: none;
    }

    & .LayerItemsControlMainColumn {
      width: 100%;
    }

    & .LegendWrapper {
      max-width: 69vw;
    }

    & .BottomGroupsControlRowInner {
      margin: 0;
      width: initial;
      flex: 1;
    }

    & .NoDataLegend {
      margin-left: 15px;
    }

    & .NoLegendMsg {
      margin: 0;
    }

    & .LayerItemsControlCloseButton {
      right: 8px;
    }
  }

  .MapNotification {
    bottom: 20px;

    &.MapNotificationPositionedHigher {
      bottom: calc(var(--mapActiveLayerControlHeightMobile));
    }
  }

  .MapNotificationYourTeam {
    bottom: 60px;
  }
}

:export {
  bottomGroupsControlHeight: var(--mapsSearchBottomLayerGroupsHeight);
}
/* stylelint-enable no-descending-specificity */
