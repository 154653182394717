.FlatDivideTabList {
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
}

.FlatDivideTab {
  border-right: 1px solid #ccc;
  border-top: none;
  border-bottom: none;
  border-left: none;
  display: flex;
  font-size: 12px;
  font-weight: 200;
  background: #fff;
  height: 25px;
  justify-content: center;
  padding: 0 29px;
  flex-direction: column;
  cursor: pointer;
  white-space: nowrap;
  /* Matching Chrome's default button text color to fix color inconsistency between browsers */
  /* TODO should this be the cobrand's "link_on_white" color? */
  color: #000;

  &.FlatDivideTabSelected {
    font-weight: 900;
  }
}

.FlatDivideTabSelectedIndicator {
  height: 2px;
  margin-top: 6px;
  width: 100%;
  transition: background 0.2s;
}

.FlatDivideTab:last-child {
  border: none;
}
