@import './_breakpoints.css';

.ShareModal {
  & .CloseIcon {
    transform: scale(0.8);
    right: 17px;
    top: 16px;
  }

  & .CopyIcon,
  & .CopyIcon-svg {
    height: 18px !important;
  }

  & .FieldRow {
    border: none;
    padding: 0;
    margin: 0 0 16px;
  }

  & h3 {
    text-align: center;
    margin-top: 0;
    font-size: 16px;
    font-weight: 900;
  }

  & label,
  & .Label {
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    color: #4a4a4a;
  }

  & textarea {
    height: 106px;
  }

  & .Modal {
    padding: 20px 30px 30px;
    max-width: 360px;
  }

  & .InputElement,
  & .CopyableField {
    width: 274px;
  }

  & .InputElement {
    padding: 12px;
    border-color: #c2c2c2;
    border-radius: 8px;
  }

  & .InputElement::placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  & .ErrorMessage {
    text-align: left;
    font-weight: 400;
    font-size: 8px;
    line-height: 11px;
    color: #b10234;
    height: 11px;
  }

  & .FormErrorIcon > svg {
    height: 12px;
    width: 12px;
  }

  & .CopyableFieldSection {
    padding: 24px 0 0;
    margin-top: 24px;
    border-top: 1px solid #e2e2e2;
  }

  & .SendButton {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  & .TextArea {
    position: relative;

    & .InputElement {
      margin-bottom: 0;
      border-color: #c2c2c2;
      width: 300px;
      margin-top: 26px;
    }
  }

  & .CopyableField {
    height: 44px;
    width: 300px;
    border-color: #c2c2c2;
    border-radius: 8px;

    & .InputWrapper {
      height: auto;
    }

    & .InputElement {
      padding-right: 0;
    }

    & .InputElement,
    & .FlatButtonLabel {
      font-size: 12px;
      border-color: #c2c2c2;
    }

    & .FlatButton {
      width: auto;
      padding-right: 0;
    }
  }

  & .ShareLanguageLegalText {
    margin-top: 16px;
    font-size: 12px;
    line-height: 16px;
    color: #4a4a4a;
  }
}

.ScreenReaderVisuallyHidden {
  composes: screen-reader-only from './_common.css';
}

@media (--small-screen) {
  .ShareModal {
    & .TextArea,
    & .InputElement,
    & .SendButton,
    & .CopyableField {
      width: 100% !important;
      max-width: 100%;
    }
  }
}
