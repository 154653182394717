@import './_breakpoints.css';
@import './_constants.css';
/**
 * For general styles to be shared between CSS Modules with `composes`.
 * Only import the class that is needed by the component, e.g: composes: row from './common.css'
 * Please use this sparingly, preferring to create Components when code needs to be shared.
 */

.auth-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 26px auto;
}

.breakout-section-dialog-common-styles {
  padding: 0 30px 20px;
  margin: auto auto 40px;
}

.details-container {
  position: relative;
  width: 100%;
  min-width: 320px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.font-base {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nullstatetext {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.null-state-icon {
  width: 26px;
  height: 26px;
  padding-right: 15px;
}

.null-state-icon-svg {
  vertical-align: top;
  display: inline;
  position: relative;
  bottom: 3px;
}

.row {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
}

.hide-off-screen {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.strip-button-styles {
  outline: none;
  border: none;
  padding: 0;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  background-color: transparent;
}

.homeowner-page-layout {
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
  width: 100%;
  max-width: var(--header-max-width);
  box-sizing: border-box;
}

.screen-reader-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
} 

@media (--tablet-screen-and-smaller) {
  .HomeownerPageContent {
    padding: 0 15px;
  }
}

@media (--small-screen) {
  .details-container {
    max-width: 100%;
  }

  .homeowner-page-layout {
    min-width: 300px;
  }
}
