@import './_breakpoints.css';
@import './_constants.css';

.WelcomeTransitionPage {
  margin: auto;

  & .ComeHomeLogoImg {
    position: absolute;
    top: 80px;
    width: 400px;
    left: calc(50% - 200px);
  }

  &.WelcomeTransitionPageWithDesktopOverlayRaised {
    & .ComeHomeLogoImg {
      top: 23px;
    }

    & .LargeScreenOverlay {
      position: absolute;
      top: 155px;
      left: calc(50% - var(--welcomeSearchPageAndTransitionOverlayWidth) / 2);
    }
  }

  & .InputElement {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #4a4a4a;
    border-radius: 0;
    color: #0e4c96;
    font-weight: 200;
    padding-left: 0;

    &::placeholder {
      color: #4a4a4a;
      line-height: 28px;
    }
  }

  & .AutoComplete {
    width: calc(100% - 20px);
    margin: 0 auto 30px;

    & .SearchIcon {
      border: none;
      transform: translateX(10px);

      svg :global .element-to-fill {
        fill: #20203f;
      }

      svg :global .element-to-stroke {
        stroke: #20203f;
      }
    }

    &.AutoCompleteWithValue {
      & .InputElement {
        border-bottom: 1px solid #0e4c96;
      }

      & .CloseIcon {
        border: none;
        
        svg {
          transform: translateX(15px);
          height: 24px !important;
          width: 24px !important;
        }

        svg :global .element-to-fill {
          fill: #0e4c96;
        }
      }
    }

    & .Results {
      border: none;
      border-radius: 0;
      padding: 0;
    }

    & .SearchButton {
      border-radius: 0;
    }

    & .ResetButton {
      right: 0;
      top: 0;
      height: 40px;
      width: 50px;
    }
  }

  & .PillButton {
    display: block;
    margin: 0 auto 20px;
  }

  & .Logo {
    display: none;
    justify-content: center;
    width: 210px;
    margin: 0 auto;
    height: 40px;
    transform: translateY(-5px);
  }
}

.TopPhoto {
  position: relative;
  width: 100%;
  height: 540px;
  background-image: url('../assets/images/cobrand/chase/welcome-background.png');
  background-size: auto 100%;
  background-position-y: bottom;
  background-position-x: center;
  background-repeat: no-repeat;
}

/* stylelint-disable no-descending-specificity */
.LargeScreenOverlay {
  border-radius: 4px;
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 14%);
  width: 370px;
  padding: 30px 20px 50px;
  margin: auto;
  transform: translateY(-35px);
  background-color: white;
}
/* stylelint-enable no-descending-specificity */

.WelcomeTo {
  display: none;
  font-size: 16px;
  font-weight: 100;
  text-align: center;
}

.FinePrint {
  font-size: 10px;
  font-weight: 200;
  text-align: center;
  margin: 10px 20px 50px;
}

.AutoCompleteLargeScreen {
  display: block;
}

.AutoCompleteSmallScreen {
  display: none;
}

/* At this width and lower, lock the aspect ratio of the div (shrinking/growing height as necessary)
 * to keep the ComeHome logo visible */
@media (max-width: 1300px) {
  .TopPhoto {
    height: 38vw;
  }
}

/* Need this additional MQ to keep the height of the top photo small enough to that it doesn't push
 * down the search input too much on larger screens */
@media (min-width: 1300px) and (max-width: 1800px) {
  .TopPhoto {
    height: 30vw;
    background-size: 100% auto;
  }
}

@media (--xx-small-screen) {
  .WelcomeTransitionPage {
    padding-top: 20px;

    & .AutoComplete {
      width: calc(100% - 80px);

      & .InputElement {
        font-size: 16px;
      }
    }

    & .Logo {
      display: flex;
    }

    & .TopPhoto {
      width: calc(100% - 40px);
      height: 52vw;
      max-height: 400px;
      margin: 0 20px 15px;
      border-radius: 4px;
      background-image: url('../assets/images/cobrand/chase/welcome-search-background-chase.jpg');
      background-size: 130%;
      background-position-y: bottom;
      background-position-x: center;
    }
  }

  .ComeHomeLogoImg {
    display: none;
  }

  .LargeScreenOverlay {
    border-radius: initial;
    box-shadow: none;
    width: initial;
    padding: 0;
    transform: none;
  }

  .WelcomeTo {
    display: block;
  }

  .AutoCompleteLargeScreen {
    display: none;
  }

  .AutoCompleteSmallScreen {
    display: block;
  }
}
