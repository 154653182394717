@import './_breakpoints.css';
@value border-color-light from './_colors.css';

.LoanQualificationLetter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid border-color-light;
  border-top: 1px solid border-color-light;
  margin: 0 10px 25px;
}

.LoanQualificationContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  padding: 10px;
  margin-left: 5px;
}

.LoanQualificationLetterIconWrapper {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
}

.LoanQualificationLetterIcon {
  display: flex;

  & svg {
    height: 40px;
    width: 36px;
  }

  & :hover {
    cursor: pointer;
  }

  &.LoanQualificationLetterIconEnabled {
    & svg :global .element-to-fill {
      fill: #0b6efd;
    }
  }

  &.LoanQualificationLetterIconDisabled {
    & svg :global .element-to-fill {
      fill: #d7d7d7;
    }
  }
}

.LoanQualificationText {
  font-size: 16px;
  font-weight: 200;

  & .LoanQualificationTextMobile {
    display: none;
  }
}

.SeeLetterButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.InfoIcon {
  cursor: pointer;
  padding: 0 10px;
  position: relative;

  /* stylelint-disable no-descending-specificity */
  & :global svg .element-to-fill {
    fill: #1a77fd;
  }
  /* stylelint-enable no-descending-specificity */
}

.SeeLetterButton {
  background: none;
  border: none;
  padding: 0;
  color: #0b6efd;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  & .ChevronIcon {
    position: relative;
    top: 3px;
    margin-left: 3px;
  }

  & .ChevronIcon-svg {
    transform: rotate(182deg);
    height: auto;
  }
}

.TooltipDescription {
  color: #414042;
  font-size: 12px;
  font-weight: 200;
}

.Tooltip {
  display: inline-flex;
}

.SectionDividerLine {
  margin: 0;
  border-bottom: 1px solid border-color-light;
  border-top: none;
}

@media (--tablet-screen-and-smaller) {
  .LoanQualificationTextDesktop {
    display: none !important;
  }

  .LoanQualificationTextMobile {
    display: flex !important;
  }

  .SeeLetterButton {
    font-size: 14px;
  }

  .LoanQualificationText {
    font-size: 12px;
    font-weight: 200;
  }
}
