@import './_breakpoints.css';
@import './_constants.css';
@value border-color-lightest from './_colors.css';
@value box-shadow-long from './_fontsAnimations.css';

.MultiUnitSelectModalWrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  height: var(--propertyCardHeight);
  min-height: var(--propertyCardHeight);
  width: 100%;
  /* Above map, map control buttons, and Header */
  z-index: 101;
  font-size: 12px;
  font-weight: 100;
}

.MultiUnitSelectModal {
  position: relative;
  width: 100%;
  max-width: 450px;
  height: 100%;
  background-color: white;
  border-radius: 0;
  overflow: hidden;
  box-shadow: box-shadow-long;
  margin: auto;
  padding: 0 12px;
  box-sizing: border-box;
  transition:
    max-width 200ms,
    border-radius 200ms;
  transition-delay: 0s;

  &.MultiUnitSelectModalExpanded {
    max-width: 100%;
    height: 100vh;
    border-radius: 0;
    overflow: auto;
    transition-delay: 100ms;
  }

  & .WatchListActionButton {
    display: block;
    height: 38px;
    width: 45px;
    flex: none;

    & .CircleButton {
      transform: scale(0.6) translateY(0);
      transform-origin: top left;
    }

    & .ButtonLabel {
      display: none;
    }
  }

  & .ListingStatusPillMultiUnit {
    font-size: 11px;
  }
}

.TopBar {
  width: 100%;
  padding: 15px 0;
  height: var(--multiUnitSelectModalTopBarHeight);
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid border-color-lightest;

  & .GroupAddress {
    font-size: 12px;
  }

  & .ModalLabel {
    font-weight: 600;
    color: #aaa;
  }

  & .MobileMapCloseButton {
    background: none;
    border: none;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}

.ScrollableSection {
  height: calc(100% - var(--multiUnitSelectModalTopBarHeight));
  overflow-y: auto;
  overscroll-behavior-y: contain;

  & > div:not(:first-child) {
    border-top: 1px solid border-color-lightest;
    margin-top: 15px;
  }

  & > div:last-child {
    margin-bottom: 20px;
  }
}

.SingleUnit {
  display: flex;
  margin-top: 15px;
  /* Needs to be a fixed vertical height to facilitate lazy-rendering */
  height: var(--multiUnitSelectModalSingleUnitHeight);
  cursor: pointer;

  & .LeftSection {
    width: 80px;
    /* To limit photo, allowing room for prop. details on very small devices */
    max-width: 23%;
  }

  & .RightSection {
    max-width: calc(100% - 23%);
    flex: 1;
    padding: 0 0 0 10px;
  }

  & .PropertyPhoto {
    width: 100%;
    height: 65px;
    margin-bottom: 10px;
  }

  & .UpperSection {
    padding-bottom: 10px;
    border-bottom: 1px solid border-color-lightest;
  }

  & .UnitNumber {
    background: none;
    border: none;
    color: inherit;
    font-size: 15px;
    font-weight: 900;
    margin-bottom: 4px;
    padding: 0;
  }

  & .AvmPriceLabel {
    color: #aaa;
    margin-bottom: 4px;
  }

  & .AvmPrice {
    font-size: 21px;
    font-weight: 900;
  }

  & .AvmPricePlaceholder {
    font-size: 18px;
    font-weight: 400;
    color: #aaa;
  }

  & .LowerSection {
    display: flex;
    padding-top: 5px;
  }

  & .PropertyType {
    font-weight: 600;
    margin-bottom: 4px;
  }

  & .PropertyBrief {
    display: flex;
    white-space: nowrap;
  }

  & .LowerSectionRightSide {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }

  & .MLSLabel {
    font-size: 10px;
    font-weight: 200;
    color: gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 20px);
  }

  & .MlsLogoOverlay {
    position: absolute;
    height: 16px;
    width: 32px;
    bottom: 4px;
    left: 8px;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@media (--small-screen) {
  .MultiUnitSelectModalWrapper {
    position: fixed !important;
  }

  .MultiUnitSelectModal {
    max-width: 100%;
  }
}

:export {
  modalCollapsedHeight: var(--propertyCardHeight);
  singleUnitHeight: var(--multiUnitSelectModalSingleUnitHeight);
}
