@import './_breakpoints.css';

.HcAvmExplanation {
  font-size: 10px;
  letter-spacing: 0;
  line-height: 14px;
  padding: 0 15px;
  margin-top: 10px;
  text-align: justify;

  &.NoAvmFactors {
    padding: 0 20px;
    margin-top: 10px;
  }

  & .LearnMoreAside {
    cursor: pointer;
    display: inline-block;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 14px;
    margin-left: 5px;
    text-decoration: underline;
  }
}

.TooltipContentScrollableContainer {
  max-height: calc(100vh - 200px);
  overflow: auto;
  padding-right: 8px;
  -webkit-overflow-scrolling: touch;
}

@media (--tablet-screen) {
  .HcAvmExplanation {
    padding: 0 10px;
    text-align: justify;

    &.NoAvmFactors {
      padding: 0 10px;
      text-align: center;
    }
  }
}

@media (--small-screen) {
  .HcAvmExplanation {
    padding: 0 16px;
    text-align: justify;
  }
}

@media (--xxx-small-screen) {
  .HcAvmExplanation {
    padding: 0 10px;
    text-align: justify;
  }
}
