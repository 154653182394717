@value text-color from './_colors.css';

.TooltipTitle {
  font-weight: 900;
  font-size: 16px;
  line-height: 1.35em;
}

.TooltipPortalDuplicateTrigger {
  position: fixed;
  opacity: 0;
  /* Above the content wrapper */
  z-index: 102;
  /* top and left positioning set by JS to match viewport positioning of tooltip trigger */
  cursor: pointer;
}

.TooltipContent {
  display: flex;
  position: relative;
  /* Initially hide, to be shown after we check the dimensions and reposition
     completely within the viewport if necessary */
  visibility: hidden;
  flex-wrap: wrap;
  background-color: white;
  border: none;
  border-radius: 4px;
  padding: 20px;
  font-size: 14px;
  font-weight: 100;
  box-sizing: border-box;
  line-height: 1.2rem;
  text-align: left;
  max-width: calc(100vw - 40px);
  color: inherit;

  &.TooltipContentWithCloseIcon {
    padding: 20px 30px 20px 20px;
  }
}

.TooltipPortalContentWrapper {
  position: fixed;
  /* Above all other page elements and modals */
  z-index: 104;
  overscroll-behavior-y: contain;

  /* top and left positioning set by JS to match viewport positioning of tooltip trigger */

  & .TooltipContent {
    visibility: visible;
  }
}

/* The preferred usage is for this component's parent element to be "display: flex; align-items: center;" */
.Tooltip {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: 0;
  z-index: auto;
}

.Trigger {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: transparent;
  font-size: inherit;
  color: inherit;
  /* Above .TooltipContentWrapper */
  z-index: 3;
  /* This will capture the onMouseLeave when the tooltip is open */
  pointer-events: auto;
}

.TooltipArrow {
  position: absolute;
  left: calc(50% - 10px);
  font-size: 16px;
  width: 10px;
  transform: scaleX(1.5);
  border: none;
  line-height: 8px;
  color: white;
}

.TooltipContentWrapper {
  color: text-color;
  position: absolute;
  /* Above .TooltipScreen */
  z-index: 2;
  display: flex;
  justify-content: center;

  &.TooltipContentWrapperPositionBottom {
    top: 100%;

    & .TooltipContent {
      transform: translateY(15px);
    }

    & .TooltipArrow {
      bottom: 100%;
      text-shadow: 0 2px 0 #fff;
    }
  }

  &.TooltipContentWrapperPositionTop {
    bottom: 100%;

    & .TooltipContent {
      transform: translateY(-15px);
    }

    & .TooltipArrow {
      top: 100%;
      text-shadow: 0 -2px 0 #fff;
    }
  }
}

/* This classname is used in a querySelector call to select the element, don't remove */
.TooltipContentScrollableContainer {
  z-index: 1;
}

.InfoIcon {
  display: flex;
  align-items: center;
}

.TooltipScreen {
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: rgb(0 0 0 / 10%);
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  pointer-events: auto;
}

.TooltipScreenAutoClose {
  /* Target non-touchscreen devices */
  @media (pointer: fine) {
    /* allow onMouseLeave to fire on tooltip trigger */
    pointer-events: none;
  }
}

.CloseIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: none;
  padding: 0;

  & svg {
    width: 20px !important;
    height: 20px !important;
  }
}

/* Transparent background, drop shadow on tooltip */
.TooltipPortalContentWrapperWithTransparentScreen {
  & .TooltipScreen {
    background-color: rgb(0 0 0 / 0%);
  }

  &
    .TooltipContentWrapper.TooltipContentWrapperPositionBottom
    .TooltipArrow
    .ArrowText {
    text-shadow:
      0 2px 0 #fff,
      0 -2px 4px #efefef;
  }

  &
    .TooltipContentWrapper.TooltipContentWrapperPositionTop
    .TooltipArrow
    .ArrowText {
    text-shadow:
      0 -2px 0 #fff,
      0 2px 4px #efefef;
  }

  & .TooltipContentWrapper .TooltipContent {
    box-shadow: -2px 2px 10px #dadada;
  }
}
