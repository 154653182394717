@import './_breakpoints.css';

.AdBlockMessageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  height: 3.3125rem;

  & .WarningIcon {
    width: 22px;
    height: 20px;
    padding: 5px;
  }

  & .MessageStyling {
    color: #908f8f;
    font-size: 16px;
    font-weight: 900;
  }
}

@media (--small-screen) {
  .AdBlockMessageContainer {
    padding: 10px;
  }
}
