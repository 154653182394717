/**
 * Please don't change any styles here unless you're sure we need the changes across all PillButtons app-wide
 */
.PillButton {
  font-size: 16px;
  font-weight: 900;
  border: 0;
  border-radius: 8px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  width: 100%;
  min-height: 36px;
  max-width: 311px;
  transition: box-shadow 300ms, background-color 300ms, color 300ms;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.PillButton.Small {
  width: 167px;
  font-size: 14px;
}

.PillButton.Disabled {
  cursor: not-allowed;
}

/* Creates a horizontal transition to darker color */
.PillButton::before {
  content: '';
  position: absolute;
  width: 0%;
  height: 100%;
  background-color: rgb(0 0 0 / 40%);
  top: 0;
  left: 0;
  z-index: 0;
}

.PillButton.isAnimating::before {
  width: 100%;
  animation-duration: 750ms;
  animation-timing-function: cubic-bezier(0.22, 0.67, 1, 0.67);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: animate-width;
}

@keyframes animate-width {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

/*
 * NOTE: This keeps string text above background animation so that the
 * text stays the same color. For children that are not a string,
 * this will need to be manually handled:
 */
.PillButton .AnimChildWrapper {
  position: relative;
  z-index: 1;
}
