@import './_breakpoints.css';
@value ch-black from './_colors.css';

.InputWrapper {
  & .InputElement {
    margin-top: 22px;
    height: 19px;
    border: 1px solid #b9b9b9;
    border-radius: 6px;
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: 300;
    padding: 12px 17px;
  }

  & .Label {
    color: ch-black;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    top: 0;
    left: 0 !important;
    cursor: pointer;
  }

  & .Error {
    margin-top: -8px;
    padding: 0;
    padding: 0 0 14px;
  }
}

@media (--small-screen) {
  /* Necessary to prevent mobile devices from zooming into the input when focused */
  .InputWrapper .InputElement {
    font-size: 16px !important;
  }
}
