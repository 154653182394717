@value faded-grey, text-color, ch-red from './_colors.css';

.InputWrapper {
  position: relative;
  font-size: 15px;
  font-weight: 200;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.InputElementContainer {
  display: flex;
  flex-wrap: wrap;
}

input.InputElement {
  height: 20px;
}

.InputElement {
  border: 0.0625rem solid #dedede;
  padding: 10px;
  position: relative;
  height: 20px;
  font-size: 15px;
  font-weight: 500;
  margin: 26px 0 7px;
  font-family: inherit;
  width: 100%;
  color: text-color;
  /* Don't allow webkit browsers to override our styling with theirs */
  appearance: none;

  &:hover {
    appearance: none;
  }
}

.InputElement:focus {
  outline: none;
}

.TogglePasswordVisibilityButton {
  position: absolute;
  top: 23px;
  right: 0;
  z-index: 1;
  width: 48px;
  height: 43px;
  padding: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
}

.InputWithVisibleLabel {
  padding-bottom: 0;
}

.OffScreenLabel {
  composes: hide-off-screen from './_common.css';
}

.Label {
  position: absolute;
  left: 1px;
  top: 0;
  font-size: 13px;
  font-weight: 500;
  color: text-color;
}

/* hovering label on top of text input */
.HasVisibleLabel {
  left: 11px;
  color: gray;
  font-size: 10px;
}

.CharacterCount {
  font-size: 12px;
  color: faded-grey;
}

.Error {
  color: ch-red;
  font-size: 12px;
  align-self: start;
  composes: font-base from './_common.css';
}
