@import './_breakpoints.css';
@import './_constants.css';

.SavedSearch {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.MapContainer {
  width: 52%; /* Use percentage so width is consistent for all screen sizes*/
  margin-right: 30px;
  border: 1px solid #f0f0f0;
  border-radius: 14px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: var(--savedSearchPageCardMapTileHeight);
  height: 100%;
}

.MobileCloseIcon {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
}

.SearchDetails {
  width: 48%; /* Use percentage so width is consistent for all screen sizes*/
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 10px;

  & .SearchName {
    font-size: 21px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 15px;

    & .MobileCloseIcon {
      display: none;
    }
  }

  & .SavedSearchFilters {
    flex-grow: 1;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 16px;
  }

  & .LoadHomesButton {
    width: 311px;
  }
}

.CloseIconContainer {
  align-items: flex-end;
  height: 22px;
  width: 22px;
  cursor: pointer;
}

.CloseIcon .CloseIcon-svg {
  height: 22px !important;
  width: 22px !important;
}

.MobileSeparator {
  display: none;
}

.DesktopAndTabletSeparator {
  display: block;
  border-bottom: 1px solid #f0f0f0;
  margin: 20px 0;
}

@media (--small-screen) {
  .SavedSearch {
    display: flex;
    flex-direction: column;
  }

  .MobileSeparator {
    display: block;
    border-bottom: 1px solid #f0f0f0;
    margin: 20px 0;
  }

  .DesktopAndTabletSeparator {
    display: none;
  }

  .MapContainer {
    width: 100%;
  }

  .SearchDetails {
    margin-right: 0;
    width: 100%;

    & .SearchName {
      font-size: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 15px;

      & .MobileCloseIcon {
        display: block;
        cursor: pointer;
      }
    }

    & .SavedSearchFilters {
      margin-bottom: 8px;
    }

    & .LoadHomesButton {
      width: 100%;
      /* Need to override the max-height defined in cobrand-styles.ts here for mobile
       * for all cobrands */
      max-width: 370px !important;
      margin: 10px auto;
    }
  }

  .CloseIconContainer {
    display: none;
  }
}

:export {
  savedSearchPageCardDesktopMapTileWidth: var(
    --savedSearchPageCardDesktopMapTileWidth
  );
  savedSearchPageCardTabletMapTileWidth: var(
    --savedSearchPageCardTabletMapTileWidth
  );
  savedSearchPageCardMobileMapTileWidth: var(
    --savedSearchPageCardMobileMapTileWidth
  );
  savedSearchPageCardMapTileHeight: var(--savedSearchPageCardMapTileHeight);
}
