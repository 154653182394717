.MapLegend {
  display: flex;
  position: relative;
  width: 100%;
  height: 14px;
  margin-top: 15px;
  align-items: center;
}

.LegendItemLabel {
  line-height: 14px;
  text-align: center;
  white-space: nowrap;
  flex: none;
  font-size: 12px;
  font-weight: 300;
}

.LegendItemLabel:first-child {
  padding-right: 10px;
  text-align: right;
}

.LegendItemLabel:last-child {
  padding-left: 10px;
  text-align: left;
}

.NoDataPlaceholder {
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background: repeating-linear-gradient(
    45deg,
    #e8e8e8,
    #e8e8e8 10px,
    #f0f0f0 10px,
    #f0f0f0 20px
  );
}
