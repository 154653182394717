@value border-color-light, text-color from './_colors.css';

.RangeSlider {
  display: flex;
  align-items: center;

  & .RightSideLabel {
    font-size: 11px;
    font-weight: 900;
    margin-left: 15px;
    margin-left: 0;
    transform: translateX(15px);
  }

  & :global .input-range__label-container {
    background-color: #fff;
    padding: 0 5px;
  }

  /* The label above the slider button */
  & :global .input-range__label {
    display: none;
    color: inherit;
    font-size: 11px;
    font-weight: 900;
    font-family: inherit;
  }

  & :global .input-range__label--value {
    top: -30px;
  }

  /* The draggable button */
  & :global .input-range__slider {
    height: 26px;
    width: 26px;
    box-sizing: border-box;
    margin-left: -13px;
    background: white;
    border: 2px solid border-color-light;
    box-shadow: 2px 3px 5px #ccc;
  }

  /* The entire background track */
  & :global .input-range__track {
    display: flex;
    background: white;
    height: 6px;
    border-radius: 3px;
    box-shadow: 0 1px 6px #b7b7b7;
  }

  & :global .input-range__slider-container .input-range__label {
    display: inline;
  }

  & :global .input-range__slider-container .input-range__label--value {
    transition: transform 0.3s;
  }

  /* The empty, inactive portion of the track */
  & :global .input-range__track:not(.input-range__track--active)::after {
    content: '';
    flex: 1;
    background: white;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  /* The active, filled portion of the track */
  & :global .input-range__track--active {
    flex: none;
    background: text-color;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    box-shadow: 0 1px 6px #b7b7b7;
  }
}
