@import './_breakpoints.css';

.WatchListActionButton {
  height: 110px;
  font-size: 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  & .CircleButton {
    width: 56px;
    height: 56px;
    overflow: hidden;
    position: relative;
  }

  & .HeartIcon,
  & .CustomIcon,
  & .HeartIcon svg {
    display: block;
    width: 25px;
    height: 25px;
    transform: translateY(1px);
  }

  & .CustomIcon {
    margin-top: -4px;
  }
}

.ScreenReaderVisuallyHidden {
  composes: screen-reader-only from './_common.css';
}
