@import './_constants.css';

.SearchMapPropertyCard {
  position: fixed;
  bottom: 20px;
  /* Above map, map control buttons, and Header */
  z-index: 101;
  top: auto;
  left: 10px;
  right: auto;
  width: calc(100vw - 20px);
  height: var(--propertyCardHeight);
}

/* Give this the appearance of any empty PropertyCard with a spinner in the middle */
.LoadingSection {
  height: 100%;
  background: white;
  border-radius: 13px;
  max-width: var(--propertyCardWidth);
  margin: auto;
}

:export {
  propertyCardHeight: var(--propertyCardHeight);
}
