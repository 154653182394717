.HorizontalSelectorButtons {
  display: flex;
  cursor: pointer;

  &.HorizontalSelectorWithDefaultDivider .Option:not(:last-child)::after {
    content: '•';
    font-weight: 900;
    margin: 2px 0 0 10px;
  }
}

.Option {
  display: flex;
  justify-content: center;
  border: 0;
  background: transparent;
  position: relative;
  padding: 0;
  margin: 0 0 0 10px;
  font-size: 11px;
}

.Option:last-child .option-divider-wrapper {
  display: none;
}

.OptionLabelBlock {
  display: inline-block;
  line-height: 22px;
  cursor: pointer;
  font-weight: 200;
}

.SelectedIndicator {
  margin-top: 2px;
  width: 100%;
  height: 1px;
  background: rgb(0 0 0 / 0%);
  transition: background 0.2s;
}
