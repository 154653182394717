@value hover-grey, border-color from './_colors.css';

:root {
  --ease-timing: 60ms;
}

.ButtonMenuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  background: #fff;
  max-height: 42px;
  padding: 0 8px;
  box-sizing: border-box;
  border: 1px solid border-color;
  cursor: pointer;
  font-size: 14px;
  font-weight: 900;
  border-radius: 4px;
  color: #4a4a4a;
  line-height: 34px;
  white-space: nowrap;
  /* To prevent changing button labels from shifting the "More Filters" toolip dialog */
  min-width: 146px;

  &.Active {
    background-color: hover-grey;
    border: 1px solid hover-grey;
  }
}

.ButtonMenuButton:hover {
  background-color: hover-grey;
  border: 1px solid hover-grey;
  transform: none;
  box-shadow: none;
  transition: background var(--ease-timing) ease-in-out,
    var(--ease-timing) ease-in-out, border var(--ease-timing) ease-in-out;
}
