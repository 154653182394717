@value text-color from './_colors.css';

:root {
  --spacing: 0.375rem;
  --neg-spacing: calc(-1 * var(--spacing));
}

.MenuIcon {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: content-box;
  background: none;
  border: none;
  padding: 0;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

.MenuIconLine {
  overflow: visible;
  position: relative;
  box-sizing: border-box;
}

.MenuIconLine,
.MenuIconLineChild {
  height: 2px;
  width: 20px;
  background-color: text-color;
  transition: transform 100ms;
}

.MenuIconLineChild {
  content: '';
  position: absolute;
}

.MenuIconLineChild:first-child {
  transform: translateY(var(--neg-spacing));
}

.MenuIconLineChild:last-child {
  transform: translateY(var(--spacing));
}

.MenuIconLine.active {
  transform: rotate(-45deg);
}

.MenuIconLine.active .MenuIconLineChild {
  transform: rotate(90deg);
}
