.HomepageArrowNavButton {
  display: flex;
  position: fixed;
  width: 30px;
  height: 18px;
  margin: 30px auto;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.HomepageArrowNav.InvertColors {
  & svg > path {
    fill: #fff;
  }
}

.HomepageArrowNavButton.UpArrow {
  transform: rotate(180deg);
}
