@import './_breakpoints.css';
@import './_constants.css';
@value border-color-light from './_colors.css';

:root {
  --breakoutSectionPreviewMinHeight: 102px;
  --breakoutSectionPreviewMarginBottom: 20px;
}

.BreakoutSections {
  composes: details-container from './_common.css';
  margin: 50px auto;
  padding: 0;
}

.BreakoutSectionsInner {
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-flow: column wrap;
  padding: 0 50px 0 0;

  & .PreviewSectionCaratIcon svg {
    transform: translateX(2px);
  }

  & .LockedComponentContainer {
    & .LockedContentContainer {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      & .LockedContentButton {
        display: flex;
        justify-content: flex-start;

        & .LockIcon {
          & svg {
            height: 30px;
            width: 30px;
          }
        }
      }
    }
  }

  .disabled {
    opacity: 0.7;
  }

  .PreviewSectionBody.disabled {
    filter: blur(2px);
  }
}

.HighlightedValue {
  font-weight: 600;
}

.PreviewSection {
  display: flex;
  margin-bottom: var(--breakoutSectionPreviewMarginBottom);
  font-size: 13px;
  font-weight: 200;
  min-height: var(--breakoutSectionPreviewMinHeight);
  margin-right: 80px;
  width: calc(calc(100% / 2) - 40px);
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-align: left;
  color: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

.PreviewSection:not(.ConsolidatedNullState) {
  /* Prevent from growing too large due to too much text content returned from API,
   * as this can cause sections to be pushed down and hidden completely */
  max-height: 100px;
}

.PreviewSection:not(:last-child) {
  border-bottom: solid 1px border-color-light;
  padding-bottom: 20px;
}

.PreviewSectionNullStateIcon {
  composes: null-state-icon from './_common.css';
}

.PreviewSectionColumn {
  flex: 1;
  padding-right: 15px;
}

.PreviewSectionTitle {
  margin-bottom: 12px;
  margin-top: 0;
}

.PreviewSectionBody {
  line-height: 16px;
}

.PreviewSectionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 60px;
  height: 60px;
  margin: 5px 5px 0 0;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

.SaleHistoryStatus {
  font-weight: 600;
}

.SchoolsSummary {
  & :global .distance,
  & :global .belowAverage,
  & :global .average,
  & :global .aboveAverage,
  & :global .best {
    font-weight: 600;
  }
}

.ConsolidatedNullState {
  display: flex;
  cursor: auto;

  & .ConsolidatedNullStateLeftSide {
    flex: none;
    margin-right: 15px;
  }

  & .ConsolidatedNullStateRightSide {
    padding-top: 4px;
  }

  & .ConsolidatedNullStateEmphasized {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 4px;
  }

  & .ConsolidatedNullStateValueList {
    font-size: 13px;
    font-weight: 200;

    & ul {
      margin: 0;
      padding: 0 18px;
    }

    & li {
      line-height: 17px;
    }
  }

  & .ConsolidatedNullStateAVMRange {
    font-size: 12px;
    margin-top: 15px;
    font-weight: 100;
  }
}

@media screen and (max-width: 1100px) {
  .BreakoutSectionsInner {
    padding-right: 40px;
    /* Override JS inline style */
    height: initial !important;
  }

  .BreakoutSections .BreakoutSectionDialog {
    justify-content: center;
  }

  .PreviewSection,
  .LockedComponentContainer {
    /* Causes sections to be in a single column */
    width: 100%;
    margin-bottom: 20px;
    min-height: 65px;
  }

  .PreviewSection:last-of-type {
    padding-bottom: 20px;
    margin-bottom: 0;
  }

  .PreviewSection:last-child {
    border-bottom: 1px solid border-color-light;
  }

  .ConsolidatedNullState {
    border-top: none;
    margin-top: 20px;
  }
}

@media (--small-screen) {
  .BreakoutSections {
    padding-right: 20px;
    margin-bottom: 40px;
    margin-top: 30px;
  }

  .BreakoutSectionsInner {
    padding-right: 0;
    padding-left: 20px;
  }
}

:export {
  previewSectionMinHeight: var(--breakoutSectionPreviewMinHeight);
  previewSectionMarginBottom: var(--breakoutSectionPreviewMarginBottom);
}
