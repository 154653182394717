@import './_breakpoints.css';
@import './_constants.css';
@value ch-blue, border-color,border-color-light, hover-grey, light-grey from './_colors.css';
@value box-shadow-soft, box-shadow-property-card from './_fontsAnimations.css';

:root {
  /* The difference between these two provides room for a scrollbar on Windows browsers */
  --mapsSearchSidebarLeftPadding: 40px;
  --mapsSearchSidebarTotalPadding: 60px;
  --ease-timing: 60ms;
}

.SidebarSectionInner {
  width: 100%;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  /* We don't need explicit padding on the right-side, since we'll have left-over space provided by the
   * sidebar's width which accounts for `sidebarHorizontalPadding`. Explicit padding is more risky since
   * we don't know the exact widths of various browsers' scrollbars. */
  padding: 8px 0 0 var(--mapsSearchSidebarLeftPadding);

  & .NoResultsNotice {
    width: 400px;
    max-width: calc(100% - 80px);
    min-width: 200px;
    padding: 150px 20px 0;
    margin: 0 auto 20px;
  }
}

.SearchPageMap {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  user-select: none;
  position: relative;
  /* No z-index here since this element needs to be below the .Header but content within might
   * need to be above the .Header */
  & h1 {
    font-size: 0;
    width: 1px;
    height: 1px;
    display: inline-block;
    overflow: hidden;
    position: absolute !important;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
  }

  & .SidebarSectionInner {
    & .PropertyCard {
      height: var(--propertyCardHeight);
    }
  }
}

.TopBar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  & .TopBarRowAbove {
    display: flex;
    align-items: center;
    padding: 8px 8px 10px;
  }

  & .TopBarRowBelow {
    display: flex;
    justify-content: space-between;
    padding: 0 35px 5px 18px;
    margin-right: 28px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    /* Above .ResizableContainer */
    z-index: 2;

    /* stylelint-disable block-no-empty */
    & .SortButton {
    }
    /* stylelint-enable block-no-empty */

    & :disabled {
      color: #d8d8d8;
      cursor: not-allowed;
    }
  }

  & .AutoComplete {
    width: 100%;
    margin: 0 10px;
    height: 40px;
    max-width: 375px;

    & .InputElement,
    & .Results {
      border-color: border-color;
      width: 375px;
    }

    & .Results {
      border-top: 1px solid border-color-light;
      top: 40px;
    }
  }
}

.DropdownSort button {
  /* Matching Chrome's default button text color to fix color inconsistency between browsers */
  /* TODO should this be the cobrand's "link_on_white" color? */
  color: #000;
}

.HudLogoContainer {
  margin-bottom: 40px;
}

/* stylelint-disable no-descending-specificity */
.SortButton {
  color: inherit;
  min-width: 100px;
  margin: 0 5px;
  background: #fff;
  max-height: 41px;
  height: 41px;
  padding-top: 11px;
  padding-bottom: 11px;
  box-sizing: border-box;
  border: 1px solid border-color;
  border-radius: 3px;
  font-weight: 900;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.SortButton,
.SortButton:hover {
  color: inherit;
  box-shadow: none;
  transition:
    background var(--ease-timing) ease-in-out,
    var(--ease-timing) ease-in-out,
    border var(--ease-timing) ease-in-out;
}

.SortButton:hover {
  background-color: hover-grey;
  border: 1px solid hover-grey;
  transform: none;
}

.SortButton.active {
  background-color: light-grey;
  border: 1px solid light-grey;
}
/* stylelint-enable no-descending-specificity */

.SortButtonIcon,
.SortButtonIcon-svg {
  height: 12px;
}

.SortButtonIcon {
  position: relative;
  bottom: 2px;
}

.ShowMapIcon,
.ShowMapIcon-svg {
  height: 18px;
}

.SortButtonIcon,
.SortButtonIcon-svg,
.ShowMapIcon,
.ShowMapIcon-svg {
  width: 16px;
}

.ShowMapIcon,
.SortButtonIcon {
  display: inline-block;
  margin-right: 20px;
}

.MobileButtonsPositioner {
  position: absolute;
  z-index: 2;
  bottom: 38px;
  right: -2px;
  transform: translateY(0);
  transition: transform 200ms;
}

.MobileButtonsPositionerYourTeam {
  bottom: 60px;
}

.MobileButtonsPositioner.positionedHigher {
  transform: translateY(-98px);
}

.MobileButtons {
  display: flex;
  flex-direction: column;

  & .MobileButton {
    color: inherit;
    background-color: white;
    border-radius: 10px 0 0 10px;
    box-shadow: 0 4px 30px 0 rgba(0 0 0 20%);
    min-width: var(--mobileControlButtonWidth);
    font-size: 9px;
    display: flex;
    align-items: center;
    padding: 30px 0 8px;
    justify-content: center;
    font-weight: 600;
    max-height: initial;
    z-index: 6;
    margin-top: 16px;
    position: relative;

    &:focus {
      outline: auto;
    }

    &:hover {
      color: inherit;
      /* over-ride default button style */
      transform: translateY(0);
      background-color: white;
      box-shadow: 0 4px 30px 0 rgb(0 0 0 / 20%);
    }

    & .FlatButtonLabel {
      font-size: 9px;
    }
  }
}

.MobileButton.SaveSearch {
  &.isInactive {
    cursor: not-allowed;
  }
}

.MobileButtonIcon {
  position: absolute;
  bottom: 27px;
  right: 17px;
}

.MobileButtonIcon,
.MobileButtonIcon-svg {
  height: 13px;
  width: auto;
}

.MobileButtonIcon.Spinner,
.MobileButtonIcon-svg.Spinner-svg {
  height: 26px;
  width: auto;
}

.MobileButton.SaveSearch .MobileButtonIcon {
  bottom: 25px;
  right: 19px;
}

.MobileButton.SaveSearch .MobileButtonIcon.Spinner {
  bottom: 20px;
  right: 12px;
}

.MainContent {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  justify-content: space-between;
  position: relative;
}

.MobileDisclaimerFairHousingWrapper {
  display: none;
}

.ResizableContainer {
  position: relative;
  z-index: 1;
  height: 100%;
  width: calc(
    calc(var(--propertyCardWidth) * 4) + var(--mapsSearchSidebarTotalPadding)
  );
  overflow: visible;
  display: flex;
  flex-direction: column;

  & li {
    & .ConnectWithATopLocalAgentContainer {
      width: calc(var(--propertyCardWidth) - 15px);
      height: var(--propertyCardHeight);
      position: inherit;
      margin: 0;
      padding: 33px 14px 32px 33px;
      box-shadow: none;
      transition: box-shadow 0.2s;

      &:hover {
        box-shadow: box-shadow-property-card;
      }

      & .Description {
        font-size: 35px;
      }
    }
  }
}

.MapSection {
  flex: 1;
  height: 100%;
  position: relative;
  /* Required since the .MapContainer can't have overflow due to controls placed outside of it */
  overflow: hidden;
  transition: filter 400ms;
  /* Slightly off-white to show separation during SSR */
  background: #f9f9f9;
  /* Setting to match the map's baselayer background color so that you don't see a white area when
   * the bottom nav temporarily hides, like when the mobile filters are open */
  background-color: #faf5ec;

  & :global .mapboxgl-ctrl-bottom-right {
    right: 0;
    bottom: -2px;
    /* Above the No MLS map notification */
    z-index: 3;
  }

  /* Zoom controls */
  & :global .mapboxgl-ctrl-top-right {
    top: initial;
    bottom: 40px;
    z-index: 1;
  }
}

.FiltersSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 0 0 20px;
  margin: 0 0 20px;
  box-sizing: border-box;
  z-index: 2;
  position: relative;
  background-color: white;
}

/* This required for the filters to have a max-width in IE11. */
.FiltersSectionInner {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 700px;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden;
}

.SidebarContents {
  display: flex;
  flex-wrap: wrap;
}

.SidebarNotification {
  width: 100%;
  display: flex;
  padding: 0 30px;
  box-sizing: border-box;

  /* Required for IE11 */
  & .SidebarNotificationInner {
    margin: 5px auto 20px;
    padding: 10px 20px;
    border-radius: 4px;
    box-shadow: box-shadow-soft;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & svg {
    margin-right: 20px;
  }
}

.SidebarNotificationBottom {
  /* stylelint-disable-next-line value-keyword-case */
  composes: SidebarNotification;
  margin: 20px auto 40px;
}

.SidebarDisclaimerFairHousingWrapper {
  padding: 0 38px 0 40px;
  text-align: right;
  display: block;
}

.PropertyCount {
  font-weight: 600;
  font-size: 14px;
  margin-right: 20px;
}

.NullStateCard {
  position: fixed;
  top: 140px;
  width: calc(100vw - 50px);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;

  & .InnerWrapper {
    padding: 20px;
  }

  & .Title {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 600;
  }
}

.ModalAlertUserIcon svg {
  width: 30px;
  margin-bottom: 10px;
}

.ModalGreenCheckIcon svg {
  width: 70px;
}

.SeeMoreLink {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-decoration: none !important;
  color: inherit;
  margin: 0 0 1.25rem;
  font-size: 0.875rem;
  padding: 1em 1.25em;
  font-weight: 900;
}

.ClearSearchConstraintLink {
  color: ch-blue;
  cursor: pointer;
  display: block;
  margin-top: 20px;
}

.MobileActiveFiltersCount {
  position: absolute;
  top: 4px;
  left: 16px;
  font-size: 12px;
  font-weight: 100;
  height: 23px;
  line-height: 23px;
  color: white;
  width: 23px;
  border-radius: 100%;
  text-align: center;
}

/* Ensures that the footer is always at screen-bottom within the scrollable container */
.MinHeightWrapper {
  min-height: calc(100vh - 480px);
}

.LoadMoreResultsBorder {
  margin-right: 30px;
  border-bottom: 1px solid #d7d7d7;
}

.SidebarSectionTopBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  padding: 0 38px 0 40px;

  & .DropdownSortOptionsListWrapper {
    right: initial;
    left: 0;
  }

  & .PropertyCount {
    font-size: 10px;
    font-weight: inherit;
    margin-right: 0;
  }
}

.SortButtonBorderless {
  display: flex;
  padding: 10px;
  cursor: pointer;
  font-size: 10px;
  background: transparent;
  border: 0;

  &.SortButtonDisabled {
    color: #ccc;

    & svg :global .element-to-stroke {
      stroke: #ccc;
    }
  }

  & .SortButtonIcon {
    margin-right: 10px;
    height: 10px;
    width: 14px;
  }

  & svg {
    transform: translateY(4px);
  }
}

.LocationIcon {
  /* place on top of map canvas and behind map layer controls */
  z-index: 1;
  position: absolute;
  background: #fff;
  right: 10px;
  bottom: 130px;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }

  & span svg {
    vertical-align: middle;
    height: 18px !important;
    width: 18px !important;
  }
}

.BrokerageWrapper {
  display: flex;
  justify-content: center;
  padding: 0 38px 0 40px;
  overflow: hidden;
  min-width: 276px;
}

@media (--x-small-screen) {
  .AutoComplete {
    width: calc(100% - 14.375rem); /* 100% - 230px */
    max-width: 580px;
  }
}

@media screen and (max-width: 2300px) {
  .ResizableContainer {
    width: calc(
      calc(var(--propertyCardWidth) * 3) + var(--mapsSearchSidebarTotalPadding)
    );
  }
}

@media screen and (max-width: 1800px) {
  .ResizableContainer {
    width: calc(
      calc(var(--propertyCardWidth) * 2) + var(--mapsSearchSidebarTotalPadding)
    );
  }
}

@media screen and (max-width: 1300px) {
  .ResizableContainer {
    width: calc(
      calc(var(--propertyCardWidth) * 1) + var(--mapsSearchSidebarTotalPadding)
    );

    & .Resizer {
      background-color: #bbb;
      pointer-events: none;
    }
  }
}

@media (--small-screen) {
  .MainContent {
    position: relative;
  }

  .MobileDisclaimerFairHousingWrapper {
    display: block;
    position: absolute;
    z-index: 2;
    top: 14px;
    left: 10px;
    padding: 0 5px 5px;
    border: 1px solid rgb(151 151 151 / 27%);
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
  }

  .MapSection {
    flex: none;
    width: 100vw;

    & .LayerItemsControl {
      /* Above YourTeamModal trigger button, when present */
      z-index: 101;
    }

    /* Move this over to leave space for the fixed page controls on the right */
    & .MapNotification {
      right: 50px;
    }

    & .MapMarkerPopup {
      max-width: calc(100vw - 40px);
      width: 220px;

      & .ThumbnailWrapper,
      & .PropertyCard {
        max-width: calc(100vw - 40px);
        width: 220px;
        overflow: hidden;
      }
    }

    & :global .mapboxgl-ctrl-attrib {
      font-size: 12px;
      line-height: 20px;
    }

    & .MapNotification.MapNotificationPositionedHigher {
      bottom: 135px;
    }
  }

  .ResizableContainer {
    display: none;
  }

  .TopBar {
    position: absolute;
    z-index: 4;
    display: none;
  }

  .LocationIcon {
    display: none;
  }
}

@media (--tablet-screen) {
  .SidebarSectionInner {
    padding-left: 20px;
  }

  .SidebarSectionTopBar,
  .BrokerageWrapper {
    padding: 0 20px 10px;
  }

  .SidebarDisclaimerFairHousingWrapper {
    padding: 0 20px 10px;
  }

  .ResizableContainer {
    width: calc(calc(var(--propertyCardWidth) * 1) + 28px);

    & .Resizer {
      display: none;
    }
  }

  .TopBar {
    margin-bottom: 0;
  }
}

@media (--tablet-screen) {
  .MobileSearchItemAnimation {
    display: none;
  }
}

@media (--tablet-portrait-screen) {
  .MapSection {
    & :global .mapboxgl-ctrl-top-right {
      top: 65px;
    }

    & .LayerGroupsControlInner {
      width: 100%;
      justify-content: space-around;
    }

    & .LayerItemsControl {
      max-width: 100%;
      bottom: 0;

      & .LayerItemsControlInner {
        /* hiding mapbox info icon */
        z-index: 3;
        width: 100%;
        /* Extra vertical room for the X icon */
        padding: 30px 10px 20px;
        border-radius: 0;

        & .LayerItemsControlMarkerToggleColumnMonochrome {
          padding-right: 0;
        }
      }
    }

    & .LocationIcon {
      top: 165px;
    }
  }
}

:export {
  sidebarHorizontalPadding: var(--mapsSearchSidebarTotalPadding);
}
