@import './_breakpoints.css';
@value border-color, text-color from './_colors.css';
@value rounded-border from './_fontsAnimations.css';

@value carousel-dialog-thumbnail-width-height: 120px;
@value carousel-dialog-thumbnail-container-max-width: 800px;

.CarouselDialog {
  & .ThumbnailCarousel {
    height: 45vw;
    width: 80vw;
    max-height: 672px;
    max-width: 1200px;
    cursor: pointer;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;
    padding: 0 30px;

    & .CurrentImage {
      /* We want to show the entire image - not crop it as we do in the other thumbnail carousels */
      background-size: contain;
      background-repeat: no-repeat;
      height: 100%;
      margin: auto;
      position: static;
      width: calc(100% - 150px);
    }

    & .Controls svg {
      transform: scale(2.8);

      & :global .element-to-fill {
        fill: black;
      }
    }
  }

  & .ImageList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: carousel-dialog-thumbnail-container-max-width;
    margin: 0 auto 40px;
  }

  & .LazilyRenderedComponent {
    width: carousel-dialog-thumbnail-width-height;
    height: carousel-dialog-thumbnail-container-max-width;
  }

  & .LazilyRenderedComponent:not(:last-child) {
    margin: 2px;
  }

  & .Image {
    width: 100%;
    height: 100%;
    border: 3px solid white;
    padding: 2px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;
    box-sizing: border-box;
    opacity: 0;
    transition: opacity 0.2s;
  }

  & .Image.active {
    box-shadow: none;
  }

  & .Image:not(.active):hover {
    outline: 1px solid border-color;
    box-shadow: none;
  }
}

.ModalPositioner .Modal {
  width: fit-content;
}

/* stylelint-disable no-descending-specificity */
.CarouselSliderArrow svg {
  & use:last-child {
    fill: text-color;
  }

  & use:first-child {
    filter: none;
  }
}
/* stylelint-enable no-descending-specificity */

.RenderPropContent {
  width: 100%;
  text-align: center;
  margin: 40px 0;
}

.Controls > * {
  width: 25px;
  height: 50px;
  top: calc(50% - 17px);
  animation: fade-in 0.3s;
  opacity: 1;

  &:first-child {
    left: 25px;
  }

  &:nth-child(2) {
    right: 25px;
  }
}

.OffscreenImg {
  position: absolute;
  top: -9999px;
  opacity: 0;
  z-index: -1;
  width: 1px;
  height: 1px;
}

@media (--tablet-screen) {
  .CarouselDialog .ThumbnailCarousel {
    width: 90vw;
    padding: 0;
  }
}

@media (--small-screen) {
  .CarouselPhotoWrapper {
    background-size: cover !important;
  }

  .LazilyRenderedComponent {
    width: calc(100% / 4 - 5px);
    padding-top: calc(100% / 4 - 5px);
    height: initial;
  }

  .RenderPropContent {
    margin: 20px 0;
  }

  .CarouselDialog .ImageList {
    justify-content: start;
    padding: 0 30px;
  }
}
