.PropertyPageMobileLoAd {
  border-radius: 14px;
  border: 1px solid #f2f2f2;
  background: #fff;
  box-shadow: 0 15px 14px 0 rgb(0 0 0 / 14%);
  overflow: hidden;
  margin: 0 13px 30px;
}

.AgentWrapper {
  padding: 10px 13px 14px 12px;
}

.CopyArea {
  color: #fff;
  padding: 32px;
}

.HelpYouText {
  font-weight: 900;
  line-height: 28px;
  font-size: 15px;
  margin-bottom: 5px;
}

.AccessText {
  font-size: 33px;
  letter-spacing: -0.63px;
  line-height: 30px;
  margin-bottom: 18px;
  font-weight: 900;
}

.ContactButton {
  margin-bottom: 0;
}

.PropertyPageMobileLoAd .ContactButton {
  font-size: 14px;
  width: auto;
  min-width: 204px;
  padding: 15px 23px 13px 19px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-basis: 0;
  max-width: 100%;
  align-content: center;
  justify-content: center;

  & .ButtonText {
    /* If LO's firstname is too long to
     * fit in UI, we add ellipsis */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.MessageIcon,
.MessageIcon-svg {
  height: 18px;
  width: 18px;
}

.MessageIcon {
  margin-right: 18px;
}

.LastMessageDate {
  font-size: 11px;
  font-weight: 300;
  position: absolute;
  bottom: 12px;
  left: 35px;
  opacity: 0.8;
}

.SeeHowLink {
  composes: strip-button-styles from './_common.css';
  font-size: 12px;
  margin-bottom: 38px;
  text-decoration: none;
  color: #fff;
  display: block;
  cursor: pointer;
}

.CaratIcon-svg {
  transform: scale(0.625);
}

.CaratIcon :global .element-to-fill {
  fill: #fff;
}

.CaratIcon {
  vertical-align: sub;
  position: relative;
  top: 1px;
  left: 2px;
}

.TextButton.CombinedContactBtn {
  color: inherit;
  padding: 0;
  font-size: 14px;
  font-weight: 800;
  text-decoration: underline;
  margin-top: 13px;

  & .MessageIcon {
    position: relative;
    top: 3px;
  }
}
