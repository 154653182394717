.RequestATourButton {
  height: 75px;
  font-size: 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.PersonOnCircle,
.PersonOnCircle-svg {
  height: 25px;
  width: 25px;
}

.ButtonLabel {
  font-weight: 300;
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  text-align: center;
  display: block;
  font-size: 12px;
}
