@import './_constants.css';

.ActiveSection {
  background-color: white;
  border-radius: 9px;
  margin-top: 10px;
  box-sizing: border-box;
  min-height: 460px;
  width: 100%;
}

.AvmFactorIcon {
  border: 0.57px solid #d7d7d7;
  border-radius: 9px;
  background-color: white;
  width: 81px;
  height: 87px;
  margin-right: 8px;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  padding: 0;
  box-sizing: content-box;

  &.AvmFactorIconPositive {
    & :global .element-to-stroke {
      stroke: #13c9a1;
    }

    & :global .element-to-fill {
      fill: #13c9a1;
    }
  }

  &.AvmFactorIconNegative {
    & :global .element-to-stroke {
      stroke: #fd8a5f;
    }

    & :global .element-to-fill {
      fill: #fd8a5f;
    }
  }

  & .icon,
  & .icon-svg {
    max-height: 40px;
    max-width: 40px;
  }

  & .AvmFactorIconLabel {
    font-weight: 900;
    margin-bottom: 5px;
  }
}

.TabsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  overflow-x: scroll;
  /* for momentum scrolling on iOS */
  -webkit-overflow-scrolling: touch;
  /* leave ample bottom space for horizontal scrollbar */
  padding-bottom: 20px;
}

.LoadingSection {
  min-height: 400px;

  & .SpinnerSection {
    transform: translateY(-20px);
  }
}
