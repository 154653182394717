@value text-color from './_colors.css';

.PreFooterContent {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.ComeHomeAnchorElement {
  color: inherit;
  text-decoration: none;
}

.ComeHomeAnchorElement:hover {
  color: inherit;
  text-decoration: none;
}

.ComeHomeFooterLogo {
  cursor: pointer;
  margin: 15px auto;
}

.ComeHomeFooterLogo,
.ComeHomeFooterLogo-svg {
  /* Override height set in ClickableLogo media query */
  height: 45px !important;
}

.CityLinksContainer {
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  line-height: 24px;
  margin: 10px auto;
  padding: 0 40px;
}

.CityLinksContainer a {
  color: inherit;
}

.CityTextLink {
  font-weight: 900;
}

.ChevronIcon {
  position: relative;
  top: 3px;
  left: 4px;
}

.ChevronIcon-svg {
  transform: rotate(270deg);
  transition: transform 100ms;
  width: 12px !important;
  height: 12px !important;
}

.active .ChevronIcon-svg {
  transform: rotate(90deg);
  transition: transform 100ms;
}

.Link {
  cursor: pointer;
}

.VerticalSeparator {
  height: 10px;
  color: text-color;
  margin: 0 10px;
}

.AppDownloadIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px auto;

  & .Spacer {
    margin: 0 10px;
  }
}

.AppStoreIcon {
  cursor: pointer;
}

.FooterElement {
  margin: 20px auto;
}

.Footer {
  padding-bottom: 100px;
}

.SiteLinks {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 10px auto;
  list-style: none;
  padding: 0;
}

.SiteLinks a,
.ComehomeFooterLink {
  text-decoration: underline;
  color: inherit;
  font-size: 12px;
  font-weight: 900;
}

.Text {
  font-size: 12px;
  margin: 10px auto;
  text-align: center;
}

.MoreCityLinks {
  cursor: pointer;
  color: #50b8fd;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: none;
  background: none;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
}

.ComehomeFooterLink {
  composes: strip-button-styles from './_common.css';
  padding: 0;
  cursor: pointer;
}

.ShowLessText {
  margin-left: 5px;
}

.DRENotice {
  margin-top: 15px;
}

.ExternalLink {
  text-decoration: underline;
  display: inline-block;
  margin: 15px auto 0;
  text-align: center;
  font-size: 14px;
}

.CitiesUl {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.CityLi {
  margin-left: 10px;

  &:not(:last-child)::after {
    content: '|';
    margin-left: 10px;
  }
}

.SiteLinkItem + .SiteLinkItem {
  margin-left: 8px;

  &::before {
    content: '|';
    margin-right: 8px;
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 300;
  }
}

.BrokerName {
  font-size: 14px;
}

.YourTeamTriggerButtonWrapper {
 text-align: center;
}
