.Photo {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  background-color: #f8f8f8;
}

.MlsLogoOverlay {
  height: 16px;
  width: 32px;
  bottom: 4px;
  left: 8px;
  background-repeat: no-repeat;
  background-size: contain;
}

.PropertyPhoto {
  height: 100%;
  width: 100%;
}
