@import './_breakpoints.css';
@value modal-overlay from './_colors.css';
@value rounded-border, box-shadow-long from './_fontsAnimations.css';

.Modal {
  background: #fff;
  border-radius: rounded-border;
  box-shadow: box-shadow-long;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  padding: 25px 14px 14px;
  position: relative;
  z-index: 1;
}

.ScrollableContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overscroll-behavior-y: contain;
}

.ModalPositioner {
  /* Avoid Pose positioning this absolutely during exit animation */
  position: fixed !important;
  /* Above .AutoComplete in Header.css, HeaderChase.css, HeaderComehome.css */
  z-index: 102;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  &.ModalPositionerFullScreen .Modal {
    border-radius: 0;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    box-shadow: initial;
  }

  &.ModalPositionerOversize .ScrollableContainer {
    overflow: auto;
    align-items: flex-start;

    & .Modal {
      top: 10px;
      margin-bottom: 10px;
    }
  }

  &.ModalPositionerFullScreen .ScrollableContainer {
    display: block;
    overflow: auto;
    background-color: white;
  }
}

.Screen {
  background: modal-overlay;
  height: 100%;
  width: 100%;
  /* Prevent Pose from positioning absolute on exit anim */
  position: fixed !important;
  z-index: 101;
  top: 0;
  left: 0;
}

.Heading {
  margin: 0 0 40px;
  text-align: center;
  font-size: 18px;
  font-weight: 200;
  position: relative;
}

.MobileTopBar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  background-color: white;
  z-index: 2;
}

.MobileCloseIconButton {
  display: none;
  padding: 8px 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  z-index: 2;
  border: none;
  background: none;
  color: unset;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

  & svg {
    height: 18px;
  }
}

.DesktopCloseIconButton {
  composes: strip-button-styles from './_common.css';
  position: absolute;
  right: 22px;
  top: 22px;
  cursor: pointer;
  z-index: 2;
}

@media (--small-screen) {
  .ScrollableContainer.StickToBottom {
    align-items: flex-end;
  }

  .Modal.ModalWithCloseIcon {
    /* Account for fixed .MobileTopBar */
    padding-top: 45px;
  }

  .Modal.StickToBottom {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 20px;
  }

  .ChildrenWrapper {
    position: relative;
  }

  .MobileCloseIconButton {
    display: block;
  }

  .DesktopCloseIcon,
  .DesktopCloseIconButton {
    display: none;
  }

  .MobileTopBar {
    display: block;
  }

  .Modal {
    /* removes unnecessary white space in the bottom e.g.) mortgage calc */
    padding-bottom: 0;
  }
}
