.Arrow > svg {
  width: 15px;
  margin: 0 10px 0 0;
}

.NavWrapper {
  overflow: hidden;
  font-size: 12px;
  float: left;
  height: 40px;
  line-height: 2.8rem;
  cursor: pointer;
  background: none;
  border: none;
  color: inherit;
}

.TextWrapper {
  display: inline-block;
  margin-top: -9px;
  vertical-align: middle;
}

.MyHomeTitle {
  font-weight: 900;
}

.Address {
  font-weight: 500;
}

.LoadingSectionHidden {
  display: none;
}

.LoadingSectionVisible {
  display: inline-block;
}
