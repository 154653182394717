@import './_breakpoints.css';
@value text-color from './_colors.css';

.WatchListHeader {
  box-sizing: border-box;
  margin: 20px auto;
  padding: 0 10px;
}

.WatchListTopNavTabList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.NavButton {
  cursor: pointer;
  background: transparent;
  padding: 10px;
  position: relative;
  height: 110px;
  width: 300px;
  border: 1px solid white;

  & .Label {
    color: #6d7278;
    font-size: 21px;
    font-weight: 900;
  }

  & .SubText {
    color: #6d7278;
    font-size: 11px;
    font-weight: 300;
    margin-top: 15px;
  }

  & .SavedHomesIconLabel {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.NavButton:first-child {
  margin-right: 8px;

  & .SubText {
    margin-top: 0;
  }
}

.NavButton.ActiveNav {
  & .Label {
    color: #414042;
  }
}

.SavedSearchesButtonText {
  position: relative;
  top: 8px;
}

@media (--tablet-screen) {
  .WatchListHeader {
    margin: 15px auto 20px;
  }

  .NavButton:first-child {
    margin-right: 5px;
  }
}

@media (--small-screen) {
  .WatchListHeader {
    margin: 10px auto;
  }

  .NavButton {
    height: 40px;
    width: 170px;
    padding: 0;
    border: none;

    & .Label {
      color: #0b6efd;
      font-size: 13px;
      font-weight: 500;
      border-bottom: 1px solid white;
      padding-bottom: 3px;
    }

    & .SubText {
      display: none;
    }

    & .SavedHomesIconLabel {
      align-items: center;
      height: 100%;
    }
  }

  .NavButton:last-child {
    &::before {
      content: '';
      left: 0;
      top: 10px;
      position: absolute;
      height: 20px;
      border-left: 2px solid #e5e5e5;
    }
  }

  .NavButton.ActiveNav {
    border: none;
  }

  .SavedSearchesButtonText {
    position: static;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (--xxx-small-screen) {
  .NavButton {
    width: 150px;
    padding: 0;
  }

  .SubText {
    display: none;
  }
}
