.MenuNotificationCount {
  background: #e30c49;
  border-radius: 50%;
  height: 19px;
  width: 19px;
  line-height: 20px;
  text-align: center;

  & .NotificationCounter {
    font-size: 12px;
    color: #fff;
    font-weight: 900;
    display: block;
    margin-right: 0.01rem;
  }
}
