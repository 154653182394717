.MapNotificationInner {
  background-color: white;
  border-radius: 4px;
  box-shadow: box-shadow-soft;
  padding: 15px 20px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  position: relative;
  z-index: 2;

  & svg {
    position: absolute;
    left: calc(50% - 18px);
    top: -18px;
    width: 36px;
    height: 36px;
  }
}

.MLSCoverageNotification {
  position: relative;

  & img {
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    opacity: 0.7;
  }

  & h2 {
    font-size: 14px;
    font-weight: 600;
    margin: 5px 0 10px;
  }

  & p {
    font-size: 12px;
    font-weight: 200;
    margin: 10px 0 5px;
  }
}
