@import './_breakpoints.css';
@import './_constants.css';
@value text-color,
border-color-lightest,
lighter-grey from './_colors.css';
@value box-shadow-soft from './_fontsAnimations.css';

.PhotoListModal {
  background-color: white;

  & .Modal {
    padding: 0;
    margin: 0;
    border-radius: 0;
  }

  & .ModalWithTopPadding {
    padding-top: 50px;
  }
}

.LazilyRenderedComponent {
  margin-bottom: 2px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Photo {
  box-sizing: border-box;
  min-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.TopBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
  box-sizing: border-box;
  background-color: white;

  & .ClickableLogo:not(.ClickableLogoByHouseCanary) {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 0;
    box-sizing: border-box;
    padding: 3px 6px 0 0;
  }

  & .ClickableLogoByHouseCanary {
    height: 28px;
  }
}

.ShareIcon {
  display: block;
  margin-left: 12px;
  transform: translate(0, 2px);

  & svg {
    height: 24px;
  }
}

.Modal .CloseIcon {
  top: 5px;
}

.PropertyInfo {
  position: fixed;
  bottom: 0;
  background-color: white;
  width: calc(100vw - 40px);
  left: 20px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  box-sizing: border-box;
  display: flex;
  padding-top: 18px;

  & .WatchListActionButton {
    position: absolute;
    display: block;
    top: 5px;
    right: 5px;

    & .CircleButton {
      width: 45px;
      height: 45px;

      & .HeartIcon,
      & .HeartIcon svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  /* div containing price value and bottom label for Chase */
  & .PropertyCardValueInline {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  /* overriding styling for BottomLabel specifically for PhotoListModal */
  & .BottomLabel {
    position: unset !important;
    margin-top: 15px !important;
    margin-bottom: 10px;
    max-width: 100% !important;
    font-size: 9px !important;
  }
}

.PropertyInfoInner {
  width: 100%;
  text-align: center;
}

.PropertyAddress {
  font-size: 11px;
  font-weight: 200;
  width: calc(100% - 35%);
  box-sizing: border-box;
  margin: 0 auto 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (--small-screen) {
  .PhotoListModal .Modal.ModalWithCloseIcon .MobileTopBar {
    /* We're using the .DesktopCloseIcon for mobile for this modal */
    display: none;
  }

  .PhotoListModal .Modal.ModalWithCloseIcon .DesktopCloseIcon {
    position: absolute;
    display: block;
    padding: 10px;
    top: 3px;
    left: 3px;
    right: initial;
    z-index: 2;
  }
}

@media (--xxx-small-screen) {
  .PropertyCardValueInline {
    & .ValueLabel,
    & .Value {
      font-size: 16px !important;
    }
  }

  .PropertyAddress {
    font-size: 10px;
  }

  .PropertyDetailsDivider {
    width: 150px;
  }

  .HorizontalSeparator {
    width: 150px;
  }
}
