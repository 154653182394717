@import './_constants.css';

.FooterSectionWithSeparator {
  padding: 0 15px;
}

.Columns {
  display: flex;
  position: relative;
  width: 100%;
  min-width: 320px;
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 1.25rem;
  box-sizing: border-box;
  z-index: 1;
}

.LeftSide {
  flex: 1;
  /* Cause content to fit within this column and not push the right-column off the page */
  overflow: hidden;
  padding-right: 20px;
  /* Since we need overflow:hidden here, we need to padding to allow long BreakoutSections modals to
   * show completely */
  padding-bottom: 175px;
}

.AlternateDesignLeftSide {
  & .ListingInfo {
    margin-bottom: 0;
  }

  & .BrokerageAttribution {
    margin: 0 0 15px;
  }
}

.RightSide {
  flex: none;
}

.PropertyIntro {
  & .DesktopPropertyInfo {
    padding-top: 10px;
  }

  & .ListingStatusContainer {
    display: none;
  }
}

.FindAgentWrapper {
  margin-top: 48px;
  margin-bottom: 40px;
}

.FindAgentCopy {
  color: #747a80;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 24px;
  font-weight: 600;
}

.AttributionWrapper {
  margin-top: 6px;
}

.MLSAttribution + .BrokerageAttribution {
  margin-top: -10px;
}
