@import './_constants.css';
@import './_breakpoints.css';
@value border-color-light, lighter-grey from './_colors.css';
@value box-shadow-soft from './_fontsAnimations.css';

.SearchPageList {
  /* This component should sit directly on top of the map component so that we don't have
   * to re-render the map when switching back and forth */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: white;
  /* Above map controls which are above the map.  The entire .SearchMapPage cannot have
   * a z-index since content within it might need to be above the .Header */
  z-index: 3;
  display: flex;
  flex-direction: column;

  & .PropertyCard {
    height: var(--propertyCardHeight);
  }
}

.PropertyResults {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  flex: 1;
  /* Necessary to make flex: 1 restrict the height to the available space when the content is larger
   * than the available space in Firefox. Child SearchListScrollableContainer is the scrollable container. */
  overflow: hidden;
}

.VisuallyHidden {
  composes: screen-reader-only from './_common.css';
}

.SearchListScrollableContainer {
  margin: 0 auto;
  max-width: 1110px;
  width: 100%;
  height: 100%;
  /* Scroll bar helpful for a11y */
  overflow: scroll;
  /** Fixes the Vertical scrolling in iOS mobile browsers (smooth scroll)*/
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
  box-sizing: border-box;
  flex: none;
  display: block;
}

.TopBar {
  justify-content: center;
  box-sizing: border-box;
  background: #fff;
  z-index: 3;
  align-items: center;
  padding: 12px 20px 20px 18px;
}

.TopBarInner {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.TopBarControls {
  display: flex;
  max-width: 600px;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0;

  & .FiltersControl {
    display: inline-flex;
    flex: 1;
    justify-content: center;
  }

  & .DropdownSort {
    display: flex;
    flex: 1;
  }
}

.TopBarControlButton {
  display: flex;
  flex: 1;
  background-color: inherit;
  border: none;
}

.Control {
  display: flex;
  flex: 1;
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.DisableControl,
.TopBarControlButtonDisabled {
  color: #d8d8d8;
  cursor: not-allowed;
}

.ActiveFilterCount {
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  margin-right: 7px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.PropertyList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: var(--list-page-item-container-max-width);
  justify-content: center;
  padding: 0 10px;
}

.SearchListScrollableContainer .LazilyRenderedComponent {
  justify-content: center;
}

.PropertyResults .NoResultsNotice {
  margin: 0 auto 60px;
  padding: 150px 20px 0;
}

.MinHeightWrapper {
  min-height: calc(100vh - 440px);
}

.LoadingSection {
  height: 100%;
  width: 100%;
}

.NextPageLoader {
  position: relative;
  width: 100%;
  height: 200px;
}

.MobilePropertyTopWrapper {
  font-size: 12px;
  padding: 0 15px;
  font-weight: 100;
  margin-bottom: 6px;
  height: 17px;
  display: flex;
  justify-content: space-between;
}

.MobilePropertyCountWrapper {
  display: block;
}

.PropertyDisclaimerFairHousingWrapper {
  text-align: right;
  width: auto;
  max-width: none;
  padding: 0;
  margin: 0;
}

.CloseButton {
  display: none;
}

.ConnectWithATopLocalAgentContainer {
  height: var(--propertyCardHeight);
  margin: 0;
  position: static;
  transition: none;
  width: var(--propertyCardWidth);
}

.Filters {
  flex-direction: column;
  justify-content: flex-start;
}

.SortType {
  height: 26px;
}

.SortOrder {
  position: relative;
  right: 31px;
  margin-bottom: 10px;
}

.NoResults {
  background-size: cover;
  background-position-x: -90px;
  background-position-y: 40px;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.NoResultsInner {
  position: relative;

  & .NoResultsTooltipArrow {
    position: absolute;
    left: calc(50% - 100px);
    top: -9px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    height: 0;
    width: 0;
  }

  & .NoResultsTooltipContent {
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: white;

    & .SearchIcon {
      padding: 10px;

      & .SearchIcon-svg {
        height: 58px;
        width: 58px;
      }
    }

    & .NoResultsTooltipText {
      color: inherit;
      font-size: 14px;
      font-weight: 300;
      text-align: center;
      text-shadow: 0 2px 18px 0;
    }
  }
}

.TooltipScreen {
  background-color: transparent;
}

/* These styles are provided just in case a user expands their browser after viewing the mobile
 * experience.  It's not possible to organically get into the list view on desktop otherwise */
@media (--not-small-screen) {
  .NoResults {
    /* Override inline CSS */
    background-image: none !important;
  }

  .PropertyList {
    justify-content: center;
    padding: 0 10px;
  }

  .TopBarControls {
    margin-top: 20px;
  }

  .CloseButton {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

/* iPhone 5/SE */
@media screen and (max-width: 320px) {
  .SearchListScrollableContainer {
    padding-left: 0;
  }

  .PropertyCardInner {
    max-width: 100vw;
  }

  .ConnectWithATopLocalAgentContainer {
    width: 300px;
  }
}
