.UnsubscribePage {
  min-height: 100vh;
  padding: 60px 5px 5px;
  max-width: 375px;
  margin: auto;

  & .TitleContainer {
    /* Truncate title text to match iOS: */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .Title {
      font-weight: 600;
      padding-bottom: 5px;
    }

    & .IconWrapper {
      margin-right: 10px;
      display: flex;

      & .GreenCheckIcon,
      & .GreenCheckIcon-svg {
        height: 30px;
        width: 30px;
        align-self: center;
      }
    }
  }

  & .Message {
    flex-basis: 3;
    margin-top: 5px;
    font-weight: 300;
    padding: 20px 10px;
    line-height: 22px;
  }

  & .LinksContainer {
    border: solid 1px #e9e9e9;
    border-radius: 4px;
    /* last MessageAndLink will take care of border-bottom */
    border-bottom: none;
    line-height: 22px;

    & .MessageAndLink {
      font-weight: 300;
      padding: 25px;
      border-bottom: solid 1px #e9e9e9;
      border-radius: inherit;

      & .Link {
        display: inline;
        text-decoration: underline;
      }

      & .Link:hover {
        cursor: pointer;
      }
    }
  }
}
