@import './_breakpoints.css';
@value text-color from './_colors.css';

.FormPage {
  width: 100%;
  min-width: 300px;
  max-width: 311px;
  padding: 15px 13px 17px;
  margin: 0 auto;
}

.SuccessPage {
  width: 100%;
  min-width: 300px;
  max-width: 311px;
  padding: 15px 13px 17px;
  margin: 0 auto;
}

.Label {
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
}

.FieldWrapper {
  position: relative;
  margin-bottom: 5px;
}

.FieldWrapper.InputWrapper {
  outline: 0;
}

.InputElement {
  margin-top: 22px;
  border-radius: 4px;
  margin-bottom: 15px;
  border-color: #9e9e9e;
}

.FieldWrapper .InputElement {
  font-size: 16px;
  font-family: inherit;
}

.FieldWrapper .Error {
  font-size: 12px;
  color: #b10234;
  font-weight: 300;
  margin: -10px 0 10px;
}

.InputWrapper .InputElementContainer input.InputElement {
  padding-right: 10px !important;
}

.PillButton {
  margin-top: 5px;
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Checkmark {
  height: 15px;
  width: 19px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

/* For some reason the Checkmark SVG has a large blank area surrounding it, causing
 * the button that contains it to be huge. I'm making the SVG absolute positioned
 * in order to keep it from taking up room within its container. */

.Checkmark-svg {
  height: 55px;
  width: auto;
  position: absolute;
  top: -15px;
}

.SubmitButton.isSent {
  cursor: not-allowed;
}

.APIErrorMessage {
  font-size: 12px;
  color: #b10234;
  margin-bottom: 16px;
  font-weight: 300;
  margin-top: 15px;
}

.TermsOfUseCopy {
  font-size: 11px;
  line-height: 19px;
  margin-top: 19px;
}

/**
  * To be able to set the color from parcon of the underline of the sign up link
  * we need to remove underline that is set from the SigUpOrLoginLink css
  * and set the text-decoration and text-decoration-color
  * using the labelStyle prop to pass them into the SignUpOrLoginLink component instead
*/
.SignUpOrLoginLink .FlatButton {
  text-decoration: none;
}

.BackLink {
  padding-top: 20px;
  font-size: 11px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

@media (--small-screen) {
  .FormPage {
    padding-bottom: 100px;
  }

  .BackLink {
    text-align: left;
    padding-top: 190px;
  }
}
