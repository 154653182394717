@import './_breakpoints.css';
@value square-border from './_fontsAnimations.css';
@value text-color from './_colors.css';

.ConfirmModal {
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;

  & .CloseIcon {
    display: none;
  }

  & .Modal {
    padding: 0;
    width: 550px;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 14%);
  }
}

.Screen {
  background: rgb(0 0 0 / 50%);
}

.DesktopCloseIcon {
  display: none;
}

.ConfirmModalContent {
  box-sizing: border-box;
  border-radius: square-border;
  width: 100%;
}

.ConfirmModalTitle {
  padding: 20px;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;

  & .RemoveWarningIconContainer {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.ConfirmModalBody {
  text-align: center;
  padding: 30px;
  font-weight: 200;
  font-size: 16px;
}

.ConfirmModalFooter {
  display: flex;
  justify-content: flex-start;
  /* reversing order because primary action needs to be on the right on desktop */
  flex-direction: row-reverse;
  padding: 20px;

  & .PillButton {
    font-size: 15px;
    font-weight: 400;
    /* Ensure that they don't touch even on very small screens */
    margin-right: 5px;

    &:last-child {
      border: 1px solid;
      margin-right: 15px;
    }
  }
}

@media (--x-small-screen) {
  .ConfirmModal {
    & .Modal {
      width: 90vw;
    }

    & .CloseIcon {
      display: block;
    }
  }

  .ConfirmModalTitle {
    background-color: white;
    color: initial;
    padding: 20px 15px;
  }

  .ConfirmModalBody {
    text-align: left;
    padding: 20px;
  }

  .ConfirmModalFooter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    align-items: center;

    & .PillButton {
      font-weight: 400;

      &:first-child {
        margin: 0 0 20px;
        font-size: 16px;
        height: 40px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}

/* Buttons would reach edge of modal on very small screens,
 * this makes them on top of each other instead of side by side:
 */
@media (--xxx-small-screen) {
  .Primary {
    margin-bottom: 1em;
  }

  .Body {
    margin-bottom: 2em;
  }

  .ConfirmModalFooter {
    display: block;
    text-align: center;
  }
}
