@import './_breakpoints.css';
@value box-shadow-long, box-shadow-long-big-blur from './_fontsAnimations.css';
@value border-color-light, text-color from './_colors.css';

.AvmBreakdown {
  position: relative;
  width: calc(100% - 20px);
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 22px 19px -3px rgb(0 0 0 / 17%);

  & .LockedComponentContainer {
    & .LockedContentContainer {
      & .LockedContentButton {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        & .LockIcon {
          & svg {
            height: 40px;
            width: 40px;

            & path {
              stroke-width: 3px;
            }
          }
        }

        & .LockMessage {
          font-size: 16px;
          width: 70%;
        }
      }
    }
  }
}

.disabled {
  filter: blur(4px);
}

.Heading {
  font-size: 17px;
  font-weight: 200;
  margin: 0 15px 20px;
}

.LearnMoreAside {
  display: flex;
  font-size: 10px;
  margin-left: 5px;

  & .LearnMoreLink {
    color: white !important;
  }
}

.TooltipContentWrapper {
  box-shadow: box-shadow-long;
}

.AvmSectionCommonStyles {
  text-align: center;
  color: white;
  font-size: 12px;
  background: linear-gradient(80deg, #00bead 0%, #387bea 90%);
}

.AvmSection {
  /* stylelint-disable-next-line value-keyword-case */
  composes: AvmSectionCommonStyles;
  padding: 15px 0;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;

  & .AvmLabelValueWrapper {
    padding: 5px 0;
    cursor: pointer;
  }
}

.NoAvmSection {
  /* stylelint-disable-next-line value-keyword-case */
  composes: AvmSectionCommonStyles;
  font-weight: 600;
  padding: 45px 0;
  line-height: 16px;
  text-shadow: 0 4px 7px 0 rgb(0 0 0 / 22%);
}

.AvmLabel {
  display: block;
  font-size: 11px;
  font-weight: 200;
  padding: 0 20px;
}

.AvmBreakdownAvmValue {
  padding-top: 10px;
}

.AvmSubtext {
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 200;

  & .LearnMoreAside {
    font-size: 11px;
    font-weight: 200;
    margin-left: 12px;
  }

  & .InfoIcon {
    display: flex;
  }
}

.AvmFactorsSection {
  box-sizing: border-box;
  padding: 0 20px 0 15px;
  margin: 0;
  overflow: hidden;
}

.DefaultAvmValueExplanation {
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0;
  padding: 25px;

  &.DefaultAvmValueWithSeeMoreButton {
    padding-bottom: 0;
  }
}

.AvmFactor {
  padding: 15px 0;
  display: flex;
  font-size: 12px;
  min-height: 50px;

  &:not(:last-child) {
    border-bottom: 1px solid border-color-light;
  }

  &:first-child {
    padding: 20px 0 20px 20px;
    min-height: unset;
    font-weight: 200;
    line-height: 2;
  }

  &:last-child {
    margin-bottom: 10px;
    border-bottom: 1px solid border-color-light;
  }
}

.AvmFactorIcon {
  width: 84px;
  margin-right: 15px;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.AvmFactorIconPositive {
    & :global .element-to-stroke {
      stroke: #13c9a1;
    }

    & :global .element-to-fill {
      fill: #13c9a1;
    }
  }

  &.AvmFactorIconNegative {
    & :global .element-to-stroke {
      stroke: #fd8a5f;
    }

    & :global .element-to-fill {
      fill: #fd8a5f;
    }
  }

  & .icon,
  & .icon-svg {
    max-height: 36px;
  }
}

.AvmFactorIconLabel {
  font-weight: 900;
  margin-bottom: 5px;
}

.AvmFactorDescription {
  font-weight: 200;
  margin-bottom: 5px;
}

.StreetAddress {
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 10px;
}

.Slider,
.SliderItem {
  height: 42px;
  width: 100%;
}

.SliderItem {
  display: flex;
  align-items: center;
}

.SliderArrow.SliderArrow--right {
  right: -20px;
}

.SliderArrow.SliderArrow--left {
  left: -20px;
}

.SliderArrow,
.SliderArrow svg.SliderArrow-svg {
  height: 24px !important;
}

/* stylelint-disable no-descending-specificity */
.SliderArrow svg :global .element-to-fill {
  fill: text-color !important;
}
/* stylelint-enable no-descending-specificity */

.SliderArrow {
  margin: auto 0;
  top: 0;
  bottom: 0;
}

.MedianTitle {
  font-weight: 300;
  font-size: 12px;
  padding-left: 28px;
}

.MedianContent {
  font-weight: 800;
  font-size: 14px;
  margin-top: 4px;
  padding-left: 28px;
}

.SeeMoreButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 25px 0;
  padding: 0 20px;

  & .PillButton {
    width: 100%;
    margin: 0;
    height: 45px;
    min-height: 45px;
  }
}

.CobrandedAvmTooltipTrigger {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
}
