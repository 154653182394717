@import './_breakpoints.css';
@import './_constants.css';

/* When content is written to the browser in page-handler.ts, we set html { opacity: 0; }
 * to avoid unstyled content appearing before the CSS loads.  Then when this style is
 * loaded, the page content appears. */
html {
  opacity: 1 !important;
}

/* Allow all child elements in app to be able to size to 100% of the browser window if desired */
html,
body,
.AppContainer,
.RouterContainer {
  height: 100%;
}

input,
button {
  /* Inherit cobrand font family defined on the app container */
  font-family: inherit;
}

:global .no-focus-outline {
  & div:focus,
  & a:focus,
  & button:focus,
  & input:focus,
  & textarea:focus,
  & span:focus,
  & ul:focus,
  & li:focus,
  & [role='button']:focus,
  & [type='checkbox']:focus,
  & [role='checkbox']:focus,
  & [role='option']:focus,
  & [role='listitem']:focus,
  & [role='menuitem']:focus {
    outline: none;
  }
}

:global #react-root {
  height: 100%;
}

.AppContainer {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* For use when we want to disallow scrolling on the container. i.e. fullscreen map views */
  &.AppContainerFixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.MainContainer {
  display: block;

  &.MainContainerFlexed {
    flex: 1;
    /* Needed to cause the container to take up the rest of the vertical page height regardless
     * of children */
    overflow: hidden;
  }

  /*
   * Set min-height on selected routes to prevent page flickering with lazily-loaded assets
   */
  &.MainContainerWithMinHeight {
    min-height: calc(100vh - var(--header-height));
  }
}

.RouterContainer {
  &.RouterContainerBlurred {
    filter: blur(10px);
    transition: filter 400ms;
  }

  &.RouterContainerFlexed {
    display: flex;
    flex-direction: column;

    & .MobileBottomNav {
      position: static;
    }
  }
}

.SkipToMainContent {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;

  &:focus,
  &:active {
    color: #fff;
    background-color: #000;
    left: auto;
    top: auto;
    width: 30%;
    height: auto;
    overflow: auto;
    margin: 10px 35%;
    padding: 5px;
    border-radius: 15px;
    border: 4px solid yellow;
    text-align: center;
    font-size: 1.2em;
    z-index: 999;
  }
}

.Header {
  min-width: 300px;
  box-sizing: border-box;
  position: relative;
}

@media (--small-screen-with-keyboard-open) {
  .MobileBottomNav {
    display: none;
  }
}
