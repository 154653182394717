@import './_breakpoints.css';

.HomeSubpageYourTeamAgent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  padding-left: 52px;
  padding-right: 30px;
  box-sizing: border-box;
}

.FindAnAgentImage {
  max-height: 343px;
  height: 100%;
  max-width: 100%;
  width: auto;
  border-radius: 16px;
  box-shadow: 0 16px 20px 0 rgb(0 0 0 / 14%);
}

.ContentCard {
  position: absolute;
  top: 101px;
  right: 40px;
  width: 446px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 53px 46px 40px;
  box-sizing: border-box;
  color: #fff;
  border-radius: 14px;
  box-shadow: 0 16px 20px 0 rgb(0 0 0 / 14%);
  text-align: left;

  & h2.CardHeader {
    padding-top: 0;
    font-weight: 900;
    font-size: 26px;
    line-height: 36px;
  }

  & p.CardDescription {
    margin: 0;
    padding: 23px 0 36px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}

.ContentWrapper {
  position: relative;
  top: -45px;
}

.CTAButtonWrapper {
  width: 100%;
}

.CTAButton {
  min-width: 250px;
  font-size: 16px;
}

@media (--small-screen) {
  .HomeSubpageYourTeamAgent {
    padding-left: 0;
    padding-right: 0;
    bottom: 0;
  }

  .AnimationWrapper {
    height: 100%;
    width: 100vw;
  }

  .FindAnAgentImage {
    /* accommodate nav bar */
    max-height: calc(100vh - 86px);
    height: calc(100vh - 86px);
    max-width: 100vw;
    width: 100vw;
    border-radius: 0;
    box-shadow: none;
    object-fit: cover;
    object-position: top left;
  }

  .ContentWrapper {
    top: 0;
  }

  .ContentCard {
    top: 35%;
    right: 0;
    border-radius: 14px 0 0 14px;
    width: auto;
    max-width: 75%;
    padding: 32px;

    & h2.CardHeader {
      font-size: 26px;
      line-height: 34px;
    }

    & p.CardDescription {
      font-size: 16px;
      line-height: 20px;
      padding-top: 20px;
      padding-bottom: 22px;
    }
  }
}

@media (--x-small-screen) {
  .ContentCard {
    max-width: calc(100vw - 24px);
  }
}

@media (--high-zoom-screen) {
  .ContentCard {
    top: 0;
    padding: 10px;
  }

  .AnimationWrapper {
    overflow: auto;
  }
}
