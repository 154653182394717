@import './_constants.css';
@import './_breakpoints.css';
@value border-color-light from './_colors.css';

.InitDataSection {
  /* Needed to space the footer down so that it doesn't appear in the viewport while the
   * init data is loading */
  min-height: 300px;
}

.InitDataLoadingSection {
  min-height: 300px;
  width: 100%;
}

.FooterSection {
  & .HudLogoContainer {
    padding: 20px 0;
  }
}

.SectionDivider {
  margin: 40px 0;
  padding: 0 10px;
  border-bottom: 1px solid border-color-light;
  border-top: none;
  border-right: none;
  border-left: none;
  width: 100%;
  box-sizing: border-box;
}

.MapPropertyPage {
  margin-top: 20px;
}

/* Below is tablet css override */
.PropertyPageMobileContents {
  position: relative;

  & .PropertyIntro {
    margin-bottom: 0;

    & .DesktopPropertyInfo {
      display: none;
    }

    & .InfoToggle {
      display: block;
      height: 0;
      margin-top: 0;
      margin-bottom: 0;
      overflow: hidden;
      box-sizing: border-box;
      transition:
        height 200ms,
        margin-top 200ms,
        margin-bottom 200ms;
      transition-delay: 0s;

      &.InfoToggleShown {
        height: 22px;
        margin-top: 5px;
        margin-bottom: 15px;
        transition-delay: 100ms;
      }
    }

    & .PropertyInfoTable {
      margin-bottom: 15px;
    }
  }

  & .SmallPropertyInfo {
    padding: 16px 16px 0;
  }

  & .HomeownerUpsellAd {
    width: auto;
    padding: 23px 18px 70px;
    margin: 40px 18px;

    & .Title {
      font-size: 18px;
      margin-top: 10px;
      letter-spacing: normal;
      line-height: 25px;
    }

    & .Description {
      font-size: 16px;
    }
  }

  & .NearbyListings {
    & .SliderContainer::after {
      background: rgb(255 255 255 / 0%);
    }
  }

  & .ListingInfo {
    padding: 0 15px 15px;

    & .LicenseNumber {
      display: block;
    }
  }

  & .MapPropertyPage {
    & .MapSection {
      margin-bottom: 0;
      height: 460px;
    }

    & .SectionDivider {
      display: block;
    }

    & .MapNullState {
      height: 100px;
      border: none;
      border-radius: 0;
      box-shadow: none;
      font-size: 14px;
    }

    & .LotMapNullState {
      background-image: url('../assets/images/cobrand/chase/lot-line-null-state-image-mobile-chase.png') !important;
    }
  }

  & .BreakoutSections {
    padding-right: 20px;
    margin-bottom: 40px;
    margin-top: 30px;

    & .BreakoutSectionsInner {
      padding-right: 0;
    }
  }

  & .AvmBreakdown {
    position: relative;
    width: calc(100% - 20px);
    margin: 0 auto;
    transform: none;
    top: 0;

    & .Heading {
      display: block;
    }

    & .AvmLabel {
      font-size: 11px;
      font-weight: 200;
    }

    & .AvmSubtext {
      font-size: 11px;
      font-weight: 200;

      & .LearnMoreAside {
        font-size: 11px;
        font-weight: 200;
        margin-left: 12px;
      }
    }

    & .AvmFactorDescription {
      font-weight: 200;
    }

    & .AvmFactorsSection {
      padding: 0 20px 0 15px;
    }

    & .AvmFactorIcon {
      margin-right: 15px;
    }

    & .PillButton {
      /* let min-width take over for all chase pill buttons */
      width: unset;
    }
  }

  & .PDPRightRailCard {
    position: static;
    width: calc(100% - 20px);
    margin: 0 auto;
    transform: none;
    top: 0;
    box-shadow: none;
    border: none;
    border-radius: 0;
    padding: 0;
    margin-bottom: 20px;

    & .LenderCTA {
      width: 70%;
    }
  }
}

.WatchListButtonPositioner {
  position: absolute;
  top: 100px;
  right: 15px;
  z-index: 2;
}

@media (--tablet-screen) {
  .PropertyPageMobileContents {
    & .OpenHouseInfo {
      padding: 0;
    }

    & .ListingInfo {
      padding: 0;
    }

    & .LoanQualificationLetter {
      margin-left: 0;
      padding-left: 0;
    }

    & .NearbyListings {
      padding: 0;
    }

    & .SectionDivider {
      display: none;
    }

    & .SmallPropertyInfo {
      padding: 10px 0;
    }
  }
}
