.LoadMoreResultsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.LoadMoreResults {
  background: none;
  padding: 0;
  cursor: pointer;
  font-size: 12px;
  font-weight: 900;
  text-align: center;
  width: 193px;
  height: 44px;
  line-height: 44px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
}
