@import './_breakpoints.css';

.GenericLOInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  pointer-events: none;
}

.TopSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
  text-align: left;
}

.BottomSection {
  display: flex;
  flex-direction: row;
}

.AgentName {
  font-size: 18px;
  font-weight: 900;
  /* To take care of exceptionally long agent names */
  max-width: 234px;
  word-wrap: break-word;
}

.AgentSubtitle {
  font-size: 13px;
  /* To take care of exceptionally long agent names */
  max-width: 234px;
  word-wrap: break-word;
}

.Company {
  font-size: 14px;
  font-weight: 900;
  line-height: 19px;
}

.mlsId {
  font-size: 11px;
  font-weight: 300;
  margin-top: 2px;
}

.PhoneAndEmail {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.3px;
  /* To take care of exceptionally long agent emails */
  max-width: 275px;
  word-wrap: break-word;
  text-align: left;
}

.PhoneAndEmail a {
  text-decoration: none;
  color: currentcolor;
}

.PhoneCallLink {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  background: #f4f4f4;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PhoneCallIcon,
.PhoneCallIcon-svg {
  height: 17px;
  width: 17px;
}

@media (--small-screen) {
  .GenericLOInfo {
    padding: 10px;
  }

  .AgentName {
    font-size: 13px;
    line-height: 16px;
    /* To take care of exceptionally long agent names */
    max-width: 200px;
  }

  .AgentSubtitle {
    font-size: 12px;
    /* To take care of exceptionally long agent names */
    max-width: 200px;
  }

  .Company {
    font-size: 11px;
    line-height: 11px;
  }

  .mlsId {
    font-size: 10px;
    line-height: 10px;
  }

  .PhoneAndEmail {
    font-size: 12px;
    font-weight: 300;
    /* To take care of exceptionally long agent emails */
    max-width: 200px;
  }

  .TopSection {
    margin-bottom: 0;
  }
}

@media (--xxx-small-screen) {
  .AgentName {
    /* To take care of exceptionally long agent names */
    max-width: 160px;
  }

  .PhoneAndEmail {
    /* To take care of exceptionally long agent emails */
    max-width: 160px;
  }
}
