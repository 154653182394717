@import './_breakpoints.css';
@value text-color from './_colors.css';

.WatchListHeader {
  box-sizing: border-box;
  margin: 15px auto;
  padding: 0 10px;
}

.WatchListTopNavTabList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.NavButton {
  cursor: pointer;
  background: transparent;
  margin: 10px 0;
  position: relative;
  height: 110px;
  width: 300px;
  border: 1px solid white;

  & .Label {
    color: #6d7278;
    font-size: 21px;
    font-weight: 900;
  }

  & .ActiveTabLabel {
    background: #f0f0f0;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 300px;
  }

  & .SubText {
    color: #6d7278;
    font-size: 11px;
    font-weight: 300;
    margin-top: 10px;
  }
}

.NavButton:first-child {
  margin-right: 6px;
}

.NavButton.ActiveNav {
  border: 1px solid #f0f0f0;
}

@media (--tablet-screen) {
  .NavButton {
    width: 300px;
  }
}

@media (--small-screen) {
  .WatchListHeader {
    margin: 0 auto 15px;
  }

  .NavButton {
    height: 54px;
    width: 170px;

    & .Label {
      color: #6d7278;
      font-size: 14px;
      font-weight: 900;
    }

    & .ActiveTabLabel {
      height: 3px;
      width: 170px;
    }
  }

  .NavButton:first-child {
    margin-right: 3px;
  }

  .NavButton.ActiveNav {
    border: 1px solid #f0f0f0;
  }

  .SubText {
    display: none;
  }
}

/**
 * To make sure styles work as expected on
 * iphone SE size devices
*/
@media (--xxx-small-screen) {
  .NavButton {
    height: 54px;
    width: 150px;

    & .ActiveTabLabel {
      height: 3px;
      width: 150px;
    }
  }

  .NavButton:first-child {
    margin-right: 3px;
  }

  .SubText {
    display: none;
  }
}
