@import './_constants.css';
@value border-color-light, lighter-grey from './_colors.css';
@value box-shadow-soft from './_fontsAnimations.css';

.DropdownSort {
  position: relative;
}

.DropdownSortOptionsListWrapper {
  z-index: 3;
  position: absolute;
  width: 200px;
  top: calc(100% + 5px);
  right: 0;
  border: 1px solid border-color-light;
  border-radius: 4px;
  background-color: white;
  font-size: 13px;
  font-weight: 200;
  color: inherit;
  box-shadow: box-shadow-soft;
  overflow: hidden;
}

.DropdownSortOptionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  z-index: 10;
}

.DropdownSortOptionsScreen {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
}

.DropdownSortOptionListItem {
  padding: 15px 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

  &:not(:last-child) {
    border-bottom: 1px solid border-color-light;
  }
}

.DropdownSortOptionListItem:hover {
  background-color: lighter-grey;
}

.DropdownSortOptionListItem:focus,
.DropdownSortOptionListItem[data-focused='true'] {
  background-color: lighter-grey;
}
